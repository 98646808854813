'use strict';

var util = require('../../util');
var shipping = require('./shipping');

/**
 * @function
 * @description Selects the first address from the list of addresses
 */
exports.init = function () {
    var $form = $('.address');
    // select address from list
    $('select[name$="_addressList"]', $form).on('change', function () {
        var selected = $(this).children(':selected').first();
        var selectedAddress = $(selected).data('address');
        if (!selectedAddress) { return; }
        util.fillAddressFields(selectedAddress, $form);
        shipping.updateShippingMethodList();
        // re-validate the form
        $form.validate().form();
    });

    // FCCL-1758, init the first customer address in T09
    var initSelected = $('select[name$="_addressList"]').children()[1];
    if (initSelected && $('input[name$="_shippingaddress_postal"]').val() === ""){
        var initSelectedAddress = $(initSelected).data('address');
        if (!initSelectedAddress) { return; }
        util.fillAddressFields(initSelectedAddress, $form);
    }
    // End of FCCL-1758
};
