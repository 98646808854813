'use strict';

var ajax = require('../../ajax'),
    formPrepare = require('./formPrepare'),
    giftcard = require('../../giftcard'),
    util = require('../../util'),
    loading = require('../../loading'),
    page = require('../../page'),
    modal = require('../../modal'),
    creditcard = require('../../credit_card');

var billing = this;
/**
 * @function
 * @description Fills the Credit Card form with the passed data-parameter and clears the former cvn input
 * @param {Object} data The Credit Card data (holder, type, masked number, expiration month/year)
 */
function setCCFields(data) {
    var $creditCard = $('[data-method="CREDIT_CARD"]');
    $creditCard.find('input[name$="creditCard_owner"]').val(data.holder).trigger('change');
    $creditCard.find('select[name$="_type"]').val(data.type).trigger('change');
    $creditCard.find('input[name*="_creditCard_number"]').val(data.maskedNumber).trigger('change');
    $creditCard.find('[name$="_month"]').val(data.expirationMonth).trigger('change');
    $creditCard.find('[name$="_year"]').val(data.expirationYear).trigger('change');
    $creditCard.find('input[name$="_cvn"]').val('').trigger('change');
}

/**
 * @function
 * @description Updates the credit card form with the attributes of a given card
 * @param {String} cardID the credit card ID of a given card
 */
function populateCreditCardForm(cardID) {
    // load card details
    var url = util.appendParamToURL(Urls.billingSelectCC, 'creditCardUUID', cardID);
    ajax.getJson({
        url: url,
        callback: function (data) {
            if (!data) {
                window.alert(Resources.CC_LOAD_ERROR);
                return false;
            }
            setCCFields(data);
        }
    });
}

/**
 * @function
 * @description Changes the payment method form depending on the passed paymentMethodID
 * @param {String} paymentMethodID the ID of the payment method, to which the payment method form should be changed to
 */
function updatePaymentMethod(paymentMethodID) {
    var $paymentMethods = $('.payment-method');
    $paymentMethods.removeClass('payment-method-expanded');

    var $selectedPaymentMethod = $paymentMethods.filter('[data-method="' + paymentMethodID + '"]');
    if ($selectedPaymentMethod.length === 0) {
        $selectedPaymentMethod = $('[data-method="Custom"]');
    }
    $selectedPaymentMethod.addClass('payment-method-expanded');

    // ensure checkbox of payment method is checked
    $('input[name$="_selectedPaymentMethodID"]').removeAttr('checked');
    $('input[value=' + paymentMethodID + ']').prop('checked', 'checked');

    formPrepare.validateForm();
}

/**
 * @function
 * @description loads billing address, Gift Certificates, Coupon and Payment methods
 */
exports.init = function () {
    var $checkoutForm = $('.checkout-billing');
    var $addGiftCert = $('#add-giftcert');
    var $discountDiv = $('div.form_checkboxWrap-discount');
    var $giftCertCode = $('#gift_code_input');
    var $addCoupon = $('#add-coupon');
    var $couponCode = $('input[name$="_couponCode"]');
    var $selectPaymentMethod = $('.payment-method-options');
    var selectedPaymentMethod = $selectPaymentMethod.find(':checked').val();
    var $birthYear = $('input[name$="_birthYear"]');
    var $birthMonth = $('select[name$="_birthMonth"]');
    var $birthDay = $('select[name$="_birthDay"]');
    // Start 201210 Add: FCCL-2331
    var postalErr = $('#div_err_check_postal');
    var stateErr = $('#div_err_change_state');
    var maxPostalLength = 7;
    var maxTimeCount = 30;  // 210120 Mod: FCCL-2485
    var intervalTime = 100;
    var topPadding = 5;
    var leftPadding = 15;
    var styleDisplayShow = 'block';
    var $oldPostalFtra = $('#old_postal');
    var $oldStateFtra = $('#old_state');
    var $oldCityFtra = $('#old_city');
    var $oldAddress1Ftra = $('#old_address1');
    var $curState = $('select[name$="_state"]');
    var $curPostal = $('input[name$="_postalCode"]');
    var $curCity = $('input[name$="_city"]');
    var $curAddress1 = $('input[name$="_address1"]');
    // Start 201218 Add: FCCL-2393
    var $defaultStateOption = $('select[name$="_state"] option[value=""]');
    var defaultStateText = $defaultStateOption.text();
    // End 201218 Add: FCCL-2393
    // End 201210 Add: FCCL-2331
    // FCCL-2688: Get all other contact input fields
    var contactInputs = $('input[name*=billingaddress_phone2]');
    const OFFSET_TOP_UPPER = 34;
    var otherContactErr = $('[id*=div_err_other_contacts]');
    var isB2b = $('input[name=is_b2b]').length > 0;
    var functOtherContactErr = function () {
        return otherContactErr.filter(function () {
            return $(this).css('display') === styleDisplayShow;
        });
    }

    formPrepare.init({
        formSelector: 'form[id$="billing"]',
        continueSelector: '[name$="billing_save"]'
    });

    if ($('input[name="login"]').val() && $.parseJSON($('input[name="login"]').val().toLowerCase()) && !$.parseJSON($('#checkFieldInput').val())) { //FCCL-2118 BangLy 12/22/2020 Mod-start
        // Check names is not null then disable all of it
        var isValueNotNull = function(value) {
            return !!value;
        };
        if (isValueNotNull($('input[name$="_lastName"]').val()) &&
                isValueNotNull($('input[name$="_firstName"]').val()) &&
                isValueNotNull($('input[name$="_lastNameKana"]').val()) &&
                isValueNotNull($('input[name$="_firstNameKana"]').val())) {
            $('input[name$="_lastName"]').prop('disabled', true);
            $('input[name$="_firstName"]').prop('disabled', true);
            $('input[name$="_lastNameKana"]').prop('disabled', true);
            $('input[name$="_firstNameKana"]').prop('disabled', true);
        }
    }

    // FCCL-2543: Add condition if gender radio has not been checked yet, select the first one
    if($('input[name$="billingaddress_sex"]:checked').length === 0) {
        $($('input[id$="billingaddress_sex"]')[0]).prop('checked', true);
    }

    $('form[id$="billing"]').find(':text').each(function () {
        $(this).attr('autocomplete', 'none');
    });

    // default payment method to 'CREDIT_CARD'
    updatePaymentMethod((selectedPaymentMethod) ? selectedPaymentMethod : 'CREDIT_CARD');
    $selectPaymentMethod.off().on('click', 'input[type="radio"]', function () {
        updatePaymentMethod($(this).val());
    });

    // select credit card from list
    $('#creditCardList').off().on('change', function () {
        var cardUUID = $(this).val();
        if (!cardUUID) {return;}
        populateCreditCardForm(cardUUID);

        // remove server side error
        $('.required.error').removeClass('error');
        $('.error-message').remove();
    });

    $('#check-giftcert').off().on('click', function (e) {
        e.preventDefault();
        var $balance = $('.balance');
        if ($giftCertCode.length === 0 || $giftCertCode.val().length === 0) {
            var error = $balance.find('span.error');
            if (error.length === 0) {
                error = $('<span>').addClass('error').appendTo($balance);
            }
            error.html(Resources.GIFT_CERT_MISSING);
            return;
        }

        giftcard.checkBalance($giftCertCode.val(), function (data) {
            if (!data || !data.giftCertificate) {
                $balance.html(Resources.GIFT_CERT_INVALID).removeClass('success').addClass('error');
                return;
            }
            $balance.html(Resources.GIFT_CERT_BALANCE + ' ' + data.giftCertificate.balance).removeClass('error').addClass('success');
        });
    });

//    $addGiftCert.off().on('click', function (e) {
//        e.preventDefault();
//        var code = $giftCertCode.val(),
//            $error = $checkoutForm.find('.giftcert-error');
//        if (code.length === 0) {
//            $error.html(Resources.GIFT_CERT_MISSING);
//            return;
//        }
//
//        var url = util.appendParamsToUrl(Urls.redeemGiftCert, {giftCertCode: code, format: 'ajax'});
//        $.getJSON(url, function (data) {
//            var fail = false;
//            var msg = '';
//            if (!data) {
//                msg = Resources.BAD_RESPONSE;
//                fail = true;
//            } else if (!data.success) {
//                msg = data.message.split('<').join('&lt;').split('>').join('&gt;');
//                fail = true;
//            }
//            if (fail) {
//                $error.html(msg);
//                return;
//            } else {
//                window.location.assign(Urls.billing);
//            }
//        });
//    });
    // FCCL-2890: Add function handle loading payment methods when loading ajax
    function handleLoadingPaymentMethods($response) {
        var resCartTotal = parseInt($response.find('input[name=total-price]').val()); // Response cart total
        var curCartTotal = parseInt($('input[name=total-price]').val()); // Current cart total
        // Get isSubscription
        var isSubscription = ($('input[name=isSubscription]').length
                                && $('input[name=isSubscription]').val() !== 'null'
                                && $('input[name=isSubscription]').val() !== 'undefined')
                                    ? JSON.parse($('input[name=isSubscription]').val())
                                    : false;
        // Only load html whenever resCartTotal === 0 and curCartTotal !== 0 or resCartTotal !== 0 && curCartTotal === 0
        // FCCL-2894: Remove isSubscription condition
        if ((resCartTotal === 0 && curCartTotal !== 0) || (resCartTotal !== 0 && curCartTotal === 0)) {
            // Case add gift until total of cart is 0
            // FCCL-2894: Add isSubscription condition for saving payment credit card
            if (resCartTotal === 0 && curCartTotal !== 0  || isSubscription) {
                savePaymentSelected();
            }

            // FCCL-2852: Load orderSummary_total and payment-methods by replacing the old one
            $('#FFgb').remove(); // Remove ftra tag
            $('.FFt').parent().remove(); // Remove all error messages on the page
            var $paymentMethods = $response.find('#payment-methods');
            $('#payment-methods').html($paymentMethods.html());

            // FCCL-2894: Add isSubscription condition for loading payment credit card
            if (resCartTotal !== 0 && curCartTotal === 0 || isSubscription) {
                // Case remove gift from 0 to another price (resCartTotal !== 0 && curCartTotal === 0)
                loadPaymentSelected();
            }

            // Set payment-methods on page hiding if response is hiding
            if ($paymentMethods.css('display') === 'none') {
                $('#payment-methods').hide();
            } else {
                $('#payment-methods').css('display', '');
            }
            // When creditCard_number is not on the page, render hidden input for ftra validation
            if ($('#payment-methods').find('#dwfrm_billing_paymentMethods_creditCard_number').length === 0) {
                $('#payment-methods')
                    .append('<input type="text" style="display: none" name="dwfrm_billing_paymentMethods_creditCard_number" id="dwfrm_billing_paymentMethods_creditCard_number" >')
                    .append('<input type="text" style="display: none" name="dwfrm_billing_paymentMethods_creditCard_cvn" id="dwfrm_billing_paymentMethods_creditCard_cvn" >');
            }
            // Init all events on billing and credit card
            billing.init();
            creditcard.init();
        }
    }

    // Save credit card input value into hidden input
    function saveCreditCardPayment() {
        // Save value of credit card
        var $creditCardPayment = $('input[id$="billingaddress_paymentType"][value=21]');
        // FCCL-2894: Hidden input of installment and installmentno
        var hiddenInstallment = $('#payment-selected-installment').length ? $('#payment-selected-installment').val() : '';
        var hiddenInstallmentNo = $('#payment-selected-installmentNo').length ? $('#payment-selected-installmentNo').val() : '';
        // Check if credit card exists
        if ($creditCardPayment.length) {
            var saveValue = $('input[name=rd_credit_type]:checked').val() +
                '|' + $('input[name$=creditCard_number]').val() +
                '|' + $('select[name$=expiration_month]').val() +
                '|' + $('select[name$=expiration_year]').val() +
                '|' + ($('input[name$=creditCard_cvn]').val() ? $('input[name$=creditCard_cvn]').val().replace(/[^0-9\.]+/g, '') : '') +
                '|' + hiddenInstallment +
                '|' + hiddenInstallmentNo;
            // Check if payment-selected-credit not exists
            modifyIfHiddenTagExists('payment-selected-credit', saveValue);
        }
    }

    // Save selected payment method
    function saveOtherPayment() {
        var $selectedPayment = $('input[id$="billingaddress_paymentType"]:checked');
        if ($selectedPayment.val()) {
            var selectedPaymentValue = $selectedPayment.val();
            // Check if payment-selected not exists
            modifyIfHiddenTagExists('payment-selected', selectedPaymentValue);
        }
    }

    // Save both selected payment and credit card value
    function savePaymentSelected() {
        // FCCL-2894: Add isSubscription condition
        var isSubscription = ($('input[name=isSubscription]').length
                                && $('input[name=isSubscription]').val() !== 'null'
                                && $('input[name=isSubscription]').val() !== 'undefined')
                                    ? JSON.parse($('input[name=isSubscription]').val())
                                    : false;
        if(($('#payment-selected').length && $('#payment-selected').val() === '21') || isSubscription) {
            saveCreditCardPayment();
        }
        saveOtherPayment();
    }

    // Get saved credit card value and set to input or select field
    function loadCreditCardValue() {
        $('input[name$=installmentType]:checked').prop('checked', ''); // Remove check for installment type for reset value
        // For credit card
        if ($('#payment-selected-credit').length && $('#payment-selected-credit').val()) {
            var paymentSelectedCredit = $('#payment-selected-credit').val().split('|');
            // Set value
            if (paymentSelectedCredit.length > 1) {
                // Check credit type
                checkRadioInput('rd_credit_type', paymentSelectedCredit[0]);
                // Credit card number
                $('input[name$=creditCard_number]').val(paymentSelectedCredit[1]);
                // Expiration month
                selectOption('expiration_month', paymentSelectedCredit[2]);
                // Expiration year
                selectOption('expiration_year', paymentSelectedCredit[3]);
                // CVN
                $('input[name$=creditCard_cvn]').val(paymentSelectedCredit[4]);
                // Installment type
                checkRadioInput('installmentType', paymentSelectedCredit[5]);
                // Installment No
                selectOption('installmentNo', paymentSelectedCredit[6]);
            }
        }
        // Check radio input if above code not selected
        if($('input[name$=installmentType]:checked').length === 0 && $('#payment-selected-installment').length) {
            checkRadioInput('installmentType', $('#payment-selected-installment').val());
        }
    }

    // Check into input field with input name and value
    function checkRadioInput(inputName, inputValue) {
        if (inputValue === 'undefined' || inputValue === undefined) {
            return;
        }
        $('input[name$=' + inputName + '][value="' + inputValue + '"]').prop('checked', true).attr('checked', 'checked');
    }

    // Select an option with select name and option value
    function selectOption(selectName, optionValue) {
        $('select[name$=' + selectName + '] option[value="' + optionValue + '"]').attr('selected', 'selected');
    }

    // Load last selected payment method values
    function loadPaymentSelected() {
        // For all payment methods
        if ($('#payment-selected').length && $('#payment-selected').val()) {
            var paymentSelected = $('#payment-selected').val();
            // Case user hasn't checked any payment yet
            if (paymentSelected !== 'undefined') {
                // Set value
                checkRadioInput('billingaddress_paymentType', paymentSelected);
                loadCreditCardValue();
            }
        }
    }

    $addGiftCert.off().on('click', function (e) {
        e.preventDefault();
        var code = $giftCertCode.val(),
            $error = $('.giftcert-error');
        if (code.length === 0) {
            modal.dialog($('#gift_error').val(), function() {
                //@TODO
            });
            return;
        }

        loading.show();
        var url = util.appendParamsToUrl(Urls.giftCardCheckBalance, {
            'giftCertificateID' : code,
            'format' : 'ajax'
        });
        $.ajax({
            url: url,
            type: 'GET',
            success: function (response) {
                loading.hide();
                if (response && response.error) {
                    modal.dialog(response.error, function() {
                        //@TODO
                    });
                } else {
                    $(response).find('div.form_checkboxWrap-discount input').each(function(e) {
                        if ($discountDiv.find('input[name="' + $(this).attr('name') + '"]').length == 0) {
                            $discountDiv.append('<label><span class="form_checkbox"><input type="checkbox" name="' + $(this).attr('name') + '" checked><span></span></span>' + $(this).parent().parent().text() + '</label>');
                        } else {
                            // FCCL-2875: Set checked property if gift checkbox already exists
                            $discountDiv.find('input[name="' + $(this).attr('name') + '"]').prop('checked', true);
                        }
                    });
                    // FCCL-2890: Move code into function
                    handleLoadingPaymentMethods($(response));
                    $('div[name$="orderSummary_total"]').html($(response).find('div[name$="orderSummary_total"]'));
                }
            }, error:function(err){
                loading.hide();
                modal.dialog(err, function() {
                    //@TODO
                });
            }
        });
    });

    $discountDiv.off().on('click', 'input', function (e) {
        var url = util.appendParamsToUrl(Urls.redeemGiftCert, {
            'giftCertCode' : e.currentTarget.name,
            'checkState' : e.currentTarget.checked,
            'format' : 'ajax'
        });
        loading.show();
        // FCCL-2852: Add logic uncheck checkbox when ajax response is error
        var $gift = $(this);
        $.ajax({
            url: url,
            type: 'GET',
            success: function (response) {
                loading.hide();
                if(response && response.error) {
                    // Check if last gift redeem failed
                    if(response.giftCodeError) {
                        $('div.form_checkboxWrap-discount input[name=' + response.giftCodeError + ']').prop('checked', false);
                    } else {
                        $gift.prop('checked', false);
                    }
                    modal.dialog(response.error, function() {
                        //@TODO
                    });
                } else {
                    // FCCL-2890: Move code into function
                    handleLoadingPaymentMethods($(response));
                    $('div[name$="orderSummary_total"]').html($(response).find('div[name$="orderSummary_total"]'));
                }
            }, error:function(err){
                loading.hide();
                modal.dialog(err, function() {
                    //@TODO
                });
            }
        });
    });

    $addCoupon.off().on('click', function (e) {
        e.preventDefault();
        var $error = $checkoutForm.find('.coupon-error'),
            code = $couponCode.val();
        if (code.length === 0) {
            $error.html(Resources.COUPON_CODE_MISSING);
            return;
        }

        var url = util.appendParamToURL(Urls.giftCardCheckBalance, 'giftCertificateID', code);
        $.getJSON(url, function (data) {
            var fail = false;
            var msg = '';
            if (!data) {
                msg = Resources.BAD_RESPONSE;
                fail = true;
            } else if (!data.success) {
                msg = data.message.split('<').join('&lt;').split('>').join('&gt;');
                fail = true;
            }
            if (fail) {
                $error.html(msg);
                return;
            }

            //basket check for displaying the payment section, if the adjusted total of the basket is 0 after applying the coupon
            //this will force a page refresh to display the coupon message based on a parameter message
            if (data.success && data.baskettotal === 0) {
                window.location.assign(Urls.billing);
            }
        });
    });

    // trigger events on enter
    $couponCode.off().on('keydown', function (e) {
        if (e.which === 13) {
            e.preventDefault();
            $addCoupon.click();
        }
    });
    $giftCertCode.off().on('keydown', function (e) {
        if (e.which === 13) {
            e.preventDefault();
            $addGiftCert.click();
        }
    });

    $('input[id$="billingaddress_paymentType"]').off().change(function() {
        $('#btn_billing_next').removeAttr('disabled');
        $('#btn_billing_next').css('background-color', '#C00');
        // FCCL-2500 payment alredy check -> hide message
        if($('input[id$="billingaddress_paymentType"]:checked').val() != undefined) {
            $('#no-select-payment').hide();
        }
    });


    /*
    $('.jsAccordionBtn').off().on({
        'click': function(){
              $(this).closest('.jsAccordion').toggleClass('isOpen').children('.jsAccordionBox').stop().slideToggle(200);
            return false;
        }
    });
    */

    // Start 201119 Add FCCL-2213
    $('input[name$="_installmentType"]').off().on('change', function() {
        $('#installment_error').hide();
    });
    // End 201119 Add FCCL-2213

    // FCCL-3039
    var addressValidation = false;
    var addressValidationValue = $('input[name="addressValidation"]').val();
    if (addressValidationValue != undefined) {
        addressValidation = addressValidationValue.toLowerCase() === 'true' ? true : false;
    }

    // FCCL-3190 【F-141】配送先、注文者の住所情報で番地の入力がないときにアラーム表示したい
    $(function(){
        $('input[name$="billingaddress_city"], input[name$="billingaddress_address1"]').focusout(function (e) {
            var val = $(this).val();
            val = val.replace(/(ー|➖|〰|-|−|〜|~|―|‐|ー|-|−|ｰ)/g, "ー");
            val = val.replace(/[A-Za-z0-9!#\$%&~\|\^\@\`;:=\-\+\*\?\/_\,\.\(\)<>\[\]\{\}\\]/g,function(s){
                return String.fromCharCode(s.charCodeAt(0)+0xFEE0);
            });
            val = val.replace(/"/g,"”");
            val = val.replace(/'/g,"’");
            val = val.replace(/\s/g,"　");
            $(this).val(val);
        })
        
        $('input[name$="billingaddress_city"], input[name$="billingaddress_address1"]').focusout(function (e) {
            var city = $('input[name$="billingaddress_city"]').val();
            var address1 = $('input[name$="billingaddress_address1"]').val();
            var value = city + address1;
            if (city.length == 0 ||  city.length > 26) {
                $('#div_err_address1_post').hide();
                return;
            }
            var ret = false;
            var preAry = ["丁目","番地"];//○丁目、○番地
            var rearAry = ["－","‐"];//○－、○-、－○、-○
            var numeric = "０１２３４５６７８９〇一二三四五六七八九十";
            var notAddress = ["番外地","未番地","無番地","無地番地"];
            var arabicNumeric = ["０","１","２","３","４","５","６","７","８","９"]     
            //アラビア数字が含まれていればOK
            $.each(arabicNumeric, function(i,val){
                if(value.indexOf(val) != -1){
                    ret = true;
                    return false;//break;
                }
            });
            if(!ret){
                //「丁目」、「番地」の前に漢数字orアラビア数字がない入力はNG
                $.each(preAry, function(i,val){
                    var idx = value.indexOf(val);
                    if(idx > 0){//idx==0となる「丁目」、「番地」という入力は認めない。
                        var num = value.substring(idx-1,idx);
                        if(numeric.indexOf(num) != -1){
                            ret = true;
                            return false;//break;
                        }
                    }
                });
            }
            if(!ret){
                //○－、○-、－○、-○の場合OK（○・・・漢数字、アラビア数字）
                $.each(rearAry, function(i,val){//○－、○-、－○、-○
                    var idx = value.indexOf(val);
                    if(idx != -1){
                        var rearNum = value.substring(idx+1,idx+2);
                        var preNum = value.substring(idx-1,idx);
                        if((rearNum != "" && numeric.indexOf(rearNum) != -1) || 
                            (preNum != "" && numeric.indexOf(preNum) != -1)){
                            ret = true;
                            return false;//break;
                        }
                    }
                });
            }
            if(!ret){
                //番外地、未番地、無番地、無地番地が含まれていればOK
                $.each(notAddress, function(i,val){
                    if(value.indexOf(val) != -1){
                        ret = true;
                        return false;//break;
                    }
                });
            }

            var input = $('input[name$="billingaddress_city"]')[0];
            var err = $('#div_err_address1_post');
            if (!ret) {
                err.css('display', styleDisplayShow);
                err.css('left', input.offsetLeft);
                err.css('top', input.offsetTop - input.offsetHeight);
            } else {
                err.hide();
            }
        })
    });

    $('#btn_billing_next').off().on({
        // FCCL-2612 レスポンスが来るまで待機するように変更
        'click': function(e){
            loading.show();

            // FCCL-3115 【F-73】入力フォームのバリデーションの実装追加
            $('input').each(function(){
                $(this).focus().blur();
            });
            
            // FCCL-2465 add check select payment
            var paymentNoSelect = false;
            if($('input[id$="billingaddress_paymentType"]:checked').val() == undefined) {
                $('#no-select-payment').css('display', 'inline-block');
                paymentNoSelect = true;
            }
            var ev = page.showFormSubmissionErrors(e);
            if ((ev && ev.isDefaultPrevented()) || paymentNoSelect) {
                e.preventDefault();
                // Start 210108 Add: FCCL-2464
                var delayTime = 300;
                setTimeout(function() {
                    var top = 0;
                    // FCCL-2500 add id and display none passing
                    $('div[id*="FFbe"],div[id*="no-select-payment"],div[id*="div_err_check_postal"],div[id*="div_err_city"],div[id*="div_err_address1"]').each(function() {
                        var display = $(this).css('display');
                        if (display != 'none' && (top == 0 || top > $(this).position().top)) {
                            top = $(this).position().top;
                        }
                    });
                    // FCCL-2500 no focus
                    $(':focus').blur();
                    window.scrollTo(0, top - topPadding);
                }, delayTime);
                // End 210108 Add: FCCL-2464
                loading.hide();
                return;
            }
            checkOtherContact(contactInputs); // FCCL-2688: check other contact when in interval
            var returnFlag = false; //FCCL-2688: Add return flag for checking and showing all errors
            // Start 201210 Add: FCCL-2331
            if(postalErr.css('display') === styleDisplayShow || stateErr.css('display') === styleDisplayShow) {
                window.scrollTo(0, $curPostal[0].offsetTop - topPadding);
                returnFlag = true;
            }
            // End 201210 Add: FCCL-2331
            // Start 211101 Add: FCCL-2982
            if($('#div_err_city').css('display') === styleDisplayShow) {
                window.scrollTo(0, $('#div_err_city')[0].offsetTop - topPadding);
                returnFlag = true;
            // FCCL-3190 【F-141】配送先、注文者の住所情報で番地の入力がないときにアラーム表示したい
            } else if ($('#div_err_address1_post').css('display') === styleDisplayShow) {
                window.scrollTo(0, $('#div_err_address1_post')[0].offsetTop - topPadding);
                returnFlag = true;
            }
            if($('#div_err_address1').css('display') === styleDisplayShow) {
                window.scrollTo(0, $('#div_err_address1')[0].offsetTop - topPadding);
                returnFlag = true;
            }
            // FCCL-3115 【F-73】入力フォームのバリデーションの実装追加
            if($('#div_err_companyName').css('display') === styleDisplayShow) {
                window.scrollTo(0, $('#div_err_companyName')[0].offsetTop - topPadding);
                returnFlag = true;
            }
            if($('#div_err_departmentName').css('display') === styleDisplayShow) {
                window.scrollTo(0, $('#div_err_departmentName')[0].offsetTop - topPadding);
                returnFlag = true;
            }
            if($('#div_err_lastName').css('display') === styleDisplayShow) {
                window.scrollTo(0, $('#div_err_lastName')[0].offsetTop - topPadding);
                returnFlag = true;
            }
            if($('#div_err_firstName').css('display') === styleDisplayShow) {
                window.scrollTo(0, $('#div_err_firstName')[0].offsetTop - topPadding);
                returnFlag = true;
            }
            // End 211101 Add: FCCL-2982
            // FCCL-2445: check if error popups are displayed and focus in first contact input
            if(functOtherContactErr().length > 0) {
                contactInputs[0].focus();
                returnFlag = true;
            }
            // FCCL-3131 bug fix
            $('div[id*="FFbe"]').each(function() {
                if ($(this).css('display') === styleDisplayShow) {
                    window.scrollTo(0, $(this)[0].offsetTop - topPadding);
                    returnFlag = true;
                    return;
                }
            });
            if(returnFlag) {
                e.preventDefault();
                loading.hide();
                return;
            }
            // Start 210106 Add: FCCL-2442
            var curStateVal = $curState.val();
            var curCityVal = $curCity.val();
            var curAddress1Val = $curAddress1.val();
            var postal = $('input[name$="_postalCode"]').val() || null;

            // FCCL-3039
            if (addressValidation) {
                $.ajax({
                    url: 'https://console.f-tra.com/zip/' + postal + '.js?encode=utf-8',
                    dataType: "text"
                })
                .done(function(data, textStatus, jqXHR) {
                    try {
                        if (jqXHR.status == 200) {
                            var stateComparison = data.indexOf('"0":"' + curStateVal + '"') >= 0 ? false : true;
                            $curState.val(curStateVal);
                            $curCity.val(curCityVal);
                            $curAddress1.val(curAddress1Val);
                            
                            if(postalErr.css('display') === styleDisplayShow || stateErr.css('display') === styleDisplayShow) {
                                window.scrollTo(0, $curPostal[0].offsetTop - topPadding);
                                returnFlag = true;
                            } else if (stateComparison) {
                                postalErr.find('.err_tooltip_msg').text($('#postal_error_2').val());
                                showPostalErr();
                                showStateErr();
                                window.scrollTo(0, $curPostal[0].offsetTop - topPadding);
                                returnFlag = true;
                            }
                            if(returnFlag) {
                                e.preventDefault();
                                loading.hide();
                                return;
                            }
                            // End 210106 Add: FCCL-2442
        
                            var isOldCreditCard = '0';
                            if ($('input[name="rd_credit_type"]').length > 1) {
                                isOldCreditCard = $('input[name="rd_credit_type"]:checked').val();
                            }
                            //End of FCCL-2554
        
                            var payment = $('input[name$="_paymentType"]:checked').val();
                            if (payment === undefined || payment === null) {
                                loading.hide();
                                return;
                            }
                        
                            // Start 201119 Add FCCL-2213
                            if (payment == $('#PaymentMethodValue_CARD').val()) {
                                var installmentType = $('input[name$="_installmentType"]:checked').val();
                                if (installmentType === undefined || installmentType === null) {
                                    $('#installment_error').css('display', 'block');
                                    window.scrollTo(0, document.getElementById('installment_error').offsetTop-110);
                                    e.preventDefault();
                                    loading.hide();
                                    return;
                                }
                            }
                            // End 201119 Add FCCL-2213
        
                            var url = util.appendParamToURL($('#billing_check_url').val(), 'paymentMethod', payment);
                            url = util.appendParamToURL(url, 'isOldCC', isOldCreditCard);
                            $.ajax({
                                url: url,
                                type: 'GET',
                                success: function (response) {
                                    if (response) {
                                        // FCCL-2469 add err msg
                                        page.redirect($('#cart_url').val() + '?billingRestrict=' + response);
                                    } else {
                                        $('input[name$="_lastName"]').prop('disabled',false);
                                        $('input[name$="_firstName"]').prop('disabled',false);
                                        $('input[name$="_lastNameKana"]').prop('disabled',false);
                                        $('input[name$="_firstNameKana"]').prop('disabled',false);
                                        if ($('#PaymentMethodValue_CARD').val() == payment) {
                                            if ($('input[name="rd_credit_type"]').length <= 1 || $('input[name="rd_credit_type"]:checked').val() == '0'){ // 210119 Mod: FCCL-2491
                                                $('#btn_GenerateToken').click();
                                            } else {
                                                $('input[name$="_creditCard_number"]').val('');
                                                $('select[name$="_month"]').val(null);
                                                $('select[name$="_year"]').val(null);
                                                $('button[name$="_save"]').click();
                                            }
                                        } else {
                                            $('button[name$="_save"]').click();
                                        }
                                    }
                                }, error:function(err){
                                    loading.hide();
                                    modal.dialog(err, function() {
                                        //@TODO
                                    });
                                }
                            });
                        } else {
                            $('button.form_addsearch').trigger('click');
                            loading.hide();
                            return;
                        }
                    } catch (error) {
                        $('button.form_addsearch').trigger('click');
                        loading.hide();
                        return;
                    }
                })
                .fail(function() {
                    $('button.form_addsearch').trigger('click');
                    loading.hide();
                    return;
                })
            } else {
                var isOldCreditCard = '0';
                if ($('input[name="rd_credit_type"]').length > 1) {
                    isOldCreditCard = $('input[name="rd_credit_type"]:checked').val();
                }
                // block check potalcode 31/3/2022 end 
                //End of FCCL-2554

                var payment = $('input[name$="_paymentType"]:checked').val();
                if (payment === undefined || payment === null) {
                    loading.hide();
                    return;
                }

                // Start 201119 Add FCCL-2213
                if (payment == $('#PaymentMethodValue_CARD').val()) {
                    var installmentType = $('input[name$="_installmentType"]:checked').val();
                    if (installmentType === undefined || installmentType === null) {
                        $('#installment_error').css('display', 'block');
                        window.scrollTo(0, document.getElementById('installment_error').offsetTop-110);
                        e.preventDefault();
                        loading.hide();
                        return;
                    }
                }
                // End 201119 Add FCCL-2213

                var url = util.appendParamToURL($('#billing_check_url').val(), 'paymentMethod', payment);
                url = util.appendParamToURL(url, 'isOldCC', isOldCreditCard);
                $.ajax({
                    url: url,
                    type: 'GET',
                    success: function (response) {
                        if (response) {
                            // FCCL-2469 add err msg
                            page.redirect($('#cart_url').val() + '?billingRestrict=' + response);
                        } else {
                            $('input[name$="_lastName"]').prop('disabled',false);
                            $('input[name$="_firstName"]').prop('disabled',false);
                            $('input[name$="_lastNameKana"]').prop('disabled',false);
                            $('input[name$="_firstNameKana"]').prop('disabled',false);
                            if ($('#PaymentMethodValue_CARD').val() == payment) {
                                if ($('input[name="rd_credit_type"]').length <= 1 || $('input[name="rd_credit_type"]:checked').val() == '0'){ // 210119 Mod: FCCL-2491
                                    $('#btn_GenerateToken').click();
                                } else {
                                    $('input[name$="_creditCard_number"]').val('');
                                    $('select[name$="_month"]').val(null);
                                    $('select[name$="_year"]').val(null);
                                    $('button[name$="_save"]').click();
                                }
                            } else {
                                $('button[name$="_save"]').click();
                            }
                        }
                    }, error:function(err){
                        loading.hide();
                        modal.dialog(err, function() {
                            //@TODO
                        });
                    }
                });
            }
        }
    });

    function updateDayOption() {
        var year = $birthYear.val();
        var month = $birthMonth.val();

        if (year != '') {
            if (year % 100 == 0) {
                year = year / 100;
            }
        } else {
            year = 1;
        }

        if (month == 1 || month == 3 || month == 5 || month == 7 || month == 8 || month == 10 || month == 12) {
            if ($birthDay.find('option[value="29"]').length == 0) {
                $birthDay.append($('<option/>', { value: 29, text: 29, label: 29, class: 'select-option' }));
            }
            if ($birthDay.find('option[value="30"]').length == 0) {
                $birthDay.append($('<option/>', { value: 30, text: 30, label: 30, class: 'select-option' }));
            }
            if ($birthDay.find('option[value="31"]').length == 0) {
                $birthDay.append($('<option/>', { value: 31, text: 31, label: 31, class: 'select-option' }));
            }
        } else if (month == 4 || month == 6 || month == 9 || month == 11) {  //201209 Mod: FCCL-2360
            if ($birthDay.find('option[value="29"]').length == 0) {
                $birthDay.append($('<option/>', { value: 29, text: 29, label: 29, class: 'select-option' }));
            }
            if ($birthDay.find('option[value="30"]').length == 0) {
                $birthDay.append($('<option/>', { value: 30, text: 30, label: 30, class: 'select-option' }));
            }
            if ($birthDay.find('option[value="31"]').length > 0) {
                $birthDay.find('option[value="31"]').remove();
            }
        } else if (year % 4 == 0) {
            if ($birthDay.find('option[value="29"]').length == 0) {
                $birthDay.append($('<option/>', { value: 29, text: 29, label: 29, class: 'select-option' }));
            }
            if ($birthDay.find('option[value="30"]').length > 0) {
                $birthDay.find('option[value="30"]').remove();
            }
            if ($birthDay.find('option[value="31"]').length > 0) {
                $birthDay.find('option[value="31"]').remove();
            }
        } else {
            if ($birthDay.find('option[value="29"]').length > 0) {
                $birthDay.find('option[value="29"]').remove();
            }
            if ($birthDay.find('option[value="30"]').length > 0) {
                $birthDay.find('option[value="30"]').remove();
            }
            if ($birthDay.find('option[value="31"]').length > 0) {
                $birthDay.find('option[value="31"]').remove();
            }
        }
    }

    $birthYear.off().on('focusout', function() {
        updateDayOption();
    });

    $birthMonth.off().on('change', function() {
        updateDayOption();
    });

    $('input.js-form-same').off().on('change', function(){
        loading.show();
        var url = util.appendParamToURL($('.form_checkboxWrap-same').attr('url'), 'val', $(this).prop('checked'))
        var type = 'GET';
        $.getJSON(url, type, function (data) {
            //basket check for displaying the payment section, if the adjusted total of the basket is 0 after applying the coupon
            //this will force a page refresh to display the coupon message based on a parameter message
            if (data.success) {
                if ($('input[name="login"]').val() && !$.parseJSON($('input[name="login"]').val().toLowerCase())) {
                    $('input[name$="_lastName"]').prop('value',data.lastName);
                    $('input[name$="_firstName"]').prop('value',data.firstName);
                    $('input[name$="_lastNameKana"]').prop('value',data.lastNameKana);
                    $('input[name$="_firstNameKana"]').prop('value',data.firstNameKana);
                }
                $('input[name$="_postalCode"]').prop('value',data.postalCode);
                $('select[name$="_state"]').prop('value',data.state);
                $('input[name$="_city"]').prop('value',data.city);
                $('input[name$="_address1"]').prop('value',data.address1);
                $('input[name$="_phone11"]').prop('value',data.phone11);
                $('input[name$="_phone12"]').prop('value',data.phone12);
                $('input[name$="_phone13"]').prop('value',data.phone13);
                //FCCL-2118 BangLy 12/8/2020 Mod-start
                if ($('#callCenterFlag').val() == 'true') {  // 201217 Mod: FCCL-2367
                    $('input[name$="_phone21CallCenter"]').prop('value',data.phone21);
                    $('input[name$="_phone22CallCenter"]').prop('value',data.phone22);
                    $('input[name$="_phone23CallCenter"]').prop('value',data.phone23);
                } else {
                    $('input[name$="_phone21"]').prop('value',data.phone21);
                    $('input[name$="_phone22"]').prop('value',data.phone22);
                    $('input[name$="_phone23"]').prop('value',data.phone23);
                }
                //FCCL-2118 BangLy 12/8/2020 Mod-End

                if ($('input[name$="_companyName"]').length > 0) {
                    $('input[name$="_companyName"]').prop('value',data.companyName);
                }
                if ($('input[name$="_corporateNameKana"]').length > 0) {
                    $('input[name$="_corporateNameKana"]').prop('value',data.corporateNameKana);
                }
                if ($('input[name$="_departmentName"]').length > 0) {
                    $('input[name$="_departmentName"]').prop('value',data.departmentName);
                }
            }
            loading.hide();
        });
    });

    // FCCL-3086
    var redirectEMV3D = 4;
    $('input[id*="_sbps_token"]').val('');
    $('input[id*="_sbps_tokenKey"]').val('');
    $('input[id*="_sbps_tds2infoToken"]').val(''); // FCCL-3086
    $('input[id*="_sbps_tds2infoTokenKey"]').val(''); // FCCL-3086

    $('#btn_GenerateToken').off().on( 'click' , function(){
        sbpsGenerateToken();
        return false;
    });

    function sbpsGenerateToken() {
        // FCCL-3086
        // if ($('#paymentCode').val() == redirectEMV3D) {
        //     var lastName = $('input[name$="_billingaddress_lastName"]').val();
        //     var firstName = $('input[name$="_billingaddress_firstName"]').val();
        //     if (!validateNameBilling(firstName, lastName)) {
        //         return true;
        //     }
        // }
        var sbpsMerchantId = $('#curMerchant_id').val();
        sbpsMerchantId = sbpsMerchantId + '';
        var sbpsServiceId  = $('#curService_id').val();
        sbpsServiceId  = ('00' + sbpsServiceId).slice(-3) + '';
        var sbpsCreditNum  = $('input[name$="_creditCard_number"]').val();
        // FCCL-2656 : bangly 3/9/2021 start
        sbpsCreditNum = (sbpsCreditNum + '').split(' ').join('');
        // FCCL-2656 : bangly 3/9/2021 end
        var sbpsCreditExp  = $('select[name$="_year"]').val().slice(0,4) + $('select[name$="_month"]').val();
        sbpsCreditExp  = sbpsCreditExp + '';
        var sbpsCreditCvn  = $('input[name$="_cvn"]').val();
        sbpsCreditCvn  = ('0' + sbpsCreditCvn).slice(-4) + ''; // FCCL-3083

        function generateToken() {
            com_sbps_system.generateToken({
                merchantId : sbpsMerchantId,
                serviceId : sbpsServiceId,
                ccNumber : sbpsCreditNum,
                ccExpiration : sbpsCreditExp,
                securityCode : sbpsCreditCvn
            }, afterGenerateToken);
        }

        var afterGenerateToken = function(response) {

            if (response.result == 'OK') {
                // Remove Credit Card Value
                //$('input[name$="creditCard_owner"]').val('');
                //$('select[name$="_type"]').val(null);
                // Start 201215 Del: FCCL-2367
                //$('input[name$="_creditCard_number"]').val('');
                //$('select[name$="_month"]').val(null);
                //$('select[name$="_year"]').val(null);
                // End 201215 Del: FCCL-2367
                //$('input[name*="_cvn"]').val('');

                $('input[id*="_sbps_token"]').val(response.tokenResponse.token);
                $('input[id*="_sbps_tokenKey"]').val(response.tokenResponse.tokenKey);
                // FCCL-3086
                if ($('#paymentCode').val() == redirectEMV3D) {
                    doSubmit3ds2();
                }
                else {
                    $('button[name$="_save"]').click();
                }
            } else {
                loading.hide();
                // FCCL-3086
                modal.dialog(showErrorCodeOneTimeToken(response.errorCode), function() {
                    //@TODO
                });
            }
        }
        generateToken();
    }
    // FCCL-3086
    function validateNameBilling(firstName, lastName) {
        var valid = true;
        var regex = new RegExp("^[a-zA-Z0-9 ]+$");
        if (!regex.test(lastName)) {
            alert("${Resource.msg('sbps.billingpayment.errorlastname','sbps',null)}");
            valid = false;
        }
        if (!regex.test(firstName)) {
            alert("${Resource.msg('sbps.billingpayment.errorfirstname','sbps',null)}");
            valid = false;
        }
        return valid;
    }
    function doSubmit3ds2(){
            var sbpsMerchantId = $('#curMerchant_id').val();
            sbpsMerchantId = sbpsMerchantId + '';
            var sbpsServiceId  = $('#curService_id').val();
            sbpsServiceId  = ('00' + sbpsServiceId).slice(-3) + '';
            // var postalCodeBilling = $('input[name$="_billingaddress_postalCode"]').val();
            // var postalCodeShipping = "${pdict.Basket.defaultShipment.shippingAddress.postalCode}";
            // postalCodeBilling = postalCodeBilling.replace('-','');
            // postalCodeShipping = postalCodeShipping.replace('-','');
            // トークン生成ロジック呼び出し
            var objParamRequest = {
                merchantId : sbpsMerchantId,
                serviceId : sbpsServiceId };
                // billingLastName : $('input[name$="_billingaddress_lastName"]').val(),
                // billingFirstName : $('input[name$="_billingaddress_firstName"]').val(),
                // billingPostalCode : postalCodeBilling,
                // billingCity : $('#dwfrm_billing_billingAddress_addressFields_city').val(),
                // billingAddress1 : $('#dwfrm_billing_billingAddress_addressFields_address1').val(),
                // billingAddress2 : $('#dwfrm_billing_billingAddress_addressFields_address2').val(),
                // billingAddress3 : '',
                // billingPhone : $('#dwfrm_billing_billingAddress_addressFields_phone').val(),
                // workPhone : $('#dwfrm_billing_billingAddress_addressFields_phone').val(),
                // shippingPostalCode : postalCodeShipping,
                // shippingCity : "${pdict.Basket.defaultShipment.shippingAddress.city}",
                // shippingAddress1 : "${pdict.Basket.defaultShipment.shippingAddress.address1}",
                // shippingAddress2 : "${pdict.Basket.defaultShipment.shippingAddress.address2}",
                // shippingAddress3 : '',
                // email : $('#dwfrm_billing_billingAddress_email_emailAddress').val() };
            
            com_sbps_system_tds2.generateToken( objParamRequest, afterGenerate3ds2Token);
    }

    function afterGenerate3ds2Token(response) {
        if (response.result == "OK") {
            $('input[id*="_sbps_tds2infoToken"]').val(response.tokenResponse.tds2infoToken);
            $('input[id*="_sbps_tds2infoTokenKey"]').val(response.tokenResponse.tds2infoTokenKey);
            $('button[name$="_save"]').click();
        }
        else {
            var errorCode = response.errorCode.toString();
            // alert(showErrorCode(errorCode));
            console.log(response);
            alert(Resources.ERROR_PAYMENT_WARN);
        }
    }

    function showErrorCode(code) {
        var prefix = code.substring(0, 2);
        var suffix = code.substring(2, 5);
        var arrErrorPrefix = {  "03": "${Resource.msg('sbps.billingtoken.error03','sbps',null)}",
                                "04": "${Resource.msg('sbps.billingtoken.error04','sbps',null)}",
                                "05": "${Resource.msg('sbps.billingtoken.error05','sbps',null)}",
                                "07": "${Resource.msg('sbps.billingtoken.error07','sbps',null)}",
                                "99": "${Resource.msg('sbps.billingtoken.error99','sbps',null)}"};
        var arrErrorSuffix = {  "001": "${Resource.msg('sbps.billingtoken.error001','sbps',null)}",
                                "002": "${Resource.msg('sbps.billingtoken.error002','sbps',null)}",
                                "003": "${Resource.msg('sbps.billingtoken.error003','sbps',null)}",
                                "004": "${Resource.msg('sbps.billingtoken.error004','sbps',null)}",
                                "005": "${Resource.msg('sbps.billingtoken.error005','sbps',null)}",
                                "006": "${Resource.msg('sbps.billingtoken.error006','sbps',null)}",
                                "007": "${Resource.msg('sbps.billingtoken.error007','sbps',null)}",
                                "008": "${Resource.msg('sbps.billingtoken.error008','sbps',null)}",
                                "009": "${Resource.msg('sbps.billingtoken.error009','sbps',null)}",
                                "010": "${Resource.msg('sbps.billingtoken.error010','sbps',null)}",
                                "011": "${Resource.msg('sbps.billingtoken.error011','sbps',null)}",
                                "012": "${Resource.msg('sbps.billingtoken.error012','sbps',null)}",
                                "013": "${Resource.msg('sbps.billingtoken.error013','sbps',null)}",
                                "014": "${Resource.msg('sbps.billingtoken.error014','sbps',null)} ",
                                "015": "${Resource.msg('sbps.billingtoken.error015','sbps',null)} ",
                                "016": "${Resource.msg('sbps.billingtoken.error016','sbps',null)} ",
                                "017": "${Resource.msg('sbps.billingtoken.error017','sbps',null)}",
                                "999": "${Resource.msg('sbps.billingtoken.error999','sbps',null)}",
                             };
        return arrErrorPrefix[prefix] + arrErrorSuffix[suffix];
    }

    function showErrorCodeOneTimeToken(code) {
        var prefix = code.substring(0, 2);
        var suffix = code.substring(2, 5);
        var arrErrorPrefix = {  "03": "${Resource.msg('sbps.onetimetoken.error03','sbps',null)}",
                                "04": "${Resource.msg('sbps.onetimetoken.error04','sbps',null)} ",
                                "05": "${Resource.msg('sbps.onetimetoken.error05','sbps',null)}",
                                "07": "${Resource.msg('sbps.onetimetoken.error07','sbps',null)}",
                                "99": "${Resource.msg('sbps.onetimetoken.error99','sbps',null)}"};
        var arrErrorSuffix = {  "001": "${Resource.msg('sbps.onetimetoken.error001','sbps',null)}",
                                "002": "${Resource.msg('sbps.onetimetoken.error002','sbps',null)}",
                                "003": "${Resource.msg('sbps.onetimetoken.error003','sbps',null)}",
                                "004": "${Resource.msg('sbps.onetimetoken.error004','sbps',null)}",
                                "005": "${Resource.msg('sbps.onetimetoken.error005','sbps',null)}",
                                "999": "${Resource.msg('sbps.onetimetoken.error999','sbps',null)}",
                             };
        return arrErrorPrefix[prefix] + arrErrorSuffix[suffix];
    }

    // FCCL-2894: Function edit or create hidden input value on page
    function modifyIfHiddenTagExists(id, value) {
        if ($('#' + id + '').length) {
            $('#' + id + '').val(value);
        } else {
            $('#dwfrm_billing').append('<input type="hidden" style="display: none" id="' + id + '" value="' + value + '" >')
        }
    }

    $('.jsInstallment').off().on('change', 'input', function (e) {
        if($(this).prop('checked')) {
            modifyIfHiddenTagExists('payment-selected-installment', this.value);
        }
        // FCCL-2890: Add more function for only set checked input field
        if ($(this).prop('checked') && this.value == '61') {
            $('.jsInstallment').find('select').removeAttr('disabled');
        } else {
            $('.jsInstallment').find('select').attr('disabled', true);
        }
    });

    $('.jsInstallment select[name$="_installmentNo"]').off().on('change', function() {
        // FCCL-2894: Change value if selected value changes
        modifyIfHiddenTagExists('payment-selected-installmentNo', $(this).val());
    });

    if ($('.checkout-billing').length > 0) {
        $('footer').css('margin-top', '170px');
    }

    // Start 210114 Add: FCCL-2439
    function enableRadioFocus(inputName) {
        $('span.form_radio:has(input[name$="' + inputName + '"]),.accountForm_radio:has(input[name$="' + inputName + '"])').each(function() {
            $(this).attr('tabindex', 0);
        });
    }

    function disableRadioFocus(inputName) {
        $('span.form_radio:has(input[name$="' + inputName + '"]),.accountForm_radio:has(input[name$="' + inputName + '"])').each(function() {
            $(this).removeAttr('tabindex');
        });
    }
    // End 210114 Add: FCCL-2439

    function enableCreditCardInput() {
        if ($('input[name$="_paymentType"]:checked').val() == $('#PaymentMethodValue_CARD').val()) {
            enableRadioFocus('rd_credit_type');  // 210114 Add: FCCL-2439
            $('input[name$="rd_credit_type"]').removeAttr('disabled');
            $('input[name*="_cvn"]').removeAttr('disabled');

            // Start 210119 Mod: FCCL-2491
            enableRadioFocus('_installmentType');  // 210114 Add: FCCL-2439
            $('input[name$="_installmentType"]').removeAttr('disabled');
            // FCCL-2890: Trigger change event on radio input
            $('.jsInstallment input').trigger('change');
            if ($('input[name$="rd_credit_type"]').length == 1) {
                $('input[name$="_creditCard_number"]').removeAttr('disabled');
                $('select[name$="_month"]').removeAttr('disabled');
                $('select[name$="_year"]').removeAttr('disabled');
            } else {
                // FCCL-2890: Check radio button on credit card
                if($('input[name$="rd_credit_type"]:checked').length === 0) {
                    var $radio = $('input[name$="rd_credit_type"][has_selected=true]');
                    if($radio.length === 0) {
                        $('input[name$="rd_credit_type"][value=1]').prop('checked', true);
                    } else {
                        $radio.prop('checked', true);
                    }
                } else {
                    $('input[name$="rd_credit_type"]:checked').attr('has_selected', true);
                }
                
                if ($('input[name$="rd_credit_type"]:checked').val() == '0') {
                    $('input[name$="_creditCard_number"]').removeAttr('disabled');
                    $('select[name$="_month"]').removeAttr('disabled');
                    $('select[name$="_year"]').removeAttr('disabled');
                } else {
                    $('input[name$="_creditCard_number"]').attr('disabled', true);
                    $('select[name$="_month"]').attr('disabled', true);
                    $('select[name$="_year"]').attr('disabled', true);
                }
            }
            // End 210119 Mod: FCCL-2491
        } else {
            disableRadioFocus('rd_credit_type');  // 210114 Add: FCCL-2439
            $('input[name$="rd_credit_type"]').attr('disabled', true);
            $('input[name$="_creditCard_number"]').attr('disabled', true);
            $('select[name$="_month"]').attr('disabled', true);
            $('select[name$="_year"]').attr('disabled', true);
            $('input[name*="_cvn"]').attr('disabled', true);
            disableRadioFocus('_installmentType');  // 210114 Add: FCCL-2439
            $('input[name$="_installmentType"]').attr('disabled', true);
            $('select[name*="_installmentNo"]').attr('disabled', true);
            // FCCL-2465 remove checked
            if($('input[name$="rd_credit_type"]').length > 1) {
                $('input[name$="rd_credit_type"]').prop('checked', false);
            }
            $('input[name$="_installmentType"]').prop('checked', false);
            $('select[name*="_installmentNo"]').prop('checked', false);
        }
    }

    if ($('input[name$="_paymentType"]').length > 0) {
        enableCreditCardInput();
        $('input[name$="_paymentType"]').off().on('change', function() {
            // FCCL-2890: Load credit card value if current selected payment method is credit card
            if($(this).val() === '21') {
                loadCreditCardValue();
            }
            savePaymentSelected();
            enableCreditCardInput();
        });
        $('input[name$="rd_credit_type"]').off().on('change', function() {
            // Remove all has_selected property in input
            $('input[name$="rd_credit_type"]').removeAttr('has_selected');
            $(this).attr('has_selected', true); // Set this option
            enableCreditCardInput();
        });
    }

    // Start 201210 Add: FCCL-2331
    var timeCount = maxTimeCount;
    $('button.form_addsearch').off().on('click', function(e) {
        if (timeCount < maxTimeCount) {
            return;
        }
        timeCount = 0;
        var cPostal = $curPostal.val();
        if (cPostal.length != maxPostalLength) {
            postalErr.find('.err_tooltip_msg').text($('#postal_error_1').val());
            showPostalErr();
            e.preventDefault();
            timeCount = maxTimeCount;
            return;
        }

        if (cPostal == $oldPostalFtra.val()) {
            $curState.val($oldStateFtra.val());
            $curCity.val($oldCityFtra.val());
            $curAddress1.val($oldAddress1Ftra.val());

                $curState.trigger('change');
                $curCity.trigger('change');
                $curAddress1.trigger('change');

            postalErr.hide();
            stateErr.hide();

            e.preventDefault();
            timeCount = maxTimeCount;
            return;
        }

        var curPostal = $curPostal.val();
        var curState = $curState.val();
        var curCity = $curCity.val();
        var curAddress1 = $curAddress1.val();

        postalErr.hide();
        stateErr.hide();

        // Start 201218 Add: FCCL-2393
        $defaultStateOption.text($('select[name$="_state"] option:selected').text());
        $defaultStateOption.prop('label', $('select[name$="_state"] option:selected').text());
        // End 201218 Add: FCCL-2393
        $curState.val('');
        loading.show();  // 210120 Add: FCCL-2485
        var myInv = setInterval(function() {
            var val = $curState.val();
            if (val && val != '') {
                // Start 201218 Add: FCCL-2393
                $defaultStateOption.text(defaultStateText);
                $defaultStateOption.prop('label', defaultStateText);
                // End 201218 Add: FCCL-2393
                $oldPostalFtra.val($curPostal.val());
                $oldStateFtra.val($curState.val());
                $oldCityFtra.val($curCity.val());
                $oldAddress1Ftra.val($curAddress1.val());
                clearInterval(myInv);
                loading.hide();  // 210120 Add: FCCL-2485
                timeCount = maxTimeCount;
                return;
            }
            timeCount = timeCount + 1;
            if (timeCount >= maxTimeCount) {
                // Start 201218 Add: FCCL-2393
                $defaultStateOption.text(defaultStateText);
                $defaultStateOption.prop('label', defaultStateText);
                // End 201218 Add: FCCL-2393
                postalErr.find('.err_tooltip_msg').text($('#postal_error_1').val());
                showPostalErr();
                $curPostal.val(curPostal);
                $curState.val(curState);
                $curCity.val(curCity);
                $curAddress1.val(curAddress1);
                clearInterval(myInv);
                loading.hide();  // 210120 Add: FCCL-2485
            }
        }, intervalTime);
    });

    $curPostal.off().on('focusin', function() {
        checkPostal();
    });

    $curPostal.off().on('focusout', function() {
        checkPostal();
    });

    $curState.off().on('focusin', function() {
        checkState();
    });

    $curState.off().on('focusout', function() {
        checkState();
    });

    $curState.off().on('change', function() {
        checkState();
    });

    // FCCL-2688: Set events of each fields
    if((contactInputs.length > 0 && contactInputs[0].name.indexOf('CallCenter') > 0) || isB2b) {
        contactInputs.each(function() {
            $(this).off().off().on('focusout', function() {
                checkOtherContact(contactInputs);
            });
        });
    }
    

    function checkOtherContact(inputs) {
        if((inputs.length > 0 && inputs[0].name.indexOf('CallCenter') > 0) || isB2b) {
            inputs.each(function(index, element) {
                if($(this).val() !== '') {
                    showContactErr(inputs, index);
                    return false;
                }
                else {
                    hideContactErr(index);
                }
            });
        }
    }

    function showContactErr(contactInputs, errIndex) {
        contactInputs.each(function(index, item) {
            if(index === errIndex || $(this).val() != '') {
                hideContactErr(index);
                return;
            }
            var contactErr = $(otherContactErr[index]);
            contactErr.css('display', styleDisplayShow);
            contactErr.css('left', $(item).offset().left);
            contactErr.css('top', $(item).offset().top - OFFSET_TOP_UPPER);
        });
        
    }
    function hideContactErr(errIndex) {
        $(otherContactErr[errIndex]).hide();
    }

    function checkPostal() {
        postalErr.hide();
        var old = $oldPostalFtra.val();
        if (old) {
            if (old != $curPostal.val() || $oldStateFtra.val() != $curState.val()) {
                postalErr.find('.err_tooltip_msg').text($('#postal_error_2').val());
                showPostalErr();
            }
        }
    }

    function checkState() {
        stateErr.hide();
        if ($oldPostalFtra.val()) {
            if ($oldStateFtra.val() != $curState.val()) {
            showStateErr();
            }
        }
    }

    function showPostalErr() {
        // FCCL-3039
        if (addressValidation) {
            var searchBtn = $('input[name$="_postal"]')[0];
            postalErr.css('display', styleDisplayShow);
            // FCCL-3131 【F-88】スマホのときの、フォームバリデーションのメッセージ表示位置が見切れる
            postalErr.css('left', searchBtn.offsetLeft);
            postalErr.css('top', searchBtn.offsetTop - searchBtn.offsetHeight);
        }
    }

    function showStateErr() {
        // FCCL-3039
        if (addressValidation) {
            var stateDiv = $('.form_select-prefectures')[0];
            stateErr.css('display', styleDisplayShow);
            stateErr.css('left', stateDiv.offsetLeft + stateDiv.offsetWidth + leftPadding);
            stateErr.css('top', stateDiv.offsetTop + topPadding);
        }
    }

    // Start 211101 Add: FCCL-2982
    function checkInputError(str) {
        if (str.length == 0) return false;
        var whiteList = Resources.CHAR_WHITE_LIST;
        for (var i=0; i < str.length; i++) {
            var c = str.charAt(i);
            if (whiteList.indexOf(c) < 0) {
                return true;
            }
        }
        return false;
    }
    function showCityErr() {
        var input = $('input[name$="_city"]')[0];
        var err = $('#div_err_city');
        if (checkInputError(input.value)) {
            err.css('display', styleDisplayShow);
            // FCCL-3131 【F-88】スマホのときの、フォームバリデーションのメッセージ表示位置が見切れる
            err.css('left', input.offsetLeft);
            err.css('top', input.offsetTop - input.offsetHeight);
        } else {
            err.hide();
        }
    }
    function showAddress1Err() {
        var input = $('input[name$="_address1"]')[0];
        var err = $('#div_err_address1');
        if (checkInputError(input.value)) {
            err.css('display', styleDisplayShow);
            // FCCL-3131 【F-88】スマホのときの、フォームバリデーションのメッセージ表示位置が見切れる
            err.css('left', input.offsetLeft);
            err.css('top', input.offsetTop - input.offsetHeight);
        } else {
            err.hide();
        }
    }
    $('input[name$="_city"]').on('input change focusout', function() {
        showCityErr();
    });
    $('input[name$="_address1"]').on('input change focusout', function() {
        showAddress1Err();
    });
    // End 211101 Add: FCCL-2982
    // End 201210 Add: FCCL-2331

    // FCCL-3069 billing
    $('input[name$="_lastName"]').on('input change focusout', function() {
        showLastnameErr();
    });
    function showLastnameErr() {
        var input = $('input[name$="_lastName"]')[0];
        var err = $('#div_err_lastName');
        if (checkInputError(input.value)) {
            err.css('display', styleDisplayShow);
            err.css('left', input.offsetLeft);
            err.css('top', input.offsetTop - input.offsetHeight);
        } else {
            err.hide();
        }
    }
    $('input[name$="_firstName"]').on('input change focusout', function() {
        showFirstnameErr();
    });
    function showFirstnameErr() {
        var input = $('input[name$="_firstName"]')[0];
        var err = $('#div_err_firstName');
        if (checkInputError(input.value)) {
            err.css('display', styleDisplayShow);
            // FCCL-3131 【F-88】スマホのときの、フォームバリデーションのメッセージ表示位置が見切れる
            err.css('left', input.offsetLeft);
            err.css('top', input.offsetTop - input.offsetHeight);
        } else {
            err.hide();
        }
    }
    $('input[name$="_companyName"]').on('input change focusout', function() {
        showCompanyNameErr();
    });
    function showCompanyNameErr() {
        var input = $('input[name$="_companyName"]')[0];
        var err = $('#div_err_companyName');
        if (checkInputError(input.value)) {
            err.css('display', styleDisplayShow);
            // FCCL-3131 【F-88】スマホのときの、フォームバリデーションのメッセージ表示位置が見切れる
            err.css('left', input.offsetLeft);
            err.css('top', input.offsetTop - input.offsetHeight);
        } else {
            err.hide();
        }
    }
    $('input[name$="_departmentName"]').on('input change focusout', function() {
        showDepartmentNameErr();
    });
    function showDepartmentNameErr() {
        var input = $('input[name$="_departmentName"]')[0];
        var err = $('#div_err_departmentName');
        if (checkInputError(input.value)) {
            err.css('display', styleDisplayShow);
            // FCCL-3131 【F-88】スマホのときの、フォームバリデーションのメッセージ表示位置が見切れる
            err.css('left', input.offsetLeft);
            err.css('top', input.offsetTop - input.offsetHeight);
        } else {
            err.hide();
        }
    }

    // Start 210114 Add: FCCL-2439
    var KEY_ENTER = 13;
    var KEY_SPACE = 32;
    $('span.form_checkbox').off().on('keypress', function(e) {
        if (e.keyCode == KEY_SPACE || e.keyCode == KEY_ENTER) {
            e.preventDefault();
            $(this).find('input')[0].checked = $(this).find('input')[0].checked != true;
            $(this).find('input').trigger('change');
        }
    });

    $('span.form_radio,.accountForm_radio').off().on('keypress', function(e) {
        if (e.keyCode == KEY_SPACE || e.keyCode == KEY_ENTER) {
            e.preventDefault();
            $(this).find('input')[0].checked = true;
            $(this).find('input').trigger('change');
        }
    });

    // FCCL-2852: Add condition check if this init function is not called by ajax
    if ($('input[name=ajax_called]').length === 0 && $('.form_checkbox').length > 0) {
        document.getElementsByClassName('form_checkbox')[0].focus();
    }
    // End 210114 Add: FCCL-2439

    // Start 210301 Add: FCCL-2651
    if ($('#no_payment_error').length > 0) {
        modal.dialog($('#no_payment_error').val(), function() {
            //@TODO
            page.redirect($('#cart_url').val());
        });
    }
    // End 210301 Add: FCCL-2651

    //T11
    $('#chk_agree_confirm').off().on('change', function() {
        if (this.checked) {
            $('#btn_summary_next').removeAttr('disabled');
            $('#btn_summary_next').css('background-color', '#C00');
        } else {
            $('#btn_summary_next').attr('disabled', true);
            $('#btn_summary_next').css('background-color', '#606A76');
        }
    });

    $('#btn_summary_next').css('background-color', '#606A76');

    $('#btn_summary_next').off().on('click', function() {
        loading.show();
    });

    if ($('#summary-error-text').val() != undefined && $('#summary-error-text').val() != 'null' && $('#summary-error-text').val() != '') {
        modal.dialog($('#summary-error-text').val(), function() {
            //@TODO
        });
    }
};