'use strict';

var giftcert = require('../giftcert'),
    tooltip = require('../tooltip'),
    util = require('../util'),
    dialog = require('../dialog'),
    page = require('../page'),
    login = require('../login'),
    validator = require('../validator'),
    modal = require('../modal'),
    productCart = require('./product/productCart'),
    loading = require('../loading'),
    contactus = require('./content/contactus'),
    findorder = require('../findorder'),
    jqueryCustom = require('../jquery-custom');

// FCCL-2445: Declare variables for checking edit account and edit address screen
var styleDisplayShow = 'block';
var otherContactErr = $('[id*=div_err_other_contacts]'); // Store error popups
var functOtherContactErr = function () {
    return otherContactErr.filter(function () {
        return $(this).css('display') === styleDisplayShow; // get popup with display show
    });
}

const OFFSET_TOP_UPPER = 34; // Set offset top for popup
/**
 * Show error of other contact when user enter 1 input field
 * @param {JqueryObjects} contactInputs Input fields of other contact
 * @param {Number} errIndex The index of input field that user entered
 */
function showContactErr(contactInputs, errIndex) {
    contactInputs.each(function(index, item) {
        if(index === errIndex || $(this).val() != '') {
            hideContactErr(index);
            return;
        }
        var contactErr = $(otherContactErr[index]);
        contactErr.css('display', styleDisplayShow);
        contactErr.css('left', $(item).offset().left);
        contactErr.css('top', $(item).offset().top - OFFSET_TOP_UPPER);
    });
    
}
/**
 * Hide error popup at specific index
 * @param {Number} errIndex The index of error popup to hide
 */
function hideContactErr(errIndex) {
    $(otherContactErr[errIndex]).hide();
}
/**
 * Validate all contact input fields
 * @param {JQueryObject} contactInputs Contact input fields to validate
 */
function checkOtherContact(contactInputs) {
    // var inputFields= $('input[name*="contact__phone"]');
    contactInputs.each(function(index, element) {
        if($(this).val() !== '') {
            showContactErr(contactInputs, index);
            return false;
        }
        else {
            hideContactErr(index);
        }
    });
}

/**
 * @function
 * @description Initializes the events on the address form (apply, cancel, delete)
 * @param {Element} form The form which will be initialized
 */
function initializeAddressForm() {
    // Start 201210 Add: FCCL-2331
    var postalErr = $('#div_err_check_postal');
    var stateErr = $('#div_err_change_state');
    // FCCL-2445: re-declare error popup
    otherContactErr = $('[id*=div_err_other_contacts]');
    initializeAccountForms();
    var maxPostalLength = 7;
    var maxTimeCount = 30;  // 210120 Mod: FCCL-2485
    var intervalTime = 100;
    var topPadding = 5;
    
    var leftPadding = 15;
    var $oldStateFtra = $('#old_state');
    var $oldPostalFtra = $('#old_postal');
    var $oldCityFtra = $('#old_city');
    var $oldAddress1Ftra = $('#old_address1');
    var $curState = $('select[name$="_state"]');
    var $curPostal = $('input[name$="_postal"]');
    var $curCity = $('input[name$="_city"]');
    var $curAddress1 = $('input[name$="_address1"]');
    // Start 201218 Add: FCCL-2393
    var $defaultStateOption = $('select[name$="_state"] option[value=""]');
    var defaultStateText = $defaultStateOption.text();
    // End 201218 Add: FCCL-2393
    // End 201210 Add: FCCL-2331
    var timeCount = maxTimeCount;  // 210106 Add: FCCL-2442
    var $form = $('#edit-address-form');

    $form.find('input[name="format"]').remove();
    // tooltip.init();
    //$("<input/>").attr({type:"hidden", name:"format", value:"ajax"}).appendTo(form);
    // FCCL-2070: Add csrf for all forms
    page.getAndInsertCSRFToken(null);
    $form.off()
        .on('click', '.apply-button', function (e) {
            // FCCL-2612 レスポンスが来るまで待機するように変更
            loading.show();

            // FCCL-3115 【F-73】入力フォームのバリデーションの実装追加
            $('input').each(function(){
                $(this).focus().blur();
            });

            // Start 201210 Add: FCCL-2331
            if ($curPostal.length == 0) {
                loading.hide();
                return;
            }
            // FCCL-2445: Add prevent flag for checking multiple conditions and show all of errors
            var preventFlag = false;
            // End 201210 Add: FCCL-2331
            var errStatus = page.showFormSubmissionErrors(e);
            if(errStatus && errStatus.isDefaultPrevented()) {
                preventFlag = true;
            }
            e.preventDefault();
            if (!$form.valid()) {
                loading.hide();
                return false;
            }
            // FCCL-2445: Get all other contact phone
            var $phone = $('input[name*="contact__phone"]')

            // Start 201210 Add: FCCL-2331
            if (postalErr.css('display') === styleDisplayShow || stateErr.css('display') === styleDisplayShow) {
                preventFlag = true;
            }
            // End 201210 Add: FCCL-2331
            // Start 211101 Add: FCCL-2982
            if($('#div_err_city').css('display') === styleDisplayShow) {
                window.scrollTo(0, $('#div_err_city')[0].offsetTop - topPadding);
                preventFlag = true;
            }
            if($('#div_err_address1').css('display') === styleDisplayShow) {
                window.scrollTo(0, $('#div_err_address1')[0].offsetTop - topPadding);
                preventFlag = true;
            }
            // FCCL-3115 【F-73】入力フォームのバリデーションの実装追加
            if($('#div_err_companyName').css('display') === styleDisplayShow) {
                window.scrollTo(0, $('#div_err_companyName')[0].offsetTop - topPadding);
                preventFlag = true;
            }
            if($('#div_err_departmentName').css('display') === styleDisplayShow) {
                window.scrollTo(0, $('#div_err_departmentName')[0].offsetTop - topPadding);
                preventFlag = true;
            }
            if($('#div_err_lastname').css('display') === styleDisplayShow) {
                window.scrollTo(0, $('#div_err_lastname')[0].offsetTop - topPadding);
                preventFlag = true;
            }
            if($('#div_err_firstname').css('display') === styleDisplayShow) {
                window.scrollTo(0, $('#div_err_firstname')[0].offsetTop - topPadding);
                preventFlag = true;
            }
            // End 211101 Add: FCCL-2982
            // FCCL-2445: Check if error popup is displayed, focus in first input field and prevent event
            if(functOtherContactErr().length > 0) {
                $phone[0].focus();
                preventFlag = true;
            }
            if(preventFlag) {
                e.preventDefault();
                loading.hide();
                return;
            }
            // Start 210106 Add: FCCL-2442
            var curStateVal = $curState.val();
            var curCityVal = $curCity.val();
            var curAddress1Val = $curAddress1.val();
            var postal = $('input[name$="_postal"]').val() || null;
            $.ajax({
                url: 'https://console.f-tra.com/zip/' + postal + '.js?encode=utf-8',
                dataType: "text"
            })
            .done(function(data, textStatus, jqXHR) {
                try {
                    if (jqXHR.status == 200) {
                        var stateComparison = data.indexOf('"0":"' + curStateVal + '"') >= 0 ? false : true;
                        $curState.val(curStateVal);
                        $curCity.val(curCityVal);
                        $curAddress1.val(curAddress1Val);
                        if(postalErr.css('display') === styleDisplayShow || stateErr.css('display') === styleDisplayShow) {
                            window.scrollTo(0, $curPostal[0].offsetTop - topPadding);
                            preventFlag = true;
                            loading.hide();
                            return;
                        } else if (stateComparison) {
    
                            postalErr.find('.err_tooltip_msg').text($('#postal_error_2').val());
                            showPostalErr();
                            showStateErr();
    
                            window.scrollTo(0, $curPostal[0].offsetTop - topPadding);
                            loading.hide();
                            return;
                        }
                        // End 210106 Add: FCCL-2442
    
                        // FCCL-2445: Check if error popup is displayed, focus in first input field and prevent event
                        if(functOtherContactErr().length > 0) {
                            preventFlag = true;
                        }
                        if(preventFlag) {
                            e.preventDefault();
                            loading.hide();
                            return;
                        }
    
                        var url = util.appendParamToURL($form.attr('action'), 'format', 'ajax');
                        var applyName = $form.find('.apply-button').attr('name');
                        var options = {
                            url: url,
                            data: $form.serialize() + '&' + applyName + '=x',
                            type: 'POST'
                        };
                        $.ajax(options).done(function (data) {
                            if (typeof(data) !== 'string') {
                                if (data.success) {
                                    // dialog.close();
                                    page.refresh();
                                } else if (data.error) {
                                    page.redirect(Urls.csrffailed);
                                } else {
                                    window.alert(data.message);
                                    page.refresh();
                                    loading.hide();
                                    return false;
                                }
                            } else {
                                // $('#dialog-container').html(data);
                                account.init();
                                // tooltip.init();
                            }
                        });
                    } else {
                        $('button.form_addsearch').trigger('click');
                        loading.hide();
                    }
                } catch (error) {
                    $('button.form_addsearch').trigger('click');
                    loading.hide();
                }
            })
            .fail(function() {
                $('button.form_addsearch').trigger('click');
                loading.hide();
            })
        })
        .on('click', '.cancel-button, .close-button', function (e) {
            e.preventDefault();
        })
    /*
    .on('click', '.delete-button', function (e) {
        e.preventDefault();
        if (window.confirm(String.format(Resources.CONFIRM_DELETE, Resources.ADDRESS_CONFIRM_DELETE))) {
            var url = util.appendParamsToUrl(Urls.deleteAddress, {
                AddressID: $form.find('#addressid').val(),
                format: 'ajax'
            });
            $.ajax({
                url: url,
                method: 'POST',
                dataType: 'json'
            }).done(function (data) {
                if (data.status.toLowerCase() === 'ok') {
                    dialog.close();
                    page.refresh();
                } else if (data.message.length > 0) {
                    window.alert(data.message);
                    return false;
                } else {
                    dialog.close();
                    page.refresh();
                }
            });
        }
    });
    */
    // Start 201127 Add FCCL-2147
    if ($('button[name$="_resetpassword_send"]').length > 0) {
        $('button[name$="_resetpassword_send"]').on('click', function(e) {
            var errStatus = page.showFormSubmissionErrors(e);
            if(errStatus && errStatus.isDefaultPrevented()) {
                e.preventDefault();
                return;
            }
        });
    }
    // End 201127 Add FCCL-2147

    // Start 201210 Add: FCCL-2331
    $('button.form_addsearch').on('click', function(e) {
        if (timeCount < maxTimeCount) {
            return;
        }
        timeCount = 0;
        var cPostal = $curPostal.val();
        if (cPostal.length != maxPostalLength) {
            postalErr.find('.err_tooltip_msg').text($('#postal_error_1').val());
            showPostalErr();
            e.preventDefault();
            timeCount = maxTimeCount;
            return;
        }

        if (cPostal == $oldPostalFtra.val()) {
            $curState.val($oldStateFtra.val());
            $curCity.val($oldCityFtra.val());
            $curAddress1.val($oldAddress1Ftra.val());

            $curState.trigger('change');
            $curCity.trigger('change');
            $curAddress1.trigger('change');

            postalErr.hide();
            stateErr.hide();

            e.preventDefault();
            timeCount = maxTimeCount;
            return;
        }

        var curPostal = $curPostal.val();
        var curState = $curState.val();
        var curCity = $curCity.val();
        var curAddress1 = $curAddress1.val();

        postalErr.hide();
        stateErr.hide();

        // Start 201218 Add: FCCL-2393
        $defaultStateOption.text($('select[name$="_state"] option:selected').text());
        $defaultStateOption.prop('label', $('select[name$="_state"] option:selected').text());
        // End 201218 Add: FCCL-2393
        $curState.val('');
        loading.show();  // 210120 Add: FCCL-2485
        timeCount = 0;
        var myInv = setInterval(function() {
            var val = $curState.val();
            if (val && val != '') {
                // Start 201218 Add: FCCL-2393
                $defaultStateOption.text(defaultStateText);
                $defaultStateOption.prop('label', defaultStateText);
                // End 201218 Add: FCCL-2393
                $oldPostalFtra.val($curPostal.val());
                $oldStateFtra.val($curState.val());
                $oldCityFtra.val($curCity.val());
                $oldAddress1Ftra.val($curAddress1.val());
                clearInterval(myInv);
                loading.hide();  // 210120 Add: FCCL-2485
                timeCount = maxTimeCount;
                return;
            }
            timeCount = timeCount + 1;
            if (timeCount >= maxTimeCount) {
                // Start 201218 Add: FCCL-2393
                $defaultStateOption.text(defaultStateText);
                $defaultStateOption.prop('label', defaultStateText);
                // End 201218 Add: FCCL-2393
                postalErr.find('.err_tooltip_msg').text($('#postal_error_1').val());
                showPostalErr();
                $curPostal.val(curPostal);
                $curState.val(curState);
                $curCity.val(curCity);
                $curAddress1.val(curAddress1);
                clearInterval(myInv);
                loading.hide();  // 210120 Add: FCCL-2485
            }
        }, intervalTime);
    });

    $curPostal.on('focusin', function() {
        checkPostal();
    });

    $curPostal.on('focusout', function() {
        checkPostal();
    });

    $curState.on('focusin', function() {
        checkState();
    });

    $curState.on('focusout', function() {
        checkState();
    });

    $curState.on('change', function() {
        checkState();
    });

    function checkPostal() {
        postalErr.hide();
        var old = $oldPostalFtra.val();
        if (old) {
            if (old != $curPostal.val() || $oldStateFtra.val() != $curState.val()) {
                postalErr.find('.err_tooltip_msg').text($('#postal_error_2').val());
                showPostalErr();
            }
        }
    }

    function checkState() {
        stateErr.hide();
        if ($oldPostalFtra.val()) {
            if ($oldStateFtra.val() != $curState.val()) {
                showStateErr();
            }
        }
    }

    function showPostalErr() {
        var searchBtn = $('input[name$="_postal"]')[0];
        postalErr.css('display', styleDisplayShow);
        // FCCL-3131 【F-88】スマホのときの、フォームバリデーションのメッセージ表示位置が見切れる
        postalErr.css('left', searchBtn.offsetLeft);
        postalErr.css('top', searchBtn.offsetTop - searchBtn.offsetHeight);
    }

    function showStateErr() {
        var stateDiv = $('.form_select-prefectures')[0];
        stateErr.css('display', styleDisplayShow);
        stateErr.css('left', stateDiv.offsetLeft + stateDiv.offsetWidth + leftPadding);
        stateErr.css('top', stateDiv.offsetTop + topPadding);
    }

    // Start 211101 Add: FCCL-2982
    function checkInputError(str) {
        if (str.length == 0) return false;
        var whiteList = Resources.CHAR_WHITE_LIST;
        for (var i=0; i < str.length; i++) {
            var c = str.charAt(i);
            if (whiteList.indexOf(c) < 0) {
                return true;
            }
        }
        return false;
    }
    function showCityErr() {
        var input = $('input[name$="_city"]')[0];
        var err = $('#div_err_city');
        if (checkInputError(input.value)) {
            err.css('display', styleDisplayShow);
            // FCCL-3131 【F-88】スマホのときの、フォームバリデーションのメッセージ表示位置が見切れる
            err.css('left', input.offsetLeft);
            err.css('top', input.offsetTop - input.offsetHeight);
        } else {
            err.hide();
        }
    }
    function showAddress1Err() {
        var input = $('input[name$="_address1"]')[0];
        var err = $('#div_err_address1');
        if (checkInputError(input.value)) {
            err.css('display', styleDisplayShow);
            // FCCL-3131 【F-88】スマホのときの、フォームバリデーションのメッセージ表示位置が見切れる
            err.css('left', input.offsetLeft);
            err.css('top', input.offsetTop - input.offsetHeight);
        } else {
            err.hide();
        }
    }
    $('input[name$="_city"]').on('input change focusout', function() {
        showCityErr();
    });
    $('input[name$="_address1"]').on('input change focusout', function() {
        showAddress1Err();
    });
    // End 211101 Add: FCCL-2982
    // End 201210 Add: FCCL-2331

    // disable validator
    // validator.init();

    // FCCL-3069 account
    $('input[name$="_lastname"]').on('input change focusout', function() {
        showLastnameErr();
    });
    function showLastnameErr() {
        var input = $('input[name$="_lastname"]')[0];
        var err = $('#div_err_lastname');
        if (checkInputError(input.value)) {
            err.css('display', styleDisplayShow);
            // FCCL-3131 【F-88】スマホのときの、フォームバリデーションのメッセージ表示位置が見切れる
            err.css('left', input.offsetLeft);
            err.css('top', input.offsetTop - input.offsetHeight);
        } else {
            err.hide();
        }
    }
    $('input[name$="_firstname"]').on('input change focusout', function() {
        showFirstnameErr();
    });
    function showFirstnameErr() {
        var input = $('input[name$="_firstname"]')[0];
        var err = $('#div_err_firstname');
        if (checkInputError(input.value)) {
            err.css('display', styleDisplayShow);
            // FCCL-3131 【F-88】スマホのときの、フォームバリデーションのメッセージ表示位置が見切れる
            err.css('left', input.offsetLeft);
            err.css('top', input.offsetTop - input.offsetHeight);
        } else {
            err.hide();
        }
    }
    $('input[name$="_companyName"]').on('input change focusout', function() {
        showCompanyNameErr();
    });
    function showCompanyNameErr() {
        var input = $('input[name$="_companyName"]')[0];
        var err = $('#div_err_companyName');
        if (checkInputError(input.value)) {
            err.css('display', styleDisplayShow);
            // FCCL-3131 【F-88】スマホのときの、フォームバリデーションのメッセージ表示位置が見切れる
            err.css('left', input.offsetLeft);
            err.css('top', input.offsetTop - input.offsetHeight);
        } else {
            err.hide();
        }
    }
    $('input[name$="_departmentName"]').on('input change focusout', function() {
        showDepartmentNameErr();
    });
    function showDepartmentNameErr() {
        var input = $('input[name$="_departmentName"]')[0];
        var err = $('#div_err_departmentName');
        if (checkInputError(input.value)) {
            err.css('display', styleDisplayShow);
            // FCCL-3131 【F-88】スマホのときの、フォームバリデーションのメッセージ表示位置が見切れる
            err.css('left', input.offsetLeft);
            err.css('top', input.offsetTop - input.offsetHeight);
        } else {
            err.hide();
        }
    }
}
// FCCL-3118 見積一覧（quotesearch）のURLの見積番号のパラメータをPOSTにする
$('.set-quote-detail').on('click', function() {
    $('#set-quote-detail input').val($(this).val());
    $('#set-quote-detail').submit();
});
/**
 * Initialize the edit account screen
 * @param {JQueryObject} contactInputs Input fields of other contact
 */
function initializeAccountForms(contactInputs) {
    if(!contactInputs) {
        contactInputs = $('input[name*="contact__phone"]');
    }
    // Set events for all contact inputs
    contactInputs.each(function() {
        $(this).off().on('focusout', function() {
            checkOtherContact(contactInputs);
        });
    });
}
/**
 * @private
 * @function
 * @description Toggles the list of Orders
 */
function toggleFullOrder () {
    $('.order-items')
        .find('li.hidden:eq(0)')
        .prev('li')
        .append('<a class="toggle">View All</a>')
        .children('.toggle')
        .click(function () {
            $(this).parent().siblings('li.hidden').show();
            $(this).remove();
        });
}
/**
 * @private
 * @function
 * @description Binds the events on the address form (edit, create, delete)
 */
function initAddressEvents() {
    var addresses = $('#addresses');
    if (addresses.length === 0) {
        // FCCL-2445: case T26 - edit account
        initializeAccountForms($('input[name*="customer_phoneMobile"]'));
        var $form = $('#edit-address-form');

        $form.find('input[name="format"]').remove();
        // tooltip.init();
        //$("<input/>").attr({type:"hidden", name:"format", value:"ajax"}).appendTo(form);

        var maxTimeCount = 30;  // 210120 Mod: FCCL-2485
        var intervalTime = 100;
        var timeCount = maxTimeCount;
        var $phone = $('input[name*="customer_phoneMobile"]');
        
        $form.off().on('click', '.apply-button', function (e) {
            var contactItemErr = functOtherContactErr();
            if (contactItemErr.length > 0) {
                $phone[0].focus();
                e.preventDefault();
                return;
            }
            var myInv = setInterval(function() {
                if (timeCount >= maxTimeCount) {
                    clearInterval(myInv);
                    checkOtherContact($phone);
                    if(contactItemErr.length > 0) {
                        $phone[0].focus();
                        e.preventDefault();
                        return;
                    }
                    loading.hide();
                }
            }, intervalTime);
        });
        return;
    }
    
    /*
    addresses.on('click', '.address-edit, .address-create', function (e) {
        e.preventDefault();
        dialog.open({
            url: this.href,
            options: {
                open: initializeAddressForm
            }
        });
    }).on('click', '.delete', function (e) {
        e.preventDefault();
        if (window.confirm(String.format(Resources.CONFIRM_DELETE, Resources.TITLE_ADDRESS))) {
            $.ajax({
                url: util.appendParamToURL($(this).attr('href'), 'format', 'ajax'),
                dataType: 'json'
            }).done(function (data) {
                if (data.status.toLowerCase() === 'ok') {
                    page.redirect(Urls.addressesList);
                } else if (data.message.length > 0) {
                    window.alert(data.message);
                } else {
                    page.refresh();
                }
            });
        }
    });
    */
    initializeAddressForm();
    // edit address
    addresses.off().on('click', '.jsAccordionBtn2', function (e) {
        e.preventDefault();
        var _this = this;
        // check is edit form 
        var addresId = addresses.find('.address-form #addressid');
        if (addresId.length > 0) {
            $.ajax({
                url: util.appendParamToURL(Urls.addAddress, 'format', 'ajax'),
                dataType: 'html'
            }).done(function (data) {
                addresses.find('.address-form').html(data);
                initializeAddressForm();
                // FCCL-2070: Add csrf for all forms
                page.getAndInsertCSRFToken(null);
            });
        }
        var _acdBtn = $(_this).closest('.jsAccordion');
        _acdBtn.toggleClass('isOpen').children('.jsAccordionBox').stop().slideToggle(200);
    }).on('click', '.form_addressList .address-edit', function (e) {
        e.preventDefault();
        loading.show();
        var _this = this;
        var _acdBtn = addresses.find('.jsAccordionBtn2').closest('.jsAccordion');
        if (!_acdBtn.hasClass('isOpen')) {
            _acdBtn.toggleClass('isOpen').children('.jsAccordionBox').stop().slideToggle(200);
        }
        
        $.ajax({
            url: util.appendParamToURL($(_this).attr('data-href'), 'format', 'ajax'),
            dataType: 'html'
        }).done(function (data) {
            loading.hide();
            addresses.find('.address-form').html(data);
            initializeAddressForm();
            // FCCL-2070: Add csrf for all forms
            page.getAndInsertCSRFToken(null);
        });
    }).on('click', '.form_addressList .address-delete', function (e) {
        e.preventDefault();
        var _this = this;
        modal.confirm(Resources.ADDRESS_CONFIRM_DELETE, '', function() {
            // console.log('callback yes');
            loading.show();
            $.ajax({
                url: util.appendParamToURL($(_this).attr('data-href'), 'format', 'ajax'),
                dataType: 'json'
            }).done(function (data) {
                if (data.status.toLowerCase() === 'ok') {
                    page.redirect(Urls.addressesList);
                } else if (data.message.length > 0) {
                    loading.hide();
                    window.alert(data.message);
                } else {
                    page.refresh();
                }
            });
        });
    });
}
/**
 * @private
 * @function
 * @description Binds the events of the payment methods list (delete card)
 */
function initPaymentEvents() {
    $('.add-card').on('click', function (e) {
        e.preventDefault();
        dialog.open({
            url: $(e.target).attr('href'),
            options: {
                open: initializePaymentForm
            }
        });
    });

    var paymentList = $('.payment-list');
    if (paymentList.length === 0) { return; }

    util.setDeleteConfirmation(paymentList, String.format(Resources.CONFIRM_DELETE, Resources.TITLE_CREDITCARD));

    $('form[name="payment-remove"]').on('submit', function (e) {
        e.preventDefault();
        // override form submission in order to prevent refresh issues
        var button = $(this).find('.delete');
        $('<input/>').attr({
            type: 'hidden',
            name: button.attr('name'),
            value: button.attr('value') || 'delete card'
        }).appendTo($(this));
        var data = $(this).serialize();
        $.ajax({
            type: 'POST',
            url: $(this).attr('action'),
            data: data
        })
            .done(function () {
                page.redirect(Urls.paymentsList);
            });
    });
}

function initializePaymentForm() {
    $('#CreditCardForm').on('click', '.cancel-button', function (e) {
        e.preventDefault();
        dialog.close();
    });

}
// FCCL-2529 : Bangly 01-02-2021 start add
/**
* @private
* @function
* @description Binds the events of the order, paging quote list
*/
function initQuoteEvents() {
    $('#main').on('click','div.quotePaging div.seriesLineup_pager a',function(e){
        loading.show();
        var container = $('#js-result-quote');
        var url = $(this).attr('url');
        if (url) {
            url = util.appendParamsToUrl(url, {
                'format': 'ajax'
            });
            $.ajax({
                url: url,
                success: function (response) {
                    container.empty().html(response);
                    window.scrollTo(0, document.getElementById("quote-list").offsetTop-70);
                    //FCCL-2646 : bangly 26-2-2021 start
                    page.getAndInsertCSRFToken(null);
                    productCart.init();
                    //FCCL-1029-Jira : bangly 22-3-2021 start
                    resizeListOrder();
                    //FCCL-1029-Jira : bangly 22-3-2021 end
                    //FCCL-2646 : bangly 26-2-2021 end
                },
                error:function(err){
                    console.log(err);
                    loading.hide();
                }
            });
        } else {
            loading.hide();
            window.scrollTo(0, document.getElementById("quote-list").offsetTop-70);
        }
    });
}
/**
* @private
* @function
* @description Binds the events of the order, paging order list
*/
function initOderEvents() {
    $('#main').on('click','div.orderPaging div.seriesLineup_pager a',function(e){
        loading.show();
        var container = $('#js-result-order');
        var url = $(this).attr('url');
        if (url) {
            url = util.appendParamsToUrl(url, {
                'format': 'ajax'
            });
            $.ajax({
                url: url,
                success: function (response) {
                    container.empty().html(response);
                    window.scrollTo(0, document.getElementById("js-result-order").offsetTop-70);
                    //FCCL-2646 : bangly 26-2-2021 start
                    reloadJSOrder();
                    //FCCL-2646 : bangly 26-2-2021 end
                },
                error:function(err){
                    console.log(err);
                    loading.hide();
                }
            });
        } else {
            loading.hide();
            window.scrollTo(0, document.getElementById("js-result-order").offsetTop-70);
        }
    });
}
//FCCL-2646 : bangly 26-2-2021 start
function reloadJSOrder() {
    jqueryCustom.init();
    var slider = {
        modal: null,
        gallery: null
    }
    var current = 0;
    var slickParam = {
        modal: {
            fade: true,
            infinite: true,
            cssEase: 'linear',
            autoplay: false,
            arrows: true,
            dots: true,
            customPaging: function(slick, index){
                var targetImage = slick.$slides.eq(index).find('img').attr('srcsmall');
                if(targetImage !== 'null') {
                    return '<img src=" ' + targetImage + ' "/>';
                } else {
                    return null;
                }
            }
        }
    }
    var createSlider = function (_target,current) {
        slickParam['modal'].initialSlide = current;
        slider['modal'] = _target['slick'](slickParam['modal']);
    }
    var setModal = function (){
        $('.js-inline-modal,.js-categoryMv_galleryItem')['magnificPopup']({
            type: 'inline',
            preloader: false,
            removalDelay: 900,
            fixedContentPos: true,
            fixedBgPos: true,
            callbacks: {
                beforeOpen: function(){
                },
                open: function(){
                    var _content = $('.mfp-content');
                    var _slider = _content.find('.js-categoryGalleryModal');
                    createSlider(_slider, current);
                    closeButton();
                },
                close: function(){
                    slider['modal'].slick('unslick');
                    slider['modal'] = null;
                }
            },
        });
    }
    var closeButton = function(){
        $('.js-inline-modal-close').on('click',function(){
            $('.js-inline-modal,.js-categoryMv_galleryItem').magnificPopup('close');
            if($('.categoryMv_galleryModal ul').hasClass('slick-slider')){
                $('.categoryMv_galleryModal ul').addClass('slick-slider js-categoryGalleryModal');
            }
        });
    }
    setModal();

    // radio billing
    $('input[name$="radio"]').each(function () {
        if ($(this).val() == '1') {
            var enable = $('input[name$="' + $(this).attr('text') + '_IsInvoicePrintable"]').val();
            if (enable == 'true') {
                $(this).attr('disabled',false);
            } else {
                $(this).attr('disabled',true);
            }
        } else {
            var enable = $('input[name$="' + $(this).attr('text') + '_IsReceiptPrintable"]').val();
            if (enable == 'true') {
                $(this).attr('disabled',false);
            } else {
                $(this).attr('disabled',true);
            }
        }
    });
    //validate T27
    function validate($control, $form){
        var error = false;
        var error_message = '';
        
        // Validate checkbox
        if ($form.find('input[name$="radio"]:checked').length == 0) {
            error = true;
        }

        // FCCL-3167  T27請求書・領収書フォームの「法人名」項目のバリデーションを条件付き必須にする
        var billing_company = $form.find('input[id$="companyname"]').val();
        var billing_firstName = $form.find('input[id$="firstName"]').val();
        var billing_lastName = $form.find('input[id$="lastName"]').val();

        if (billing_company == '' && billing_firstName == '' && billing_lastName == '') {
            alert("※法人名もしくはお名前のいずれかを入力してください");
        }
        
        // Validate input field
        $form.find('.validate-field').each(function(){
            var $field = $(this);
            var localError = false;
            var value = $field.val();
            var to_label = $field.parent().find('p');
            to_label.find('span').remove();
            // Start 210322 Mod: FCCL-2699
            // FCCL-3167  T27請求書・領収書フォームの「法人名」項目のバリデーションを条件付き必須にする
            if ($field.hasClass('valid_name') || $field.hasClass('valid_company')) {
                if (billing_company == '' && (billing_firstName == '' || billing_lastName == '')) {
                    error = true;
                    localError = true;
                } else if (checkLength(billing_company, 52) == false
                    || checkLength(billing_firstName, 25) == false
                    || checkLength(billing_lastName, 25) == false) {
                    error = true;
                    localError = true;
                }
                // End 210322 Mod: FCCL-2699
            }
            else if ($field.hasClass('valid_proviso') && $field.parent().css('display') !== 'none' && (( checkLength(value, 30) == false )||(value == '')) && $form.find("input.radio01").prop('checked')) { //FCCL-2672 : bangly 8/3/2021
                error = true;
                localError = true;
                error_message = notification_maxproviso;
                //FCCL-2672 : bangly 4-3-2021 start
            } else if ($field.hasClass('valid_proviso_field') && value == optionvalue1 && $form.find("input.radio01").prop('checked')) {
                error = true;
                localError = true;
            }
            //FCCL-2672 : bangly 4-3-2021 end
            
            if (localError && $control && $control.hasClass('validate-field')) {
                to_label.append('<span class="documentForm_note">'+ error_message +'</span>');
            }
        }); 
        
        if (error) {
            $form.find('.billing_submit').attr('disabled',true)
        } else {
            $form.find('.billing_submit').removeAttr('disabled');
        }
    };

    // Start 210402 Add: FCCL-2755
    function checkLength(value, length){
        var validmax = length;
        if (value.length > validmax){
            return false;
        }
        else{
            return true;
        }
    }
    // End 210402 Add: FCCL-2755

    // Init form
    $('.billingreceipt').each(function () {
        var $form = $(this);
        
        // Check input state to set submit button state
        if ($form.find("input.radio01").prop('checked') == false && $form.find("input.radio02").prop('checked') == false) {
            $form.find('.billing_submit').attr('disabled',true);
        }
        
        $form.find('.validate-field').on('keyup', function(){
            validate($(this), $form);
        });
        
        $form.find('input[name$="radio"]').change(function () {
            validate($(this), $form);
        });
        
        // select billing address
        $form.find('#billing_address .js-direct-select select').change(function(){
            var control = $(this);
            if(control.val() == orderer_information) {
                var billing_lname = $form.find( 'input[name$="' + $form.find('input.lastname').attr('text') + '_billing_lname"]').val();
                var billing_fname = $form.find( 'input[name$="' + $form.find('input.firstname').attr('text') + '_billing_fname"]').val();
                $form.find('input.lastname').val(billing_lname ? billing_lname : "" );
                $form.find('input.firstname').val(billing_fname ? billing_fname : "" );

                // FCCL-2801 法人名自動入力処理追加
                var billing_companyName = $form.find( 'input[name$="' + $form.find('input.companyname').attr('text') + '_billing_companyname"]').val();
                $form.find('input.companyname').val(billing_companyName ? billing_companyName : '' );

                validate($form.find('.valid_name'), $form);
            } else {
                var shipping_lname = $form.find( 'input[name$="' + $form.find('input.lastname').attr('text') + '_shipping_lname"]').val();
                var shipping_fname = $form.find( 'input[name$="' + $form.find('input.firstname').attr('text') + '_shipping_fname"]').val();
                $form.find('input.lastname').val(shipping_lname ? shipping_lname : "");
                $form.find('input.firstname').val(shipping_fname ? shipping_fname : "");

                // FCCL-2801 法人名自動入力処理追加
                var shipping_companyName = $form.find( 'input[name$="' + $form.find('input.companyname').attr('text') + '_shipping_companyname"]').val();
                $form.find('input.companyname').val(shipping_companyName ? shipping_companyName : '' );

                validate($form.find('.valid_name'), $form);
            }
        });
        
        // Init state for input on form initilization
        if($form.find('#billing-description .js-direct select').val() == optionvalue4) {
            $form.find('#billing-description .js-directBox').show();
        }
        
        //billing description
        var lastBillingDescription = 0;
        $form.find('#billing-description .js-direct select').change(function(){
            if($(this).val() == optionvalue4){
                $form.find('#billing-description .js-directBox').show();
            } else{
                $form.find('#billing-description .js-directBox').hide();
            }
            
            if ($(this).val() == optionvalue4) {
                validate($form.find('.valid_proviso'), $form);
            } else {
                validate(null, $form);
            }
            
            lastBillingDescription = $(this).val();
        });
        
        $form.submit(function(){
            var field = $(this).find('.validate-field');
            validate( field, $form );
        });
    });
    //FCCL-1029-Jira : bangly 22-3-2021 start
    resizeListOrder();
    //FCCL-1029-Jira : bangly 22-3-2021 end
    //FCCL-2646 : bangly 3-3-2021 start
    page.getAndInsertCSRFToken(null);
    //FCCL-2646 : bangly 3-3-2021 start
}
//FCCL-2646 : bangly 26-2-2021 end
// FCCL-2529 : Bangly 01-02-2021 start end
//FCCL-1029-Jira : bangly 22-3-2021 start
function resizeListOrder () {
    var j;
    if($(".js-heightLine-pT-group1").length) {
        for(j = 1; j <= 20; j++){
          $('.js-heightLine-pT-group' + j).heightLine();
        }
    } 
    // （マイページ）登録済所有製品一覧 mypageStatus_productListItem
    var m;
    if($('.js-heightLine-mSpLI-group1').length){
        for(m = 1; m <= 7; m++){
            $('.js-heightLine-mSpLI-group' + m).heightLine();
        }
    }
}
//FCCL-1029-Jira : bangly 22-3-2021 end
/**
 * @private
 * @function
 * @description Binds the events of the order, address and payment pages
 */
function initializeEvents() {
    toggleFullOrder();
    initAddressEvents();
    initPaymentEvents();
    // FCCL-2529 : Bangly 01-02-2021 start add
    initQuoteEvents();
    initOderEvents();
    // FCCL-2529 : Bangly 01-02-2021 end add
    $(document.body).on('click', 'button#search-quote', function () {
        loading.show();
        var qid = $('input#quoteid').val();
        var url = window.location.href;
        url = util.appendParamsToUrl(url, {
            'qid': qid,
            'format': 'ajax'
        });
        $.ajax({
            url: url,
            success: function (response) {
                $('div.mypageStatus_productContents').empty().html(response);
                if ($('#quote-list').length > 0) {
                    // FCCL-2070: Add csrf for all forms at quote list after rendering container template
                    page.getAndInsertCSRFToken(null);
                    // click add-list-product-to-cart button
                    $('.add-list-product-to-cart').on('click', productCart.addListToCart);
                }
                loading.hide();
            },
            error: function (err) {
                loading.hide();
            }
        });
    });
    login.init();
}

var account = {
    init: function () {
        initializeEvents();
        giftcert.init();
        productCart.init();
        contactus.init();
        findorder.init();
    },
    initCartLogin: function () {
        login.init();
    }
};

module.exports = account;

