'use strict';

var compareWidget = require('../compare-widget'),
    productTile = require('../product-tile'),
    progress = require('../progress'),
    util = require('../util'),
    loading = require('../loading'),
    config = require('../../scripts/config.json'),
    modal = require('../modal'),
    Promise = require('promise'),
    page = require('../page');

var delayTime = 200; // 210115 Add: FCCL-2449
var resizeFlg = false;  // 210603 Add: FCCL-2876

function infiniteScroll() {
    // getting the hidden div, which is the placeholder for the next page
    var loadingPlaceHolder = $('.infinite-scroll-placeholder[data-loading-state="unloaded"]');
    // get url hidden in DOM
    var gridUrl = loadingPlaceHolder.attr('data-grid-url');

    if (loadingPlaceHolder.length === 1 && util.elementInViewport(loadingPlaceHolder.get(0), 250)) {
        // switch state to 'loading'
        // - switches state, so the above selector is only matching once
        // - shows loading indicator
        loadingPlaceHolder.attr('data-loading-state', 'loading');
        loadingPlaceHolder.addClass('infinite-scroll-loading');


        // named wrapper function, which can either be called, if cache is hit, or ajax repsonse is received
        var fillEndlessScrollChunk = function (html) {
            loadingPlaceHolder.removeClass('infinite-scroll-loading');
            loadingPlaceHolder.attr('data-loading-state', 'loaded');
            $('div.search-result-content').append(html);
        };

        // old condition for caching was `'sessionStorage' in window && sessionStorage["scroll-cache_" + gridUrl]`
        // it was removed to temporarily address RAP-2649

        $.ajax({
            type: 'GET',
            dataType: 'html',
            url: gridUrl,
            success: function (response) {
                // put response into cache
                try {
                    sessionStorage['scroll-cache_' + gridUrl] = response;
                } catch (e) {
                    // nothing to catch in case of out of memory of session storage
                    // it will fall back to load via ajax
                }
                // update UI
                fillEndlessScrollChunk(response);
                productTile.init();
            }
        });
    }
}
/**
 * @private
 * @function
 * @description replaces breadcrumbs, lefthand nav and product listing with ajax and puts a loading indicator over the product listing
 */
function updateProductListing(url) {
    if (!url || url === window.location.href) {
        return;
    }
    progress.show($('.search-result-content'));
    $('#main').load(util.appendParamToURL(url, 'format', 'ajax'), function () {
        compareWidget.init();
        productTile.init();
        progress.hide();
        history.pushState(undefined, '', url);
    });
}
function updateSearchUrl (){
    if($('#search-url') && (window.location.href.indexOf('sparentcats') > -1 || window.location.href.indexOf('scats') > -1 || window.location.href.indexOf('sattributes') > -1 || window.location.href.indexOf('sprices') > -1)){
        $('#search-url').html(window.location.href);
    }
}

/**
 * @function close popup when finish action
 * @description close popup when finish action
 * */
var closePopup = function (){
    $('.js-inline-modal-close').click(function () {
        $.magnificPopup.close();
    });
};
var _cateAnchor = $('.categoryAnchor');
var _active = 'isActive';
var _open = 'isOpen';
var _close = 'isClose';
var _fixed = 'isFixed';

/**
 * @private
 * @function
 * @description Initializes events for the following elements:<br/>
 * <p>refinement blocks</p>
 * <p>updating grid: refinements, pagination, breadcrumb</p>
 * <p>item click</p>
 * <p>sorting changes</p>
 */
function initializeEvents() {
    var $main = $('#main');
//    var _open = 'isOpen';
//    // compare checked
//    $main.on('click', 'input[type="checkbox"].compare-check', function () {
//        var cb = $(this);
//        var tile = cb.closest('.product-tile');
//
//        var func = this.checked ? compareWidget.addProduct : compareWidget.removeProduct;
//        var itemImg = tile.find('.product-image a img').first();
//        func({
//            itemid: tile.data('itemid'),
//            uuid: tile[0].id,
//            img: itemImg,
//            cb: cb
//        });
//
//    });
//
//    // handle toggle refinement blocks
//    $main.on('click', '.refinement h3', function () {
//        $(this).toggleClass('expanded')
//        .siblings('ul').toggle();
//    });
//
//    // handle events for updating grid
//    $main.on('click', '.refinements a, .pagination a, .breadcrumb-refinement-value a', function (e) {
//        // don't intercept for category and folder refinements, as well as unselectable
//        if ($(this).parents('.category-refinement').length > 0 || $(this).parents('.folder-refinement').length > 0 || $(this).parent().hasClass('unselectable')) {
//            return;
//        }
//        e.preventDefault();
//        updateProductListing(this.href);
//    });
//
//    // handle events item click. append params.
//    $main.on('click', '.product-tile a:not("#quickviewbutton")', function () {
//        var a = $(this);
//        // get current page refinement values
//        var wl = window.location;
//
//        var qsParams = (wl.search.length > 1) ? util.getQueryStringParams(wl.search.substr(1)) : {};
//        var hashParams = (wl.hash.length > 1) ? util.getQueryStringParams(wl.hash.substr(1)) : {};
//
//        // merge hash params with querystring params
//        var params = $.extend(hashParams, qsParams);
//        if (!params.start) {
//            params.start = 0;
//        }
//        // get the index of the selected item and save as start parameter
//        var tile = a.closest('.product-tile');
//        var idx = tile.data('idx') ? + tile.data('idx') : 0;
//
//        // convert params.start to integer and add index
//        params.start = (+params.start) + (idx + 1);
//        // set the hash and allow normal action to continue
//        a[0].hash = $.param(params);
//    });
//
//    // handle sorting change
//    $main.on('change', '.sort-by select', function (e) {
//        e.preventDefault();
//        updateProductListing($(this).find('option:selected').val());
//    })
//    .on('change', '.items-per-page select', function () {
//        var refineUrl = $(this).find('option:selected').val();
//        if (refineUrl === 'INFINITE_SCROLL') {
//            $('html').addClass('infinite-scroll').removeClass('disable-infinite-scroll');
//        } else {
//            $('html').addClass('disable-infinite-scroll').removeClass('infinite-scroll');
//            updateProductListing(refineUrl);
//        }
//    });

    /* Start T05 at 2020/05/09 */
    $main.on('click', '#js-pe-submit-keyword',  function (e) {
        e.preventDefault();

        var container = $('#js-pe-result-wrap');
        var url;
        url= $('#search-url').text();

        var searchKeyword = $('#js-pe-search-keyword').val();
        var filterKeyword = $('#js-model-filter-text').val();
        url = util.removeParamsFromURL(url, ['q','model_num', 'sz', 'start']);
        if (searchKeyword !== '') {
            url = util.appendParamToURL(url, 'q', searchKeyword);
        }
        if (filterKeyword !== '') {
            url = util.appendParamToURL(url, 'model_num', filterKeyword);
        }
        url = util.appendParamToURL(url, 'format', 'ajax');

        loading.show();
        $.ajax({
            url: url,
            success: function (response) {
                container.empty().html(response);
                setProductLineItem();
                //FCCL-2502 : bangly 20/01/2021 start
                resizePicture();
                //FCCL-2502 : bangly 20/01/2021 end
                loading.hide();
            },
            error:function(err){
                loading.hide();
            }
        });
    });

    $main.on('click', '#js-model-filter-submit', function (e) {
        e.preventDefault();
        var container = $('#js-pe-result-wrap');
        var url = $('#search-url').text();
        var searchKeyword = $('#js-pe-search-keyword').val();
        var filterKeyword = $('#js-model-filter-text').val();

        url = util.removeParamsFromURL(url, ['q', 'model_num', 'sz', 'start' ]);
        if (searchKeyword !== '') {
            url = util.appendParamToURL(url, 'q', searchKeyword);
        }
        if (filterKeyword !== '') {
            url = util.appendParamToURL(url, 'model_num', filterKeyword);
        }     
        url = util.appendParamToURL(url, 'format', 'ajax');
        loading.show();
        $.ajax({
            url: url,
            success: function (response) {
                container.empty().html(response);
                setProductLineItem();
                //FCCL-2502 : bangly 20/01/2021 start
                resizePicture();
                //FCCL-2502 : bangly 20/01/2021 end
                loading.hide();
            },
            error:function(err){
                loading.hide();
            }
        });

    });

    $main.on('click', '#js-model-filter-cancel', function (e) {
        e.preventDefault();
        var container = $('#js-pe-result-wrap');
        var url = $('#search-url').text();
        url = util.removeParamsFromURL(url, [ 'model_num', 'ownedmachine', 'sz', 'start' ]);
        var searchKeyword = $('#js-pe-search-keyword').val();
        if (searchKeyword !== '') {
            url = util.appendParamToURL(url, 'q', searchKeyword);
        }
        url = util.appendParamToURL(url, 'format', 'ajax');
        loading.show();
        $.ajax({
            url: url,
            success: function (response) {
                container.empty().html(response);
                setProductLineItem();
                //FCCL-2502 : bangly 20/01/2021 start
                resizePicture();
                //FCCL-2502 : bangly 20/01/2021 end
                loading.hide();
            },
            error:function(err){
                loading.hide();
            }
        });
    });
    $main.on('click', '.productList_button-favorite',function (e){
        var url = util.ajaxUrl(Urls.addWishlist);
        var data = {
            productId: $(this).attr('data-pid') ,
            screenCgid: $(this).attr('data-screen'),
            isCategory: 1 
        }
        return Promise.resolve($.ajax({
            type: 'POST',
            url: util.ajaxUrl(Urls.addWishlist),
            data: data
        })).then(function (response) {
            // handle error in the response
            
            // var popupId = '#favourites_popup_success';
             if (response.error == 'login') {
                page.redirect( util.appendParamsToUrl(Urls.login, {
                    scope: 'wishlist'
                }));
             }
             else {
             	if (response.error == config.wishlist.max_items) {
//             		popupId = '#favourites_popup_confirm';
                     modal.confirm(Resources.FAVORITE_UPDATE_TITILE, '', function() {
                       var url = util.ajaxUrl(Urls.updateFirstWishList);
                       return Promise.resolve($.ajax({
                           type: 'POST',
                           url: util.ajaxUrl(Urls.updateFirstWishList),
                           data: data
                       })).then(function (response) {
                           // handle error in the response
                           if (response.error == 'login') {
                                page.redirect(Urls.login);
                           }
                           else{
                               $.magnificPopup.close();
                           }
                       });
                    });
             	} else {
             		 modal.addWishlist(Resources.FAVORITE_ADD_SUCCESS, Resources.BUTTON_FAVORITE, function() {
                       //todo
             			page.redirect(Urls.showWishList);
                    });
                }
             }
        });
    });
   

    /*Add refine search*/
    $main.on('click', '.js-pe-product-check', function (e) {
        var container = $('#js-pe-result-wrap');
        var url = $('#search-url').text();
        var searchKeyword = $('#js-pe-search-keyword').val();
        var filterKeyword = $(this).attr('value') ? $(this).attr('value') : null;

        url = util.removeParamsFromURL(url, [ 'model_num', 'sz', 'start' ]);
        if (searchKeyword !== '') {
            url = util.appendParamToURL(url, 'q', searchKeyword);
        }
        if (filterKeyword !== '') {
            url = util.appendParamToURL(url, 'model_num', filterKeyword);
        }
        url = util.appendParamToURL(url, 'format', 'ajax');
        loading.show();
        $.ajax({
            url: url,
            success: function (response) {
                container.empty().html(response);
                setProductLineItem();
                loading.hide();
            },
            error:function(err){
                loading.hide();
            }
        });

    });
    $main.on('click', '#js-ownedmachine-clear', function (e) {
        e.preventDefault();
        var container = $('#js-pe-result-wrap');
        var url = $('#search-url').text();
        url = util.removeParamsFromURL(url, [ 'model_num', 'ownedmachine', 'sz', 'start' ]);
        var searchKeyword = $('#js-pe-search-keyword').val();
        if (searchKeyword !== '') {
            url = util.appendParamToURL(url, 'q', searchKeyword);
        }
        url = util.appendParamToURL(url, 'format', 'ajax');
        loading.show();
        $.ajax({
            url: url,
            success: function (response) {
                container.empty().html(response);
                setProductLineItem()
                //FCCL-2502 : bangly 20/01/2021 start
                resizePicture();
                //FCCL-2502 : bangly 20/01/2021 end
                loading.hide();
            },
            error:function(err){
                loading.hide();
            }
        });
    });
    // add filter by sub category
    $main.on('click', '.js-btn-scat-filter', function (e) {
        e.preventDefault();
        var container = $('#js-pe-result-wrap');
        var url = $('#search-url').text();
        var scat = $(this).attr('scat') ? $(this).attr('scat') : '';
        url = util.removeParamsFromURL(url, [ 'scgid', 'sz', 'start' ]);
        if (scat !== '') {
            url = util.appendParamToURL(url, 'scgid', scat);
        }
        url = util.appendParamToURL(url, 'format', 'ajax');
        loading.show();
        $.ajax({
            url: url,
            success: function (response) {
                container.empty().html(response);
                setProductLineItem();
                loading.hide();
            },
            error:function(err){
                loading.hide();
            }
        });
    });

    $main.on('click', '#js-select-all',  function (e) {
        e.preventDefault();

        var container = $('#js-pe-result-wrap');
        var url;
        url= $('#search-url').text();
        url = util.removeParamsFromURL(url, [ 'scgid', 'q', 'model_num', 'sz', 'start' ]);
        url = util.appendParamToURL(url, 'format', 'ajax');
        url = util.appendParamToURL(url, 'check', 'true');
        loading.show();
        $.ajax({
            url: url,
            success: function (response) {
                container.empty().html(response);
                setProductLineItem();
                loading.hide();
            },
            error:function(err){
                console.log(err);
                loading.hide();
            }
        });
    });
    //FCCL-2193 T05 pagination
    $main.on('click','div.pagingbar div.seriesLineup_pager a',function(e){
        loading.show();
        var container = $('#js-pe-result-wrap');
        var url = $(this).attr('url');
        if (url) {
            url = util.appendParamsToUrl(url, {
                'format': 'ajax'
            });
            $.ajax({
                url: url,
                success: function (response) {
                    container.empty().html(response);
                    setProductLineItem();
                    loading.hide();
                    window.scrollTo(0, $('h2.productList_heading')[0].offsetTop); //FCCL-2257 scroll to h2 heading
                },
                error:function(err){
                    console.log(err);
                    loading.hide();
                }
            });
        } else {
            loading.hide();
            window.scrollTo(0, $('h2.productList_heading')[0].offsetTop);
        }
    });

    // Init event after ajax complete
    $( document ).ajaxComplete(function() {
        //FCCL-2819 : 13-5-2021 bangly start
        //Check flag
        if ($('#searchSuggestFlag').length > 0 && $('#searchSuggestFlag').val() == 1) {
            return;
        }
        //FCCL-2819 : 13-5-2021 bangly end
        // refineSlider
        reloadRefineSliderAjax();
    });
    /* END T05 at 2020/05/20 */

    /** START T04 at 2020/05/07 **/
    var mode = $('input#contentMode').attr('value');
    if(mode=="") {
        mode=$('input#contentMode').attr('default');
    }
    $('li#' + mode).trigger('click');
    //product list refinement search
    $('div.seriesLineup_row').on('click','div#refinementBar div.seriesLineup_refineUnit input[type=checkbox]',function(e){
        loading.show();
        var url = $(this).attr('url');
        var rsVal = url.substr(url.indexOf("?") + 1);
        $('input#refinement_url').attr('url', url);
        $('input#refinement_url').attr('rsVal', rsVal);
        url = util.appendParamsToUrl(url, {
            'format': 'ajax',
            'categoryLevel' : $(this).val(),
            'listCheck' :  $('input.trackingClick').val(),
            'listCheckMain' : $('input.trackingClickMainTab').val()
        });
        $.ajax({
            url: url,
            success: function (response) {
                $('div.seriesLineup_row').empty().html(response);
                setProductLineItem();
                reloadTabSpecifications();
                // FCCL-2415 : bangly 30/12/2020 - Add Start
                hideTabTitle();
                // FCCL-2415 : bangly 30/12/2020 - Add End
                loading.hide();
                window.scrollTo(0, document.getElementById("anc04").offsetTop-70);  // 201116 FCCL-2191: Go to top of product list
            },
            error:function(err){
                loading.hide();
            }
        });
    });
    //series sorting option
    $( 'select#sortingOptions' ).change(function() {
        loading.show();
        var selectedOption = $(this).find('option:selected');
        var url = selectedOption.attr('value');
        var rsVal = $('input#refinement_url').attr('rsVal');
        var keyword = $('input#refinement_url').attr('keyword');
        if(rsVal!=""){
            url = url + "&" + rsVal;
        }
        if(keyword!=""){
            url = url + "&" + keyword;
        }
        url = util.appendParamsToUrl(url, {
            'format': 'ajax'
        });
        $.ajax({
            url: url,
            success: function (response) {
                $('div.seriesLineup_row').empty().html(response);
                setProductLineItem();
                // FCCL-2415 : bangly 30/12/2020 - Add Start
                hideTabTitle();
                // FCCL-2415 : bangly 30/12/2020 - Add End
                loading.hide();
            },
            error:function(err){
                loading.hide();
            }
        });
    });
    //series pagination
    $('div.seriesLineup_row').on('click','div.seriesLineup_pager a',function(e){
        loading.show();
        var url = $(this).attr('url');
        if (url) {
            url = util.appendParamsToUrl(url, {
                'format': 'ajax'
            });
            $.ajax({
                url: url,
                success: function (response) {
                    $('div.seriesLineup_row').empty().html(response);
                    setProductLineItem();
                    loading.hide();
                    window.scrollTo(0, document.getElementById("anc04").offsetTop-70);  // 201116 FCCL-2191: Go to top of product list
                },
                error:function(err){
                    loading.hide();
                }
            });
        } else {
            loading.hide();
            window.scrollTo(0, document.getElementById("anc04").offsetTop-70);
        }
    });
    //search by key word
    $('div.seriesLineup_row').on('click','#series-submit-keyword',function(e){
        loading.show();
        var searchKeyword = ($('#series-search-keyword').val() === '文字列を入力') ? '' : $('#series-search-keyword').val();
        $('input#refinement_url').attr('keyword',"q=" + searchKeyword);
        var url = $('input#refinement_url').attr('url');
        if(url!=""){
            url = util.removeParamFromURL(url, 'q');
            url = util.appendParamToURL(url, 'q', searchKeyword);
        }else{
            url = $('input#refinement_url').attr('urlForCategory');
            url = util.appendParamToURL(url, 'q', searchKeyword);
        }
        url = util.appendParamsToUrl(url, {
            'format': 'ajax',
            'listCheck' :  $('input.trackingClick').val(),
            'listCheckMain' : $('input.trackingClickMainTab').val()
        });
        $.ajax({
            url: url,
            success: function (response) {
                $('div.seriesLineup_row').empty().html(response);
                setProductLineItem();
                reloadTabSpecifications();
                // FCCL-2415 : bangly 30/12/2020 - Add Start
                hideTabTitle();
                // FCCL-2415 : bangly 30/12/2020 - Add End
                loading.hide();
            },
            error:function(err){
                loading.hide();
            }
        });
    });
    //reload js for button change mode list view and tab view
    $('li.seriesLineup_buttonItem').on('click',function(){
        var id=$(this).attr('id');
        $('input#contentMode').attr('value', id);
        // Start 210115 Add: FCCL-2449
        if (mode == 'listMode' && !resizeFlg) {  // 210603 Mod: FCCL-2876
            setTimeout(resizeProductSP, delayTime);
        }
        resizeFlg = false;  // 210603 Mod: FCCL-2876
        // End 210115 Add: FCCL-2449
    });
    //reload js for AccordionBtn
    $('div.seriesLineup_row').on('click','div#refinementBar div.seriesLineup_refineUnit p.jsAccordionBtn',function(e){
        $(this).closest('.jsAccordion').toggleClass(_open).children('.jsAccordionBox').stop().slideToggle(200);
        var a = [];
        var b = [];
        // Track list 
        $('div.seriesLineup_row div#refinementBar > div.seriesLineup_refineUnit > p.jsAccordionBtn:first-of-type').each (function(){
            if(!$(this).parent().hasClass('isOpen')){
                b.push(this.textContent);
            }
        });

        $('div.specifications div.seriesLineup_refineUnit p.seriesLineup_refineUnitTitle-button').each (function(){
            if($(this).parent().hasClass('isOpen')){
                a.push(this.textContent);
            }
        });
        // add track list to input
        $('input.trackingClick').val(encodeURI(a.join('|')));
        $('input.trackingClickMainTab').val(encodeURI(b.join('|')));
		return false;
    });
    //button refresh refinement
    $('div.seriesLineup_row').on('click','.seriesLineup_refineBoxTitleButton',function(e){
        loading.show();
        // FCCL-2415 : bangly 30/12/2020 - Add Start
        $('input#refinement_url').attr('url', '');
        $('input#refinement_url').attr('keyword', '');
        $('input#refinement_url').attr('rsVal', '');
        // FCCL-2415 : bangly 30/12/2020 - Add End
        var url = $('input#refinement_url').attr('urlForCategory');
        url = util.appendParamsToUrl(url, {
            'format': 'ajax'
        });
        $.ajax({
            url: url,
            success: function (response) {
                $('div.seriesLineup_row').empty().html(response);
                setProductLineItem();
                // FCCL-2415 : bangly 30/12/2020 - Add Start       
                hideTabTitle();
                // FCCL-2415 : bangly 30/12/2020 - Add End
                loading.hide();
            },
            error:function(err){
                loading.hide();
            }
        });
    });

    // Init event after ajax complete
    $( document ).ajaxComplete(function() {
        //FCCL-2819 : 13-5-2021 bangly start
        //Check flag
        if ($('#searchSuggestFlag').length > 0 && $('#searchSuggestFlag').val() == 1) {
            return;
        }
        //FCCL-2819 : 13-5-2021 bangly end
        // Click on changeMode button
        var mode = $('input#contentMode').attr('value');
        if(mode=="") {
            mode=$('input#contentMode').attr('default');
        }
        $('li#' + mode).trigger('click');

        // init slick control
        if( getBranch() == 'SP' ){
            $('.js-forSP-slider').not('.slick-initialized').slick({
                swipeToSlide: true,
                slidesToScroll: 1,
                slidesToShow: 1,
                autoplay: false,
                arrows: true,
                infinite: false,
                variableWidth: true
            });
        }
        if( getBranch() == 'PC' ){
            $('.js-forSP-slider.slick-initialized').slick('unslick');
        }
    });

    /** END T04 at 2020/05/13 **/
    /** START T25 at 2020/05/18 **/
    var modeSearch = $('input#contentSearchMode').attr('value');
    if (modeSearch == "") {
        modeSearch = $('input#contentSearchMode').attr('default');
    }
    $('li#' + modeSearch).trigger('click');
    //product list refinement search
    $('div.searchResult_row').on('click', 'div.searchResult_refineScroll div.searchResult_refineUnit input[type=checkbox]', function (e) {
        loading.show();
        var url = $(this).attr('url');
        var rsVal = url.substr(url.indexOf("?") + 1);
        $('input#refinementSearch_url').attr('url', url);
        $('input#refinementSearch_url').attr('rsVal', rsVal);
        url = util.appendParamsToUrl(url, {
            'format': 'ajax',
            'categoryLevel' : $(this).val(),
            'listCheck' :  $('input.trackingClick').val(),
            'listCheckMain' : $('input.trackingClickMainTab').val()
        });
        $.ajax({
            url: url,
            success: function (response) {
                $('div.searchResult_row').empty().html(response);
                setProductLineItem();
				reloadTabSpecificationsMain();
                loading.hide();
            },
            error: function (err) {
                loading.hide();
            }
        });
    });
    //button refresh refinement
    $('div.searchResult_row').on('click','.searchResult_refineBoxTitleButton',function(e){
        loading.show();
        var url = $('input#refinementSearch_url').attr('urlForCategory');
        console.log(url);
        url = util.appendParamsToUrl(url, {
            'format': 'ajax'
        });
        $.ajax({
            url: url,
            success: function (response) {
                $('div.searchResult_row').empty().html(response);
                setProductLineItem();
                loading.hide();
            },
            error:function(err){
                loading.hide();
            }
        });
    });
    
    //reload js for AccordionBtn
    $('div.searchResult_row').on('click','div#searchRefineBar div.searchResult_refineUnit p.jsAccordionBtn',function(e){
        $(this).closest('.jsAccordion').toggleClass(_open).children('.jsAccordionBox').stop().slideToggle(200);
        var a = [];
        var b = [];
        // Track list 
        $('div.searchResult_row div#searchRefineBar > div.searchResult_refineUnit > p.jsAccordionBtn:first-of-type').each (function(){
            if(!$(this).parent().hasClass('isOpen')){
                b.push(this.textContent);
            }
        });
        $('div.specifications div.seriesLineup_refineUnit p.seriesLineup_refineUnitTitle-button').each (function(){
            if($(this).parent().hasClass('isOpen')){
                a.push(this.textContent);
            }
        });
        // add track list to input
        $('input.trackingClick').val(encodeURI(a.join('|')));
        $('input.trackingClickMainTab').val(encodeURI(b.join('|')));
        return false;
    });
    $( document ).ajaxComplete(function() {
        //FCCL-2819 : 13-5-2021 bangly start
        // Check Flag
        if ($('#searchSuggestFlag').length > 0 && $('#searchSuggestFlag').val() == 1) {
            return;
        }
        //FCCL-2819 : 13-5-2021 bangly end
        reloadSearchMode();
        cateAnc();
    })
    /** END T25 at 2020/05/19 **/

    /** START T31 at 2020/05/20 **/
    // add filter by owned machine
    $main.on('click', '.js-radio-ownedmachine-filter', function (e) {
        loading.show();
        var currentUrl = Urls.seriesService;
        var warrantyType = $(this).attr('warranty-type') || '';
        var warrantyTime = $(this).attr('warranty-time') || '';
        var modelNumber = $(this).parent().parent().parent('.searchRefine_item').attr('data-mywebtypemodel') || '';
        // FCCL-2116 add ownedMachine info
        var ownedMachineWebtypeNumber = $('input:radio[name="checkRefine"]:checked').parent().parent().parent('.searchRefine_item').attr('data-mywebtypemodel') || '';
        var ownedMachineSerialNumber = $('input:radio[name="checkRefine"]:checked').parent().parent().parent('.searchRefine_item').attr('data-myserialnumber') || '';
        //FCCL-2643: bangly 24/2/2021 start
        var catagory  = $('input.value_catagory').attr('cgid');
        //FCCL-2643: bangly 24/2/2021 end
        if (warrantyType || warrantyTime || modelNumber) {
            currentUrl = util.appendParamsToUrl(currentUrl, {
                'cgid': catagory,
                'start': warrantyTime,
                'type': warrantyType,
                'modelNumber': modelNumber,
                'ownedMachineWebtypeNumber': ownedMachineWebtypeNumber,
                'ownedMachineSerialNumber': ownedMachineSerialNumber
            });
        }
        history.pushState(undefined, '', currentUrl);
        window.location.href = currentUrl;
    });

    //cancel filter by owned machine
    $main.on('click', '.js-btn-cancel-filter', function (e) {
        // loading.show();
        // var $buttonFilterCategory = $('.serviceCategoryList_typeItem a');

        // $buttonFilterCategory.addClass('js-btn-cgid-filter');

        // var currentUrl = Urls.seriesService;
        // history.pushState(undefined, '', currentUrl);
        // // currentUrl = util.appendParamToURL(currentUrl, 'format', 'ajax');
        //
        // $.ajax({
        //     url: currentUrl,
        //     success: function (response) {
        //         $('#result-service-series').empty().html(response);
        //         loading.hide();
        //         // activeButtonFilter(config.cgid.SERVICE);
        //     },
        //     error:function(err){
        //         console.log(err);
        //         loading.hide();
        //     }
        // });
        loading.show();
        //FCCL-2643: bangly 24/2/2021 start
        window.location.href =  $('input.value_catagory').attr('href');
        //FCCL-2643: bangly 24/2/2021 end
    });

    //active button filter
    function activeButtonFilter(warrantyId) {
        var $buttonFilterCategory = $('.serviceCategoryList_typeItem a');
        $buttonFilterCategory.removeClass('isActive');
        $buttonFilterCategory.each(function (index) {
            if ($buttonFilterCategory[index].attributes.cgid.value === warrantyId) {
                $buttonFilterCategory[index].classList.add('isActive');
            }
        })
    }
    $('div.seriesLineup_row').on('click mouseenter', '.js-colorList li',function() {
        if(util.isMobile()) {
            var hoverIndex = $(this).parent('ul').children('li').index(this);
            var targetWarpSPList = $(this).closest('.seriesLineupListSP_imageBoxWrap');
            var targetWarpSPTile = $(this).closest('.seriesLineupTileSP_imageBoxWrap');

            var targetImgBoxSPList = targetWarpSPList.find('.js-imageBox');
            var targetImgBoxSPTile = targetWarpSPTile.find('.js-imageBox');

            targetImgBoxSPList.find('img').addClass('dn');
            targetImgBoxSPList.find('img').eq(hoverIndex).removeClass('dn');

            targetImgBoxSPTile.find('img').addClass('dn');
            targetImgBoxSPTile.find('img').eq(hoverIndex).removeClass('dn');
        }
    })

    // add filter by category id
    // $main.on('click', '.js-btn-cgid-filter', function (e) {
    //     loading.show();
    //     var currentUrl = Urls.seriesService;
    //     var categoryId = $(this).attr('cgid') || '';
    //     currentUrl = util.removeParamsFromURL(currentUrl, [ 'cgid', 'sz', 'start' ]);
    //     if (categoryId) {
    //         currentUrl = util.appendParamToURL(currentUrl, 'cgid', categoryId);
    //     }
    //     history.pushState(undefined, '', currentUrl);
    //     currentUrl = util.appendParamToURL(currentUrl, 'format', 'ajax');
    //     $.ajax({
    //         url: currentUrl,
    //         success: function (response) {
    //             $('#result-service-series').empty().html(response);
    //             loading.hide();
    //             activeButtonFilter(categoryId);
    //         },
    //         error:function(err){
    //             console.log(err);
    //             loading.hide();
    //         }
    //     });
    // });
    /** END T31 at 2020/05/28**/
}
// get data tracklist from sever-side - BigTag
function reloadTabSpecificationsMain(){
    var checkList = $('input.trackingClick').val();
    if(checkList != ''){
        var listSpecifications = decodeURI(checkList).split('|');
        $('div.specifications div.seriesLineup_refineUnit p.seriesLineup_refineUnitTitle-button').each (function(){
            if(listSpecifications.indexOf(this.textContent) != -1){
                if(!$(this).parent().hasClass(_open)){
                    $(this).parent().toggleClass(_open).children('.jsAccordionBox').stop().slideToggle(200);
                }
            }
        });
    }
    var checkListMain = $('input.trackingClickMainTab').val();
    if(checkListMain != ''){
        var listMainSpecifications = decodeURI(checkListMain).split('|');
        $('div.searchResult_row div#searchRefineBar > div.searchResult_refineUnit > p.jsAccordionBtn:first-of-type').each (function(){
            if(listMainSpecifications.indexOf(this.textContent) != -1){
                if($(this).parent().hasClass(_open)){
                    $(this).parent().toggleClass(_open).children('.jsAccordionBox').stop().slideToggle(200);
                }
            }
        });
    }
}
// get data tracklist from sever-side - SmallTag
function reloadTabSpecifications(){
    var checkList = $('input.trackingClick').val();
    if(checkList != ''){
        var listSpecifications = decodeURI(checkList).split('|');
        $('div.specifications div.seriesLineup_refineUnit p.seriesLineup_refineUnitTitle-button').each (function(){
            if(listSpecifications.indexOf(this.textContent) != -1){
                if(!$(this).parent().hasClass(_open)){
                    $(this).parent().toggleClass(_open).children('.jsAccordionBox').stop().slideToggle(200);
                }
            }
        });
    }
    var checkListMain = $('input.trackingClickMainTab').val();
    if(checkListMain != ''){
        var listMainSpecifications = decodeURI(checkListMain).split('|');
        $('div.seriesLineup_row div#refinementBar div.seriesLineup_refineUnit p.jsAccordionBtn:first-of-type').each (function(){
            if(listMainSpecifications.indexOf(this.textContent) != -1){
                if($(this).parent().hasClass(_open)){
                    $(this).parent().toggleClass(_open).children('.jsAccordionBox').stop().slideToggle(200);
                }
            }
        });
    }
}
function reloadRefineSliderAjax() {
    if ($('.js-refineSlider').length) {
        $('.js-refineSlider').on('init', function() {
            $('.searchRefine_label').heightLine();
        });
        $('.js-refineSlider').slick({
            slidesToScroll : 1,
            slidesToShow : 1,
            autoplay : false,
            arrows : true,
            infinite : false,
            variableWidth : true,
            responsive : [ {
                breakpoint : 768,
                settings : {
                    swipeToSlide : true
                }
            } ]
        });
    }
}
function reloadSearchMode(){
    $('.jsMoreSearchBox').each(function() {
        var _viewS = 10;
        var _box = $(this);
        var _boxLength = $(this).children().length;
        $(this).find(' > *:gt(' + (_viewS - 1) + ')').hide();
        if(_boxLength <= 10){
            $(this).next('.jsMoreSearchBtn').hide();
        }
        $(this).next('.jsMoreSearchBtn').on('click',function(){
            _viewS += 10;
            _box.find(' > *:lt(' + _viewS + ')').show();
            if(_boxLength <= _viewS){
                $(this).hide();
            }
            return false;
        });
    });
}

function cateAnc(){
    var _cateAnchor = $('.categoryAnchor');
	if(_cateAnchor.length){
		$(window).on({
			'scroll': function(){
				var _cateAnchorPos = _cateAnchor.offset().top;
				if( $(this).scrollTop() > _cateAnchorPos ){
					_cateAnchor.addClass(_fixed);
				} else {
					_cateAnchor.removeClass(_fixed);
				}
			}
		});
	}
}
/**
 * 
 * @description Request Ajax set ProductLineItem
 * 
 * @returns {void}
 * 
 * @private
 */
function setProductLineItem() {
    setSeriesSorting();
    searchWhenClickEnter();
    fillDataForInput();
    resizeFlg = true;  // 210603 Mod: FCCL-2876
    setTimeout(resizeProductSP, delayTime); // 210115 Add: FCCL-2449
    $('button.search-order').on('click', function(){
        loading.show();
        var productID = $(this).attr('data-pid');
        // FCCL-2116 add ownedMachine info
        var ownedMachineWebtypeNumber = $('input:radio[name="checkRefine"]:checked').parent().parent().parent('.searchRefine_item').attr('data-mywebtypemodel') || '';
        var ownedMachineSerialNumber = $('input:radio[name="checkRefine"]:checked').parent().parent().parent('.searchRefine_item').attr('data-myserialnumber') || '';
        var urlRedirect = Urls.getProductUrl + '?pid=' + productID + '&ownedMachineWebtypeNumber=' + ownedMachineWebtypeNumber + '&ownedMachineSerialNumber=' + ownedMachineSerialNumber;
        var inputChecked = $('.searchRefine_item input:checked');
        if (inputChecked.length > 0) {
            var itemParent = inputChecked.parents('.searchRefine_item');
            $.ajax({
                method: 'GET',
                data: {
                    My_ProductName: itemParent.attr('data-myproductname'),
                    My_WebTypeModel: itemParent.attr('data-mywebtypemodel'),
                    My_SerialNumber: itemParent.attr('data-myserialnumber'),
                    My_PcWarrantStart: itemParent.attr('data-mypcwarrantstart')
                },
                url: Urls.searchGetSeriesPeripheral + '?format=ajax&ownmachine=true',
            }).done(function(res) {
                setProductLineItem();
                loading.hide();
                window.location.href = urlRedirect;
            }).fail(function() {
                loading.hide();
                window.location.href = urlRedirect;
            });
        } else {
            window.location.href = urlRedirect;
        }
    });
}

/**
 * 
 * @description Set series sorting event
 * 
 * @returns {void}
 * 
 * @private
 */
function setSeriesSorting() {
    //series sorting option
    $( 'div.searchResult_section select#searchOptions' ).change(function() {
        var selectedOption = $(this).find('option:selected');
        var url = selectedOption.attr('value');
        if(!url) {
            return;
        }
        loading.show();
        var rsVal = $('input#refinementSearch_url').attr('rsVal');
        var keyword = $('input#refinementSearch_url').attr('keyword');
        if(rsVal!=""){
            url = url + "&" + rsVal;
        }
        if(keyword!=""){
            url = url + "&" + keyword;
        }
        url = util.appendParamsToUrl(url, {
            'format': 'ajax'
        });
        $.ajax({
            url: url,
            success: function (response) {
                $('div.searchResult_row').empty().html(response);
                setProductLineItem();
                loading.hide();
            },
            error:function(err){
                loading.hide();
            }
        });
    });
}
// FCCL-2415 : bangly 30/12/2020 - Add Start
/**
 * 
 * @description Hide tab empty
 * 
 * @returns {void}
 * 
 * @private
 */
function hideTabTitle() {
    $('div.seriesLineup_row div.seriesLineup_refineUnit').each( 
        function (){
            if( $(this).find('li').length < 1 ){
                $(this).css('display', 'none');
            }
        }
    );
}

/**
 * press Enter then search function
 * @returns
 */
function searchWhenClickEnter() {
    $('input#js-pe-search-keyword').keypress( function(e) {
        if (e.which  === 13) {

            var container = $('#js-pe-result-wrap');
            var url;
            url= $('#search-url').text();

            var searchKeyword = $('#js-pe-search-keyword').val();
            var filterKeyword = $('#js-model-filter-text').val();
            url = util.removeParamsFromURL(url, ['q','model_num', 'sz', 'start']);
            if (searchKeyword !== '') {
                url = util.appendParamToURL(url, 'q', searchKeyword);
            }
            if (filterKeyword !== '') {
                url = util.appendParamToURL(url, 'model_num', filterKeyword);
            }
            url = util.appendParamToURL(url, 'format', 'ajax');

            loading.show();
            $.ajax({
                url: url,
                success: function (response) {
                    container.empty().html(response);
                    setProductLineItem();
                    loading.hide();
                },
                error:function(err){
                    loading.hide();
                }
            });
        }
    });
    
    $('input#series-search-keyword').keypress(function(e) {
        if (e.which  === 13) {
            loading.show();
            var searchKeyword = ($('#series-search-keyword').val() === '文字列を入力') ? '' : $('#series-search-keyword').val();
            $('input#refinement_url').attr('keyword',"q=" + searchKeyword);
            var url = $('input#refinement_url').attr('url');
            if(url!=""){
                url = util.removeParamFromURL(url, 'q');
                url = util.appendParamToURL(url, 'q', searchKeyword);
            }else{
                url = $('input#refinement_url').attr('urlForCategory');
                url = util.appendParamToURL(url, 'q', searchKeyword);
            }
            url = util.appendParamsToUrl(url, {
                'format': 'ajax',
                'listCheck' :  $('input.trackingClick').val(),
                'listCheckMain' : $('input.trackingClickMainTab').val()
            });
            $.ajax({
                url: url,
                success: function (response) {
                    $('div.seriesLineup_row').empty().html(response);
                    setProductLineItem();
                    reloadTabSpecifications();
                    // FCCL-2415 : bangly 30/12/2020 - Add Start
                    hideTabTitle();
                    // FCCL-2415 : bangly 30/12/2020 - Add End
                    loading.hide();
                },
                error:function(err){
                    loading.hide();
                }
            });
        }
    })
}

function fillDataForInput() {
    var $input = $('input#value-parameter');
    if($input.val()) {
        $('input#js-pe-search-keyword').val($input.val());
    }
    
}

// Start 210115 Add: FCCL-2449
/**
* resize product detail panel to the same
*/
function resizeProductSP() {
    // Start 210603 Mod: FCCL-2876
    if (Math.max.apply(null, $('.seriesLineupListSP_price').map(function() {return $(this).height();}).get()) > 0) {
        $('.seriesLineupListSP_model').height(Math.max.apply(null, $('.seriesLineupListSP_model').map(function() {return $(this).height();}).get()));
        $('.seriesLineupListSP_headline').height(Math.max.apply(null, $('.seriesLineupListSP_headline').map(function() {return $(this).height();}).get()));
        $('.seriesLineupListSP_imageBoxWrap').height(Math.max.apply(null, $('.seriesLineupListSP_imageBoxWrap').map(function() {return $(this).height();}).get()));
        $('.seriesLineupListSP_price').height(Math.max.apply(null, $('.seriesLineupListSP_price').map(function() {return $(this).height();}).get()));
        $('.seriesLineupListSP_specWrap').height(Math.max.apply(null, $('.seriesLineupListSP_specWrap').map(function() {return $(this).height();}).get()));
    }
    // End 210603 Mod: FCCL-2876
}
// End 210115 Add: FCCL-2449
// Start 210126 Add: FCCL-1744
function resizeWeeklyRankingProduct() {
    var $Image = $('.weeklyRanking').find('.weeklyRanking_img');
    var $Text = $('.weeklyRanking').find('.weeklyRanking_text');
    if ($Image.length > 0) {
        setTimeout(function() {
            $Image.height('auto');
            $Text.height('auto');
            $Image.height(Math.max.apply(null, $Image.map(function() {return $(this).height();}).get()));
            $Text.height(Math.max.apply(null, $Text.map(function() {return $(this).height();}).get()));
        }, delayTime);
    }
}
// End 210126 Add: FCCL-1744
//FCCL-2502 : bangly 20/01/2021 start
function resizePicture() {
    setTimeout(function() {
        if (Math.max.apply(null, $('.groupproduct_image').map(function() {return $(this).height();}).get()) > 0) {
            $('.groupproduct_image').height('auto');
            $('.groupproduct_image').height(Math.max.apply(null, $('.groupproduct_image').map(function() {return $(this).height();}).get()));
        }
    }, delayTime);
}
//FCCL-2502 : bangly 20/01/2021 end
// FCCL-2415 : bangly 30/12/2020 - Add End
exports.init = function () {
// compareWidget.init();
// if (SitePreferences.LISTING_INFINITE_SCROLL) {
// $(window).on('scroll', infiniteScroll);
// }
// productTile.init();
    initializeEvents();
    reloadRefineSliderAjax();
    reloadSearchMode();
    cateAnc();
    setProductLineItem();
    // FCCL-2415 : bangly 30/12/2020 - Add Start
    hideTabTitle();
    // FCCL-2415 : bangly 30/12/2020 - Add End
    $('div.searchResult_section select#searchOptions').change(); // set change event for default sorting T25
    // Start 210115 Add: FCCL-2449
    $(window).on('orientationchange', function() {
        setTimeout(resizeProductSP, delayTime);
    });
    setTimeout(resizeProductSP, delayTime);  // 210603 Add: FCCL-2876
    // End 210115 Add: FCCL-2449
    // Start 210126 Add: FCCL-1744
    resizeWeeklyRankingProduct();
    $(window).on('resize', function() {
        resizeWeeklyRankingProduct();
        setTimeout(resizeProductSP, delayTime);  // 210603 Add: FCCL-2876
    });
    // End 210126 Add: FCCL-1744
    //FCCL-2502 : bangly 20/01/2021 start
    $(window).on('resize', function() {
        resizePicture();
    });
    resizePicture();
    //FCCL-2502 : bangly 20/01/2021 end
};
