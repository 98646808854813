var loading = require('../../loading'),
    util = require('../../util'),
    page = require('../../page');

var contactus = {
    // FCCL-2309: Modify contact us for customizable on custom object
    // inquiryItems: Object that needs to show
    // @see <input name="inquiryItem" value="XXX" />
    inquiryObject : null,
    /**
     * Initialize contactus
     * 
     * @public
     */
    init: function () {
        // Get value from inquiryItem
        this.inquiryObject = $('input[name="inquiryItem"]').val() ? JSON.parse($('input[name="inquiryItem"]').val()) : null;
        this.initializeContactus();
    },
    /**
     * @private
     * @function
     * @description Binds event the contactus page.
     */
    initializeContactus: function () {
        if(this.inquiryObject) {
            try {
                /**
                 * Check b2b site
                 */
                if ($('.contact-us .b2b').length > 0) {
                    this.setInquiryType(this.inquiryObject.body_b2b);
                } else {
                    this.setInquiryType(this.inquiryObject.body_b2c);
                }
                /**
                 * On submit form
                 */
                $('button[name$="contactus_send"]').on('click', function(e){
                    var errStatus =  page.showFormSubmissionErrors(e);
                    if(e.isDefaultPrevented() || errStatus) {
                        e.preventDefault();
                        return;
                    }
                    e.preventDefault();
                    var subject = 0;
                    var type = '';
                    if ($('.contact-us .b2b').length > 0) {
                        type = 'corporate';
                        subject = $('.contact-us select[name$="subject"] option:selected').text();
                    } else {
                        if ($('.contact-us input[name$="inquiryType"]:checked').hasClass('byEmail')) {
                            type = 'personalEmail';
                        } else {
                            type = 'personalTel';
                        }
                        subject = $('.contact-us select[name$="subject"] option:selected').text();
                    }
                   
                    loading.show();
                    // FCCL-2070: Use util for ajax calling
                    $.ajax({
                        method: 'POST',
                        url: util.ajaxUrl(Urls.contactUsSubmit),
                        data: {
                            inquiryType: $('.contact-us input[name$="inquiryType"]:checked').hasClass('byEmail') ? 1 : 2, // FCCL-2309: Check if email selected
                            subject: subject,
                            toiawase1: $('.contact-us textarea[name$="toiawase1"]:visible').val(),
                            preName: $('.contact-us input[name$="firstnameHira"]:visible').val(),
                            lastName: $('.contact-us input[name$="lastnameHira"]:visible').val(),
                            preNameKana: $('.contact-us input[name$="firstnameKana"]:visible').val(),
                            lastNameKana: $('.contact-us input[name$="lastnameKana"]:visible').val(),
                            mailAdd: $('.contact-us input[name$="email"]:visible').val(),
                            tel: $('.contact-us input[name$="tel"]:visible').val(),
                            phonenumber: $('.contact-us input[name$="phoneNum1"]:visible').val()
                                + "-" + $('.contact-us input[name$="phoneNum2"]:visible').val()
                                + "-" + $('.contact-us input[name$="phoneNum3"]:visible').val(),
                            comanyname: $('.contact-us input[name$="companyName"]:visible').val(),
                            departmentname: $('.contact-us input[name$="departmentName"]:visible').val(),
                            csrf_token: $(this).closest('form').children('input[name="csrf_token"]').val(),
                            type: type
                        },
                        dataType: 'json'
                    }).done(function(res){
                        if (res) {
                            if (res.success) {
                                window.location.href = Urls.contactComplete;
                            }
                            else if(res.csrf_error) {
                                // Redirect to CSRF failed page whenever csrf validation failed
                                page.redirect(Urls.csrffailed);
                            }
                            else {
                                $('.loader').hide();
                                alert('Request fail!');
                            }
                        } else {
                            $('.loader').hide();
                            alert('500 Error Server');
                        }
                    }).fail(function(res){
                        loading.hide();
                    });
                })
            } catch (e) {
                console.error(e);
            }
        }
        
    },
    /**
     * Set inquiry type for displaying if we choose mail or phone
     * @param {Object} inquiryType Object contains mail, phone
     * @example 
     * "mail": {
     *       "inquiryBeforePurchase" : {
     *           "inquiryProductInfo" : "",
     *           "inquiryContent" : "inquiryContent value"
     *       },
     *       "issueReceipt" : {}
     * },
     * "phone": { }
     */
    setInquiryType: function(inquiryType) {
        var contact = this;
        /**
         * Handle call function setSubjects base on radio inquiryType
         */
        var indicateInquiry = function() {
            if($('.contact-us input[name$="inquiryType"]:checked').hasClass('byEmail')) {
                contact.setSubjects(inquiryType.mail);
                //Set placeholder of body
                $('textarea[name$="toiawase1"]').prop('placeholder', $('input[name="bodyPlaceholderMail"]').val());
            }
            else {
                contact.setSubjects(inquiryType.phone);
                //Set placeholder of body
                $('textarea[name$="toiawase1"]').prop('placeholder', $('input[name="bodyPlaceholderPhone"]').val());
            }
        }
        // Set change event for radio button
        $('.contact-us input[name$="inquiryType"]').off().on('change', function(){
            indicateInquiry();
        });
        // Trigger event
        $('.contact-us input[name$="inquiryType"]').trigger('change');
    },
    /**
     * Set subject option into <select> and add event when it changes.
     * @param {Object} subjectsItem Object contains info base on mail or phone
     * @example
     * {
     *      "inquiryBeforePurchase" : {
     *          "inquiryProductInfo" : "inquiryProductInfo value"
     *      },
     *      "issueReceipt" : { }
     * }
     */
    setSubjects: function(subjectsItem) {
        // Get subject keys
        var keySubject = Object.keys(subjectsItem);
        $('select[name$="subject"]').html(''); // Reset select tag
        var contact = this;
        // FCCL-2457: add param inquiryType and save to session
        var inquiryTypeURL = $('input[name$="inquiryTypeURL"]').val();
        if (!inquiryTypeURL || JSON.parse(inquiryTypeURL) == null) {
            inquiryTypeURL = 0;
        }
        var counter = 0;
        var selectedItemValue = '';
        var lastItemValue = '';
        keySubject.forEach(function(item) {
            // Append option into <select>
            $('.contact-us select').append('<option value="' + item + '">' + contact.getName(item) + '</option>');
            if (counter == inquiryTypeURL) {
                selectedItemValue = item;
            }
            counter++;
            if (inquiryTypeURL >= keySubject.length) {
                lastItemValue = item;
            }
        });
        if (inquiryTypeURL >= keySubject.length) {
            $('.contact-us select').val(lastItemValue);
        } else {
            $('.contact-us select').val(selectedItemValue);
        }
        // End of FCCL-2457
        if(keySubject.length > 0) {
            // Set event when <select> change
            $('select[name$="subject"]').off().on('change', function(){
                // Call function setBody with current value
                contact.setBody(subjectsItem[$(this).val()]);
            });
            // Trigger event
            $('select[name$="subject"]').trigger('change');
        }
    },
    /**
     * Set object into text area with value
     * @param {Object} bodyItem Object contains key value to show in text area
     * @example
     * {
     *     "inquiryProductInfo" : "inquiryProductInfo value",
     *     "purchaseFujiHistory" : "purchaseFujiHistory value"
     * }
     */
    setBody: function(bodyItem) {
        // Get bodyItem keys
        var inquirySelected = Object.keys(bodyItem);
        var body = $('.contact-us textarea[name$="toiawase1"]');
        var contact = this;
        var bodyValue = '';
        inquirySelected.forEach(function(item) {
            // Loop through bodyItem keys and concatenate key with value in object
            bodyValue += contact.getName(item) + '：' + bodyItem[item] + '\n';
        });

        body.val(bodyValue.slice(0, bodyValue.length - 1)); // Slice last \n character
    },
    /**
     * Function get object's name value with keyName
     * @param {String} keyName Key of object needs to get name value
     * @returns String name value
     */
    getName: function(keyName) {
        return this.inquiryObject.fields[keyName]; // Get name from inquiryObject.fields
    }
}

module.exports = contactus;
