'use strict';

var account = require('./account'),
    util = require('../util'),
    page = require('../page'),
    loading = require('../loading'),
    modal = require('../modal'),
    //bonusProductsView = require('../bonus-products-view'),
    //quickview = require('../quickview'),
    cartStoreInventory = require('../storeinventory/cart');

// FCCL-2116
$(document).ready(function(){
    var errText = $('input[name="errText"]').val();
    if (errText !== '' && errText !== undefined && errText !== null && errText !== false & errText !== 'null' && errText !== 'false') {
        modal.dialog(errText, function() {});
    }
})

/**
 * @private
 * @function
 * @description Binds events to the cart page (edit item's details, bonus item's actions, coupon code entry)
 */
function initializeEvents() {
    /*
    $('#cart-table').on('click', '.item-edit-details a', function (e) {
        e.preventDefault();
        quickview.show({
            url: e.target.href,
            source: 'cart'
        });
    })
    .on('click', '.bonus-item-actions a, .item-details .bonusproducts a', function (e) {
        e.preventDefault();
        bonusProductsView.show(this.href);
    });
    */
    if (errMsg !== ''){
        modal.dialog(errMsg, function() {
            //@TODO
        });
        return false;
    }

    // override enter key for coupon code entry
    $('form input[name$="_couponCode"]').on('keydown', function (e) {
        if (e.which === 13 && $(this).val().length === 0) { return false; }
    });

    // Clear click event before triggering new one
    $('button[name$="addCoupon"]').off('click').on('click', function (e) {
        e.preventDefault();

        loading.show();
        var $form = $(this).closest('form');
        var url = util.ajaxUrl($form.attr('action'));
        var applyName = $(this).attr('name');

        var options = {
            url: url,
            data: $form.serialize() + '&' + applyName + '=x',
            type: 'POST'
        };

        $.ajax(options)
            .success(function (data) {
                if (data && data.csrf_error) {
                    // Redirect to CSRF failed page whenever csrf validation failed
                    return page.redirect(Urls.csrffailed);
                }
                if (typeof data === "object") {
                    return page.refresh();
                }

                loading.hide();
                $form.find('.cartProductDetail_choiceCoupon p').addClass('custom');
                $form.find('.cartProductDetail_choiceCoupon input[name$="couponCode"]').addClass('custom');
                $form.find('#message-coupon').empty().append(data);
                initializeEvents();
            })
            .error(function (e) {
                loading.hide();
            });
    })

    $('button[name$="deleteCoupon"]').on('click', function (e) {
        e.preventDefault();

        loading.show();
        var $form = $(this).closest('form');
        var url = util.ajaxUrl($form.attr('action'));
        var applyName = $(this).attr('name');

        var options = {
            url: url,
            data: $form.serialize() + '&' + applyName + '=x',
            type: 'POST'
        };

        $.ajax(options).done(function (response) {
            if(response && response.csrf_error) {
                // Redirect to CSRF failed page whenever csrf validation failed
                page.redirect(Urls.csrffailed);
            } else {
                page.refresh();
            }
        }).fail(function (xhr, textStatus) {
            loading.hide();
        })
    })

    //to prevent multiple submissions of the form when removing a product from the cart
    var removeItemEvent = false;
    $('button[name$="deleteProduct"]').on('click', function (e) {
        if (removeItemEvent) {
            e.preventDefault();
        } else {
            removeItemEvent = true;
        }
    });

    //validate special and quantity
    $('button[name$="updateLineItem"]').on('click', function (e) {
        e.preventDefault();
        loading.show();
        var $productDetail = $(this).closest('.cartProductDetail_unitRow');
        var $li = $(this).closest('.cartProductDetail_unitRow');
        
        
        var input = $li.find('input[name$="quantity"]');
        if(input.length > 0) {
            var quantity = input.val();
        } else {
            var quantity = $productDetail.find('select[name$="quantity"]').val();
        }
        
        var specialPrice = $productDetail.find('input[name$="specialPrice"]').val();

        var check = checkQuantitySpecialPrice(quantity, specialPrice);
        if (!check) {
            loading.hide();
            modal.dialog(Resources.ERROR_CART_WARN_0003, function () {
                //TODO
            });
            return false;
        }
        var $form = $(this).closest('form');
        var url = util.appendParamToURL($form.attr('action'), 'format', 'ajax');
        var applyName = $(this).attr('name');
        var options = {
            url: url,
            data: $form.serialize() + '&' + applyName + '=x',
            type: 'POST'
        };
        $.ajax(options).done(function (data) {
            if(data && !data.error) {
                page.refresh();
            } else if(data && data.csrf_error) {
                // Redirect to CSRF failed page whenever csrf validation failed
                page.redirect(Urls.csrffailed);
            } else {
                loading.hide();
                modal.dialog(Resources.ERROR_CART_WARN_0003, function () {
                    //TODO
                });
            }
        });
    });

    $('button[name$="updateCart"]').on('click', function(e) {
        e.preventDefault();
        loading.show();
        var $form = $(this).closest('form');
        var url = util.appendParamToURL($form.attr('action'), 'format', 'ajax');
        var applyName = $(this).attr('name');
        var options = {
            url: url,
            data: $form.serialize() + '&' + applyName + '=x',
            type: 'POST'
        };
        $.ajax(options).done(function (data) {
            
            if(data && !data.error) {
                page.refresh();
            } else if(data && data.csrf_error) {
                // Redirect to CSRF failed page whenever csrf validation failed
                page.redirect(Urls.csrffailed);
            } else {
                loading.hide();
                modal.dialog(Resources.ERROR_CART_WARN_0003, function () {
                    //TODO
                });
            }
            
        });
    });

    $('button[name$="updateLineItemService"]').on('click', function (e) {
        e.preventDefault();
        
        var $productDetail = $(this).closest('.cartProductDetail_unitRow');
        var $li = $(this).closest('.cartProductDetail_unitRow');
        var input = $li.find('input[name$="quantity"]');
        if(input.length > 0) {
            var quantity = input.val();
        } else {
            var quantity = $productDetail.find('select[name$="quantity"]').val();
        }
        
        var specialPrice = $productDetail.find('input[name$="specialPrice"]').val();

        var check = checkQuantitySpecialPrice(quantity, specialPrice);
        if (!check) {
            modal.dialog(Resources.ERROR_CART_WARN_0003, function () {
                //TODO
            });
            return false;
        }
        var $form = $(this).closest('form');
        var url = util.appendParamToURL($form.attr('action'), 'format', 'ajax');
        loading.show();

        var $li = $(this).closest('.cartProductDetail_unitRow');
        $form.find('input[name$="inputpliid"]').val($li.attr('data-uuid'));
        var input = $li.find('input[name$="quantity"]');
        if(input.length > 0) {
            $form.find('input[name$="inputqty"]').val(input.val());
        } else {
            $form.find('input[name$="inputqty"]').val($li.find('select[name$="quantity"] :selected').val());
        }
        
        var applyName = $(this).attr('name');
        var options = {
            url: url,
            data: $form.serialize() + '&' + applyName + '=x',
            type: 'POST'
        };
        $.ajax(options).done(function (data) {
            if(data && data.csrf_error) {
                // Redirect to CSRF failed page whenever csrf validation failed
                return page.redirect(Urls.csrffailed);
            }
            page.refresh();
        });
    });

    $('button[name$="deleteLineItemService"]').on('click', function (e) {
        e.preventDefault();
        var $form = $(this).closest('form');
        var url = util.appendParamToURL($form.attr('action'), 'format', 'ajax');
        loading.show();
        var $li = $(this).closest('.cartProductDetail_unitRow');

        $form.find('input[name$="inputpliid"]').val($li.attr('data-uuid'));
        $form.find('input[name$="inputqty"]').val($li.find('select[name$="quantity"] :selected').val());

        var applyName = $(this).attr('name');
        var options = {
            url: url,
            data: $form.serialize() + '&' + applyName + '=x',
            type: 'POST'
        };
        $.ajax(options).done(function (data) {
            //loading.hide();
            if(data && data.csrf_error) {
                // Redirect to CSRF failed page whenever csrf validation failed
                return page.redirect(Urls.csrffailed);
            }
            page.refresh();
        });
    });
    // check click button ご購入手続きへ
    $('button[name$="checkoutCart"]').on('click', function (e) {
        e.preventDefault();
        // FCCL-2515: Prevent click events when total price is negative
        if(checkTotalPriceNegative()) {
            return;
        }
        
        var $form = $(this).closest('form');
        var url = util.appendParamToURL($form.attr('action'), 'format', 'ajax');
        loading.show();

        var applyName = $(this).attr('name');
        var options = {
            url: url,
            data: $form.serialize() + '&' + applyName + '=x',
            type: 'POST'
        };
        $.ajax(options).done(function (data) {
            if (data && data.errText !== undefined) {
                var message = data.errText.split(",");
                if (Resources[message[0]] !== undefined) {
                    loading.hide();
                    //Start FCCL-2306 fix show name of product contain comma
                    var msgLength = message.length;
                    var msgText = '"';
                    if(msgLength > 1) {
                        for(var i = 1; i < msgLength; i ++) {
                            if(i != (msgLength - 1)) {
                                msgText += message[i] + ',';
                            } else {
                                msgText += message[i] + '"' + Resources[message[0]];
                            }
                            
                        }
                    } else {
                        msgText = Resources[message[0]];
                    }
                    modal.dialog(msgText, function() {
                        //@TODO
                    });
                    //End FCCL-2306 fix show name of product contain comma
                }
            } else if (data && data.errTextStock !== undefined) { // FCCL-2663 add
                loading.hide();
                modal.dialog(data.errTextStock, function(){})
            } else if(data && data.Url !== undefined){
                page.redirect(data.Url);
            } else if(data && data.csrf_error) {
                // Redirect to CSRF failed page whenever csrf validation failed
                page.redirect(Urls.csrffailed);
            } else {
                page.refresh();
            }
        });
    });

    //validate special price
    $("input[name$='specialPrice']").on('keyup', function (e) {
        e.preventDefault();
        var totalSalePrice = $(this).siblings(".total-sale-price").val();
        while (!/^\d*$/.test(this.value) || parseInt(this.value) > parseInt(totalSalePrice)) {
            $(this).val(this.value.slice(0, -1));
        }
    })
    $("input[name$='specialPrice']").on('input', function () {
        var totalSalePrice = $(this).siblings(".total-sale-price").val();
        if (!/^\d*$/.test(this.value) || (parseInt(this.value) > parseInt(totalSalePrice))) {
            while (parseInt(this.value) > parseInt(totalSalePrice)) {
                $(this).val(this.value.slice(0, -1));
            }
        }
    })
    
    $('form.page-t08').on('keyup keypress', function(e) {
        var keyCode = e.keyCode || e.which;
        if (keyCode === 13) { 
            e.preventDefault();
            return false;
        }
    });    
    
    $('.productSpec_inputNumber').on('change', function (e) {
        var qty = 0;
        var control = $(this);
        var $li = $(this).closest('.cartProductDetail_unitRow');
        var uuid = $li.attr('data-uuid');
        if(uuid) {
            var btnUpdateLI = $li.find('button#buttonUpdateLIS');
            var aTag = $li.find('a#buttonUpdateLIS');
        } else {
            var btnUpdateLI = $li.find('button#buttonUpdateLI');
            var aTag = $li.find('a#buttonUpdateLI');
        }
        
        if(control.val() == null || control.val() == undefined || control.val() == "") {
            qty = 0;
        } else {
            qty = parseInt(control.val(), 10);
        }

        if(qty > 999 || qty < 1) {
            btnUpdateLI.attr("disabled", true);
            aTag.css('background-color', '#606A76');
            window.alert(inputNunmberValidate);
        } else {
            var isEnable = btnUpdateLI.prop('disabled');
            if(isEnable) {
                btnUpdateLI.removeAttr("disabled");
                aTag.css('background-color', '#FE6D4B');
            }
        }
    })
    
    /**
     * Validate when click button create Quote
     */
    $('a.quote_show').on('click', function(e){
        e.preventDefault();
        // FCCL-2515: Prevent click events when total price is negative
        if(checkTotalPriceNegative()) {
            return;
        }
        var errText = $(this).attr('errText');
        
        if(errText && errText != 'false'){
            var message = errText.split(",");
            if (Resources[message[0]] !== undefined) {
                var msgLength = message.length;
                var msgText = '"';
                if(msgLength > 1) {
                    for(var i = 1; i < msgLength; i ++) {
                        if(i != (msgLength - 1)) {
                            msgText += message[i] + ',';
                        } else {
                            msgText += message[i] + '"' + Resources[message[0]];
                        }
                        
                    }
                } else {
                    msgText = Resources[message[0]];
                }
                modal.dialog(msgText, function() {
                    //@TODO
                });
                //End FCCL-2306 fix show name of product contain comma
            }
        } else {
            // Start FCCL-2935 210812 Add: Check cart before add quote
            var $form = $(this).closest('form');
            var url = util.appendParamToURL($form.attr('action'), 'format', 'ajax');
            loading.show();

            var applyName = $(this).attr('name');
            var options = {
                url: url,
                data: $form.serialize() + '&' + applyName + '=x',
                type: 'POST'
            };
            $.ajax(options).done(function (data) {
                if (data && data.errText !== undefined) {
                    var message = data.errText.split(",");
                    if (Resources[message[0]] !== undefined) {
                        loading.hide();
                        var msgLength = message.length;
                        var msgText = '"';
                        if(msgLength > 1) {
                            for(var i = 1; i < msgLength; i ++) {
                                if(i != (msgLength - 1)) {
                                    msgText += message[i] + ',';
                                } else {
                                    msgText += message[i] + '"' + Resources[message[0]];
                                }
                                
                            }
                        } else {
                            msgText = Resources[message[0]];
                        }
                        modal.dialog(msgText, function() {
                            //@TODO
                        });
                    }
                } else if (data && data.errTextStock !== undefined) {
                    loading.hide();
                    modal.dialog(data.errTextStock, function(){})
                } else if(data && data.Url !== undefined){
                    page.redirect(data.Url);
                } else if(data && data.csrf_error) {
                    // Redirect to CSRF failed page whenever csrf validation failed
                    page.redirect(Urls.csrffailed);
                } else {
                    page.refresh();
                }
            });
            // End FCCL-2935 210812 Add: Check cart before add quote (copy from action checkoutCart)
        }
    })
}

//Check quantity and special price
function checkQuantitySpecialPrice(quantity, specialPrice) {
    if (specialPrice) {
        if (parseInt(specialPrice.replace(/,/g, '')) %  parseInt(quantity) !== 0) {
            return false;
        }
    }
    return true
}

/**
 * FCCL-2515: check total price in T08 is negative number
 * @returns {Boolean} True if total price value is a negative number, otherwise is positive
 */
function checkTotalPriceNegative() {
    var $totalValue = $('#orderTotalValue');
    // FCCL-2527: Add function check special price
    if(($totalValue && parseInt($totalValue.val()) < 0) || !checkPrdPriceWithSpecPrice()) {
        modal.dialog(Resources.CART_TOTAL_VALUE_NEGATIVE, function () {
            //TODO
        });
        return true;
    }
    return false;
}
/**
 * FCCL-2527: check special price with product price T08 is not applicable
 * @returns {Boolean} True if it's applicable, otherwise false
 */
function checkPrdPriceWithSpecPrice() {
    var jSpecialPrice = $('input[name$=_specialPrice]');
    for (var i = 0; i < jSpecialPrice.length; i++) {
        var $specialPrice = $(jSpecialPrice[i]);
        // Continue if $specialPrice doesnt have value
        if($specialPrice.val() === '') {
            continue;
        }
        // Parse int special price after replace comma character
        var specialValue = parseInt($specialPrice.val().replace(/,/g, '')); // FCCL-2561: Use regex instead of replaceAll function for IE
        // Find next input and get value
        var bestPriceVal = parseInt($specialPrice.next('input').val());
        if (bestPriceVal && (specialValue - bestPriceVal > 0)) {
            return false;
        }
    }
    return true;
}

exports.init = function () {
    initializeEvents();
    if (SitePreferences.STORE_PICKUP) {
        cartStoreInventory.init();
    }
    account.initCartLogin();
};
