'use strict';

var address = require('./address'),
    billing = require('./billing'),
    multiship = require('./multiship'),
    shipping = require('./shipping'),
    loading = require('../../loading'),
    page = require('../../page'),
    modal = require('../../modal');

/**
 * @function Initializes the page events depending on the checkout stage (shipping/billing)
 */
exports.init = function () {
    address.init();
    if ($('.checkout-shipping').length > 0) {
        shipping.init();
    } else if ($('.checkout-multi-shipping').length > 0) {
        multiship.init();
    } else {
        billing.init();
    }

    //if on the order review page and there are products that are not available diable the submit order button
    if ($('.order-summary-footer').length > 0) {
        if ($('.notavailable').length > 0) {
            $('.order-summary-footer .submit-order .button-fancy-large').attr('disabled', 'disabled');
        }
    }
    
    // FCCL-2371: Change jquery submit form more specifically on check out template (T9 -> T11)
    $(document).on('submit', 'form.address.form-horizontal', function(e) {
        if (e.delegateTarget.activeElement.localName.toUpperCase() == "INPUT" || e.delegateTarget.activeElement.localName.toUpperCase() == "TEXTAREA") {
            e.preventDefault();
        }
    });
    
    function pageRedirectConfirm(url, msg) {
        // console.log('callback yes');
        //FCCL-2774 : bangly 9-4-2021 start
        page.redirect(url);
        //FCCL-2774 : bangly 9-4-2021 end
    };
    
    $('button.form_button-back').on('click', function() {
        pageRedirectConfirm($(this).attr('url'), $(this).attr('conf-msg'));
    });
    
    $('button.sideSubInfo_button').on('click', function() {
        pageRedirectConfirm($(this).attr('url'), $(this).attr('conf-msg'));
    });
};
