/**
 *    (c) 2009-2014 Demandware Inc.
 *    Subject to standard usage terms and conditions
 *    For all details and documentation:
 *    https://github.com/SalesforceCommerceCloud/sitegenesis
 */

'use strict';

var countries = require('./countries'),
    dialog = require('./dialog'),
    minicart = require('./minicart'),
    page = require('./page'),
    rating = require('./rating'),
    searchplaceholder = require('./searchplaceholder'),
    searchsuggest = require('./searchsuggest'),
    tooltip = require('./tooltip'),
    util = require('./util'),
    validator = require('./validator'),
    tls = require('./tls'),
    modal = require('./modal'),
    consentTracking = require('./consentTracking'),
    seriesSV = require('./pages/category/series_sv'),
    jqueryCustom = require('./jquery-custom'),
    ajax = require('./ajax');  //210317 Add: FCCL-2705

require('./jquery-ext')();
require('./cookieprivacy')();
consentTracking.init();
require('./captcha')();

function initializeEvents() {
    var controlKeys = ['8', '13', '46', '45', '36', '35', '38', '37', '40', '39'];

    $('body')
        .on('keydown', 'textarea[data-character-limit]', function (e) {
            var text = $.trim($(this).val()),
                charsLimit = $(this).data('character-limit'),
                charsUsed = text.length;

            if ((charsUsed >= charsLimit) && (controlKeys.indexOf(e.which.toString()) < 0)) {
                e.preventDefault();
            }
        })
        .on('change keyup mouseup', 'textarea[data-character-limit]', function () {
            var text = $.trim($(this).val()),
                charsLimit = $(this).data('character-limit'),
                charsUsed = text.length,
                charsRemain = charsLimit - charsUsed;

            if (charsRemain < 0) {
                $(this).val(text.slice(0, charsRemain));
                charsRemain = 0;
            }

            $(this).next('div.char-count').find('.char-remain-count').html(charsRemain);
        });

    /**
     * initialize search suggestions, pending the value of the site preference(enhancedSearchSuggestions)
     * this will either init the legacy(false) or the beta versions(true) of the the search suggest feature.
     * */
    /** START update 2020/05/06 */
    // var $searchContainer = $('#navigation .header-search');
    var $searchContainer = $('.header_searchBox div.header_search');
    /** END update 2020/05/06 */
    searchsuggest.init($searchContainer, Resources.SIMPLE_SEARCH);

    // add show/hide navigation elements
    $('.secondary-navigation .toggle').click(function () {
        $(this).toggleClass('expanded').next('ul').toggle();
    });

    // add generic toggle functionality
    $('.toggle').next('.toggle-content').hide();
    $('.toggle').click(function () {
        $(this).toggleClass('expanded').next('.toggle-content').toggle();
    });

    // subscribe email box
    var $subscribeEmail = $('.subscribe-email');
    if ($subscribeEmail.length > 0)    {
        $subscribeEmail.focus(function () {
            var val = $(this.val());
            if (val.length > 0 && val !== Resources.SUBSCRIBE_EMAIL_DEFAULT) {
                return; // do not animate when contains non-default value
            }

            $(this).animate({color: '#999999'}, 500, 'linear', function () {
                $(this).val('').css('color', '#333333');
            });
        }).blur(function () {
            var val = $.trim($(this.val()));
            if (val.length > 0) {
                return; // do not animate when contains value
            }
            $(this).val(Resources.SUBSCRIBE_EMAIL_DEFAULT)
                .css('color', '#999999')
                .animate({color: '#333333'}, 500, 'linear');
        });
    }

    $('.privacy-policy').on('click', function (e) {
        e.preventDefault();
        dialog.open({
            url: $(e.target).attr('href'),
            options: {
                height: 600
            }
        });
    });

    $('.consent-tracking-policy').on('click', function (e) {
        e.preventDefault();
        consentTracking.show();
    });
    
    // main menu toggle
    $('.menu-toggle').on('click', function () {
        $('#wrapper').toggleClass('menu-active');
    });
    $('.menu-category li .menu-item-toggle').on('click', function (e) {
        e.preventDefault();
        var $parentLi = $(e.target).closest('li');
        $parentLi.siblings('li').removeClass('active').find('.menu-item-toggle').removeClass('fa-chevron-up active').addClass('fa-chevron-right');
        $parentLi.toggleClass('active');
        $(e.target).toggleClass('fa-chevron-right fa-chevron-up active');
    });
    $('.user-account').on('click', function (e) {
        e.preventDefault();
        $(this).parent('.user-info').toggleClass('active');
    });

    //disabled close modal dialog when click not button
    $('#modal_dialog .categoryCustom_confirmInner').on('click', function (e) {
        if ($(e.target).is('button') === false) {
            e.preventDefault();
            e.stopPropagation();
            return;
        }
    });
    //trigger categoryAnchor
    jqueryCustom.init();
}
/**
 * @private
 * @function
 * @description Adds class ('js') to html for css targeting and loads js specific styles.
 */
function initializeDom() {
    // add class to html for css targeting
    $('html').addClass('js');
    if (SitePreferences.LISTING_INFINITE_SCROLL) {
        $('html').addClass('infinite-scroll');
    }
    // load js specific styles
    util.limitCharacters();
}

/**
 * Add href to button elment
 * @example <button href="<url>" class="href">Navigate</button>
 * 
 */
function initLinkOnButton() {
    var btn = $('button.href');
    if (btn.attr('href')) {
        btn.on('click', function(e) {
            e.preventDefault();
            location.href = btn.attr('href');
        });
    }
}

var pages = {
    account: require('./pages/account'),
    cart: require('./pages/cart'),
    checkout: require('./pages/checkout'),
    compare: require('./pages/compare'),
    product: require('./pages/product'),
    registry: require('./pages/registry'),
    search: require('./pages/search'),
    storefront: require('./pages/storefront'),
    wishlist: require('./pages/wishlist'),
    empIntro: require('./pages/empIntro'),
    storelocator: require('./pages/storelocator'),
    orderconfirmation: require('./pages/orderconfirmation'),
    print: require('./pages/print')  // 210122 Add: FCCL-2495
};

var app = {
    init: function () {
        if (document.cookie.length === 0) {
            $('<div/>').addClass('browser-compatibility-alert').append($('<p/>').addClass('browser-error').html(Resources.COOKIES_DISABLED)).appendTo('#browser-check');
        }
        initializeDom();
        initializeEvents();

        // init specific global components
        countries.init();
        tooltip.init();
        minicart.init();
        validator.init();
        rating.init();
        searchplaceholder.init();
        seriesSV.init();

        if (getQueryString('popup_src') === 'show') {
            window.history.pushState('',document.title, location.origin + location.pathname);
            modal.customPopup(Resources.MODAL_SOURCECODE_TEXT);
        }
        initLinkOnButton();
        // execute page specific initializations
        $.extend(page, window.pageContext);
        var ns = page.ns;
        if (ns && pages[ns] && pages[ns].init) {
            pages[ns].init();
        }
        page.showFormSubmissionErrors(null);
        // FCCL-2070: Add csrf into all forms which have post or get method
        page.getAndInsertCSRFToken(null);

        // Check TLS status if indicated by site preference
        if (SitePreferences.CHECK_TLS === true) {
            tls.getUserAgent();
        }

        // FCCL-3192 【F-142】学割の職域ログイン〜購入のページ動線をかえる
        $('.handlingOfPersonalInformation a').on('click', function () {
            modal.dialog(Resources.MODAL_HANDLING_OF_PERSONAL_INFOMATION__TEXT);
        });
    }
};

/**
 * Get Query String in URL (Working in IE)
 * 
 */
function getQueryString() {
    var key = false,
        res = {},
        itm = null;
    // get the query string without the ?
    var qs = location.search.substring(1);
    // check for the key as an argument
    if (arguments.length > 0 && arguments[0].length > 1) {
        key = arguments[0];
    }
    // make a regex pattern to grab key/value
    var pattern = /([^&=]+)=([^&]*)/g;
    // loop the items in the query string, either
    // find a match to the argument, or build an object
    // with key/value pairs
    while (itm = pattern.exec(qs)) {
        if (key !== false && decodeURIComponent(itm[1]) === key) {
            return decodeURIComponent(itm[2]);
        } else if (key === false) {
            res[decodeURIComponent(itm[1])] = decodeURIComponent(itm[2]);
        }
    }
    return key === false ? res : null;
}

// general extension functions
(function () {
    String.format = function () {
        var s = arguments[0];
        var i, len = arguments.length - 1;
        for (i = 0; i < len; i++) {
            var reg = new RegExp('\\{' + i + '\\}', 'gm');
            s = s.replace(reg, arguments[i + 1]);
        }
        return s;
    };
})();

// initialize app
$(document).ready(function () {
    app.init();
    //show alert message 
    if (window.ErrorMessage !== undefined
        && window.ErrorMessage !== null
        && window.ErrorMessage !== '') {
        alert(window.ErrorMessage);
    }

    // Start 210317 Add: FCCL-2705
    setInterval(function() {
        $.ajax({
            url: Urls.emptyPage,
        })
        .done(function(data, textStatus, jqXHR) {
        });
    }, 20*60*1000);
    // End 210317 Add: FCCL-2705
});
