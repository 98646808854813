'use strict';

var modal = require('../modal');

/**
 * @private
 * @function
 * @description show order error message
 */
function initializeEvents() {

    if ($('#summary-error-text').val() != undefined && $('#summary-error-text').val() != 'null' && $('#summary-error-text').val() != '') {
        modal.dialog($('#summary-error-text').val(), function() {
            //@TODO
        });
    }
}

exports.init = function () {
    initializeEvents();
};
