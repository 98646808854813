'use strict';

var comf = require('./Comf'),
    intro = require('./Intro')

/**
 * @function Initializes the page events depending on the checkout stage (shipping/billing)
 */
exports.init = function () {
	if ($('.membersInfo').length > 0) {
		comf.init();
	} else if ($('.complete').length > 0) {
		 intro.init();
	}
};
