'use strict';

var dialog = require('./dialog'),
    page = require('./page'),
    loading = require('./loading'),
    validator = require('./validator');

var login = {
    /**
     * @private
     * @function
     * @description init events for the loginPage
     */
    init: function () {
        // Start 201210 Add: FCCL-2331
        var postalErr = $('#div_err_check_postal');
        var stateErr = $('#div_err_change_state');
        var maxPostalLength = 7;
        var maxTimeCount = 30;  // 210120 Mod: FCCL-2485
        var intervalTime = 100;
        var topPadding = 5;
        var leftPadding = 15;
        var styleDisplayShow = 'block';
        var $oldStateFtra = $('#old_state');
        var $oldPostalFtra = $('#old_postal');
        var $oldCityFtra = $('#old_city');
        var $oldAddress1Ftra = $('#old_address1');
        var $curState = $('select[name$="_state"]');
        var $curPostal = $('input[name$="_reg__postalCode"]');
        var $curCity = $('input[name$="_reg__city"]');
        var $curAddress1 = $('input[name$="_reg__address1"]');
        // Start 201218 Add: FCCL-2393
        var $defaultStateOption = $('select[name$="_state"] option[value=""]');
        var defaultStateText = $defaultStateOption.text();
        // End 201218 Add: FCCL-2393
        // End 201210 Add: FCCL-2331
        var isCallCenter = $('input[name*="login_firstNamek"]').length > 0 && $('input[name*="login_lastNamek"]').length > 0;
        //o-auth binding for which icon is clicked
        $('.oAuthIcon').on('click', function () {
            $('#OAuthProvider').val(this.id);
        });        
        /** START T23: event if user click button 新規登録する then show register form with accordion
         * 		and check if the form showed and user dont check on accept terms checkbox
         * 		then disable button 登録
         * */
        $(".jsAccAccordionBtn").click(function () {
            if ($('.jsAccAccordionBox').css("display") == "block") {
                $("input[name='accept-terms']").change();
                $('select[name$="_registration_secretquestion"]').change();
            }
        });

        // disable button register if checkbox accept terms is uncheck
        $("input[name='accept-terms']").change(function () {
            if (this.checked) {
                $('#register-button').removeClass("button-disable");
            } else {
                $('#register-button').addClass("button-disable");
            }
        });


        //login_mail_check
        $('.jsCheckOn').click(function(){
            $('.accountForm_mailUnit input[type="checkbox"]').each(function(){
                $(this).prop('checked', 'checked');
            });
        });
        $('.jsCheckOff').click(function(){
            $('.accountForm_mailUnit input[type="checkbox"]').each(function(){
                $(this).prop('checked', '');
            });
        });


        /**
         * show date selection and hide secret answer input when user choose 'what's your birthday' and vice versa
         */

        $('select[name$="_registration_secretquestion"]').change(function () {
            var selectedIndex = $(this).find('option:selected').val();
            if (selectedIndex == 1) {
                $('#secret-birthday').show();
                $('#secret-answer').hide();
            } else {
                $('#secret-birthday').hide();
                $('#secret-answer').show();
            }
        });
        $('select[name$="_registration_secretquestion"]').change();
        /**
         *  change location from cr_style for loading register accordion when error 
         * */
        //login_accrdion
        $('.jsAccAccordionBtn').next('button').hide();
        // hide image of FTRA when accordion btn hasnt click yet
        $('.jsAccAccordionBtn').next('img').hide();
        // $('.jsAccAccordionBtn').click(function () {
        //     $('.jsAccAccordionBox').slideDown();
        //     $(this).hide();
        //     $(this).next('button').show();
        // });

        //get required field (first name) for checking open accordion and showing error
        var regFirstName = $('input[name$="_registration_reg__firstname"]').val();
        if (regFirstName !== null && regFirstName !== "") {
            $('.jsAccAccordionBtn').click(function () {
                $('.jsAccAccordionBox').slideDown(function() {
                    // Check if 'div.accountForm accountForm-consent' selector's display is not none then scroll into it
                    // otherwise scroll into 'accountForm_heading'
                    if(document.getElementsByClassName('accountForm accountForm-consent')[0].style.display !== 'none') {
                        document.getElementsByClassName('accountForm accountForm-consent')[0].scrollIntoView(true);
                    } else {
                        document.getElementsByClassName('accountForm_heading')[0].scrollIntoView(true);
                    }
                });
                $(this).hide();
                $(this).next('button').show();
                $(this).next('img').show();
            });
            // open accordion
            $('a.linkRegist').click();
            // set secret answer field
            $('select[name$="_registration_secretquestion"]').change();
            // disable button register
            if(!isCallCenter) {
                $('#register-button').addClass("button-disable");
            }
            // head to div class error-anchor
        }
        else {
            $('.jsAccAccordionBtn').click(function () {
                $('.jsAccAccordionBox').slideDown();
                $(this).hide();
                $(this).next('button').show();
                $(this).next('img').show();
            });
        }

        /**
         * Function and events to adjust day based on secret answer month and year
         */
        $('input[id$="registration_secretquestionanswer__year"]').change(function() {
            adjustDay();
        });

        $('select[id$="registration_secretquestionanswer__month"]').change(function() {
            adjustDay();
        });

        // Adjust the day
        function adjustDay() {
            var year = $('input[id$="registration_secretquestionanswer__year"]').val();
            var month = $('select[id$="registration_secretquestionanswer__month"]').val();
            var day = new Date(year, month, 0).getDate();
            var scope = $('select[id$="registration_secretquestionanswer__day"]');
            if(scope.val() > day) {
                scope.val('1')
            }
            for(var i = 1; i <= day; i++) {
                scope.children('option[value=' + i + ']').removeAttr('disabled');
            }
            for(var i = day+1; i <= 31; i++) {
                scope.children('option[value=' + i + ']').attr('disabled','disabled');
            }
        }

        // FCCL-2156: Hide fields in registration form T23 when customer is callcenter
        if(isCallCenter) {
            //FCCL-2118 BangLy 12/8/2020 Mod-Start
            var password = $('input[name$="registration_reg__password"]');
            var passwordConfirm = $('input[name$="registration_reg__passwordconfirm"]');
            // init password value for default-value attr
            password.attr('default-value', password.val());
            passwordConfirm.attr('default-value', passwordConfirm.val());
            password.val(password.attr('default-value')).closest('div.accountForm_unit').css('display', 'none');
            passwordConfirm.val(passwordConfirm.attr('default-value')).closest('div.accountForm_unit').css('display', 'none');
            // Handle hide password fields if myCloudId contains no@fmworld.net
            $('input[name$="registration_mycloudid"]').closest('div.accountForm_unit').css('display', 'none');
            // Handle hide secret question and answer and set default answer 2000/1/1
            $('select[name$="registration_secretquestion"]').off().closest('div.accountForm_unit').css('display', 'none');
            $('input[name$="registration_secretquestionanswer__year"]').val(2000).closest('div.accountForm_unit').css('display', 'none');
            // Uncheck all checkbox
            $('.jsCheckOff').trigger('click');
            $('div.account_accordionBox .accountForm:nth-child(2)').css('display', 'none');
            $('div.account_accordionBox .accountForm:nth-child(3)').css('display', 'none');
            $('input[name="accept-terms"]').off();
            $('#register-button').removeClass('button-disable');
            //FCCL-2118 BangLy 12/8/2020 Mod-End
        }
        // FCCL-2118: Add handle code for b2b registration form
        if($('input[name$="registration_reg__companyName"]').length > 0 && $('input[name="isB2BCallCenter"]').val()) {
            var callcenter = JSON.parse($('input[name="isB2BCallCenter"]').val());
            if(callcenter) {
                // Hide 2 password fields if current user is call center
                $('input[name$="registration_reg__password"]').closest('div.accountForm_unit').css('display', 'none');
                $('input[name$="registration_reg__passwordconfirm"]').closest('div.accountForm_unit').css('display', 'none');
            }
        }
        
        // Function enable submit event when clicking enter button 
        $('#dwfrm_login_register').on('keydown', 'input', function (e) {
            if (e.which == 13) {
                // Click button submit
                e.preventDefault();
            }
        });

        // Function enable submit event when clicking enter button 
        $('#dwfrm_login').on('keydown', 'input', function (e) {
            if (e.which == 13) {
                // Click button submit
                $('#btnLogin').click();
            }
        });

        $('#btnLogin').click(function(e){
            // FCCL-3150 【F-131】ログイン画面でフォームのバリデーションがログインボタン押下時に動いてしまう
            $('div[id*="FFbe"]').remove();
            $('.jsAccAccordionBox').remove();
            $('.jsAccAccordionBtn').show();
            $('.jsAccAccordionBtn').next('button').hide();
            $('.jsAccAccordionBtn').next('img').hide();
            
            // FCCL-2522: Add loading and remove $("#dwfrm_login").submit() because btnLogin is submit type button
            $("#dwfrm_login").submit();
            loading.show();
        });

        /** END T23 */
        //toggle the value of the rememberme checkbox
        $('#dwfrm_login_rememberme').on('change', function () {
            if ($('#dwfrm_login_rememberme').attr('checked')) {
                $('#rememberme').val('true');
            } else {
                $('#rememberme').val('false');
            }
        });

        // Set autocomplete=new-password for disabling password's auto-fill and fixing FTRA bug FCCL-1860
        $("input[id$='registration_reg__password']").attr('autocomplete', 'new-password');

        $('#password-reset').on('click', function (e) {
            loading.show();
            e.preventDefault();
            dialog.open({
                url: $(e.target).attr('href'),
                type: "GET",
                options: {
                    open: function () {
                        validator.init();
                        var $requestPasswordForm = $('[name$="_requestpassword"]');
                        var $submit = $requestPasswordForm.find('[name$="_requestpassword_send"]');
                        // Add csrf token into request password form
                        page.getAndInsertCSRFToken($requestPasswordForm.attr('id'));
                        $($submit).on('click', function (e) {
                            if (!$requestPasswordForm.valid()) {
                                return;
                            }
                            e.preventDefault();
                            loading.show();
                            var data = $requestPasswordForm.serialize();
                            // add form action to data
                            data += '&' + $submit.attr('name') + '=';
                            // make sure the server knows this is an ajax request
                            if (data.indexOf('ajax') === -1) {
                                data += '&format=ajax';
                            }
                            $.ajax({
                                type: 'POST',
                                url: $requestPasswordForm.attr('action'),
                                data: data,
                                success: function (response) {
                                    if ((typeof response === 'object' &&
                                            !response.success &&
                                            response.error === Resources.CSRF_TOKEN_MISMATCH) || response.csrf_error) {
                                        // FCCL-2070: Redirect to csrf failed template if it has csrf error
                                        page.redirect(Urls.csrffailed);
                                    } else if (typeof response === 'string') {
                                        dialog.$container.html(response);
                                        // Add csrf token into request password form
                                        page.getAndInsertCSRFToken($requestPasswordForm.attr('id'));
                                    }
                                    loading.hide();
                                },
                                failure: function () {
                                    dialog.$container.html('<h1>' + Resources.SERVER_ERROR + '</h1>');
                                    loading.hide();
                                }
                            });
                        });
                        loading.hide();
                    }
                }
            });
        });

        // FCCL-1859: If param has register=true then open the registration form
        var paramArr = Array.prototype.concat.apply([], document.location.search.substr(1).split('&').map(function(x){
            return x.split('=');
        }));
        if(paramArr.length && paramArr.indexOf('register') != -1 && paramArr[paramArr.indexOf('register') + 1] === 'true') {
            // open accordion
            $('.jsAccAccordionBox').slideDown(function() {
                document.getElementById('anc1').scrollIntoView(true);
            });
            $('a.linkRegist').click();
        }
        if(paramArr.length && paramArr.indexOf('passwordremind') != -1 && paramArr[paramArr.indexOf('passwordremind') + 1] === 'true') {
            // open accordion
            $('#password-reset').click();
        }
        // End 1859

        // Start 201210 Add: FCCL-2331
        if ($curPostal.length > 0) {
            $('#register-button').on('click', function(e) {
                // FCCL-3115 【F-73】入力フォームのバリデーションの実装追加
                $('input').each(function(){
                    $(this).focus().blur();
                });

                var ev = page.showFormSubmissionErrors(e);
                var preventFlag = false;

                if (ev && ev.isDefaultPrevented()) {
                    preventFlag = true;
                }

                // Start 201210 Add: FCCL-2331
                if(postalErr.css('display') === styleDisplayShow || stateErr.css('display') === styleDisplayShow) {
                    window.scrollTo(0, $curPostal[0].offsetTop - topPadding);
                    preventFlag = true;
                }
                // End 201210 Add: FCCL-2331
                // Start 211101 Add: FCCL-2982
                if($('#div_err_city').css('display') === styleDisplayShow) {
                    window.scrollTo(0, $('#div_err_city')[0].offsetTop - topPadding);
                    preventFlag = true;
                }
                if($('#div_err_address1').css('display') === styleDisplayShow) {
                    window.scrollTo(0, $('#div_err_address1')[0].offsetTop - topPadding);
                    preventFlag = true;
                }

                if(preventFlag) {
                    e.preventDefault();
                    return;
                }
                // End 211101 Add: FCCL-2982
            });

            var timeCount = maxTimeCount;
            $('button.form_addsearch').on('click', function(e) {
                if (timeCount < maxTimeCount) {
                    return;
                }
                timeCount = 0;
                var cPostal = $curPostal.val();
                if (cPostal.length != maxPostalLength) {
                    postalErr.find('.err_tooltip_msg').text($('#postal_error_1').val());
                    showPostalErr();
                    e.preventDefault();
                    timeCount = maxTimeCount;
                    return;
                }

                if (cPostal == $oldPostalFtra.val()) {
                    $curState.val($oldStateFtra.val());
                    $curCity.val($oldCityFtra.val());
                    $curAddress1.val($oldAddress1Ftra.val());

                    $curState.trigger('change');
                    $curCity.trigger('change');
                    $curAddress1.trigger('change');

                    postalErr.hide();
                    stateErr.hide();

                    e.preventDefault();
                    timeCount = maxTimeCount;
                    return;
                }

                var curPostal = $curPostal.val();
                var curState = $curState.val();
                var curCity = $curCity.val();
                var curAddress1 = $curAddress1.val();

                postalErr.hide();
                stateErr.hide();

                // Start 201218 Add: FCCL-2393
                $defaultStateOption.text($('select[name$="_state"] option:selected').text());
                $defaultStateOption.prop('label', $('select[name$="_state"] option:selected').text());
                // End 201218 Add: FCCL-2393
                $curState.val('');
                loading.show();  // 210120 Add: FCCL-2485
                timeCount = 0;
                var myInv = setInterval(function() {
                    var val = $curState.val();
                    if (val && val != '') {
                        // Start 201218 Add: FCCL-2393
                        $defaultStateOption.text(defaultStateText);
                        $defaultStateOption.prop('label', defaultStateText);
                        // End 201218 Add: FCCL-2393
                        $oldPostalFtra.val($curPostal.val());
                        $oldStateFtra.val($curState.val());
                        $oldCityFtra.val($curCity.val());
                        $oldAddress1Ftra.val($curAddress1.val());
                        clearInterval(myInv);
                        loading.hide();  // 210120 Add: FCCL-2485
                        timeCount = maxTimeCount;
                        return;
                    }
                    timeCount = timeCount + 1;
                    if (timeCount >= maxTimeCount) {
                        // Start 201218 Add: FCCL-2393
                        $defaultStateOption.text(defaultStateText);
                        $defaultStateOption.prop('label', defaultStateText);
                        // End 201218 Add: FCCL-2393
                        postalErr.find('.err_tooltip_msg').text($('#postal_error_1').val());
                        showPostalErr();
                        $curPostal.val(curPostal);
                        $curState.val(curState);
                        $curCity.val(curCity);
                        $curAddress1.val(curAddress1);
                        clearInterval(myInv);
                        loading.hide();  // 210120 Add: FCCL-2485
                    }
                }, intervalTime);
            });

            $curPostal.on('focusin', function() {
                checkPostal();
            });

            $curPostal.on('focusout', function() {
                checkPostal();
            });

            $curState.on('focusin', function() {
                checkState();
            });

            $curState.on('focusout', function() {
                checkState();
            });

            $curState.on('change', function() {
                checkState();
            });
        }

        function checkPostal() {
            postalErr.hide();
            var old = $oldPostalFtra.val();
            if (old) {
                if (old != $curPostal.val() || $oldStateFtra.val() != $curState.val()) {
                    postalErr.find('.err_tooltip_msg').text($('#postal_error_2').val());
                    showPostalErr();
                }
            }
        }

        function checkState() {
            stateErr.hide();
            if ($oldPostalFtra.val()) {
                if ($oldStateFtra.val() != $curState.val()) {
                    showStateErr();
                }
            }
        }

        function showPostalErr() {
            var searchBtn = $('input[name$="_postalCode"]')[0];
            postalErr.css('display', styleDisplayShow);
            // FCCL-3131 【F-88】スマホのときの、フォームバリデーションのメッセージ表示位置が見切れる
            postalErr.css('left', searchBtn.offsetLeft);
            postalErr.css('top', searchBtn.offsetTop - searchBtn.offsetHeight);
        }

        function showStateErr() {
            var stateDiv = $('.form_select-prefectures')[0];
            stateErr.css('display', styleDisplayShow);
            stateErr.css('left', stateDiv.offsetLeft + stateDiv.offsetWidth + leftPadding);
            stateErr.css('top', stateDiv.offsetTop + topPadding);
        }

        // Start 211101 Add: FCCL-2982
        function checkInputError(str) {
            if (str.length == 0) return false;
            var whiteList = Resources.CHAR_WHITE_LIST;
            for (var i=0; i < str.length; i++) {
                var c = str.charAt(i);
                if (whiteList.indexOf(c) < 0) {
                    return true;
                }
            }
            return false;
        }
        function showCityErr() {
            var input = $('input[name$="_city"]')[0];
            var err = $('#div_err_city');
            if (checkInputError(input.value)) {
                err.css('display', styleDisplayShow);
                // FCCL-3131 【F-88】スマホのときの、フォームバリデーションのメッセージ表示位置が見切れる
                err.css('left', input.offsetLeft);
                err.css('top', input.offsetTop - input.offsetHeight);
            } else {
                err.hide();
            }
        }
        function showAddress1Err() {
            var input = $('input[name$="_address1"]')[0];
            var err = $('#div_err_address1');
            if (checkInputError(input.value)) {
                err.css('display', styleDisplayShow);
                // FCCL-3131 【F-88】スマホのときの、フォームバリデーションのメッセージ表示位置が見切れる
                err.css('left', input.offsetLeft);
                err.css('top', input.offsetTop - input.offsetHeight);
            } else {
                err.hide();
            }
        }
        $('input[name$="_city"]').on('input change focusout', function() {
            showCityErr();
        });
        $('input[name$="_address1"]').on('input change focusout', function() {
            showAddress1Err();
        });
        // End 211101 Add: FCCL-2982
        // End 201210 Add: FCCL-2331

        // FCCL-3069 login
        $('input[name$="_lastname"]').on('input change focusout', function() {
            showLastnameErr();
        });
        function showLastnameErr() {
            var input = $('input[name$="_lastname"]')[0];
            var err = $('#div_err_lastname');
            if (checkInputError(input.value)) {
                err.css('display', styleDisplayShow);
                // FCCL-3131 【F-88】スマホのときの、フォームバリデーションのメッセージ表示位置が見切れる
                err.css('left', input.offsetLeft);
                err.css('top', input.offsetTop - input.offsetHeight);
            } else {
                err.hide();
            }
        }
        $('input[name$="_firstname"]').on('input change focusout', function() {
            showFirstnameErr();
        });
        function showFirstnameErr() {
            var input = $('input[name$="_firstname"]')[0];
            var err = $('#div_err_firstname');
            if (checkInputError(input.value)) {
                err.css('display', styleDisplayShow);
                // FCCL-3131 【F-88】スマホのときの、フォームバリデーションのメッセージ表示位置が見切れる
                err.css('left', input.offsetLeft);
                err.css('top', input.offsetTop - input.offsetHeight);
            } else {
                err.hide();
            }
        }
        $('input[name$="_companyName"]').on('input change focusout', function() {
            showCompanyNameErr();
        });
        function showCompanyNameErr() {
            var input = $('input[name$="_companyName"]')[0];
            var err = $('#div_err_companyName');
            if (checkInputError(input.value)) {
                err.css('display', styleDisplayShow);
                // FCCL-3131 【F-88】スマホのときの、フォームバリデーションのメッセージ表示位置が見切れる
                err.css('left', input.offsetLeft);
                err.css('top', input.offsetTop - input.offsetHeight);
            } else {
                err.hide();
            }
        }
        $('input[name$="_departmentName"]').on('input change focusout', function() {
            showDepartmentNameErr();
        });
        function showDepartmentNameErr() {
            var input = $('input[name$="_departmentName"]')[0];
            var err = $('#div_err_departmentName');
            if (checkInputError(input.value)) {
                err.css('display', styleDisplayShow);
                // FCCL-3131 【F-88】スマホのときの、フォームバリデーションのメッセージ表示位置が見切れる
                err.css('left', input.offsetLeft);
                err.css('top', input.offsetTop - input.offsetHeight);
            } else {
                err.hide();
            }
        }
    }
}

module.exports = login;