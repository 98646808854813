'use strict';

var util = require('./util'),
    loading = require('./loading'),
    Promise = require('promise');

var page = {
    title: '',
    type: '',
    params: util.getQueryStringParams(window.location.search.substr(1)),
    redirect: function (newURL) {
        setTimeout(function () {
            window.location.href = newURL;
        }, 0);
    },
    redirectError: function (code) {
        var errUrl = Urls.errorGeneral;
        if (code == 401) {
            errUrl = Urls.errorForbidden;
        } else if (code == 404) {
            errUrl = Urls.errorNotFound;
        } else if (code == 502) {
            // 14/7/2020 BangLy-E02 Start
            errUrl = util.appendParamToURL(Urls.errorGeneral, "code", "502");
            // 14/7/2020 BangLy-E02 End
        }
        setTimeout(function () {
            window.location.href = errUrl;
        }, 0);
    },
    refresh: function () {
        setTimeout(function () {
            window.location.assign(window.location.href);
        }, 500);
    },
    showFormSubmissionErrors: function (event) {
        // Setting FTRA 
        // Hide popup FTRA
        $('#FFgb').css('display', 'none');
        if(event) {
            // FCCL-2729: Fix event validate ftra
            if ($('#FFgb').length && $('div#FFgb').children().children().text() === '') {
                return false;
            }
            var $form = $(event.target).parents().find('form').filter(function (){
                return $(this).find('button').is($(event.target));
            });
            var $scope = $form.find('input, select').filter(function() {
                return true;//this.style['background-color'] === 'rgb(255, 255, 255)';
            });
            if($scope.length == 0) {
                return event;
            }
            event.preventDefault()
            event.stopPropagation();
            // Show all errors at registration form FCCL-1576
            var focusInterval = setInterval(function() {
                $scope.each(function(){
                    $(this).focus();
                });
                $($scope.filter(function(){
                    return $(this).is(':text')
                })[0]).focus();
                clearInterval(focusInterval);
            }, 1);
            // End
            return event;
        }
        else {
            $(document).on('submit', 'form', function (e) {
                // FCCL-3134
                $('input').each(function(){
                    $(this).focus().blur();
                });
                var returnFlag = false
                $('div[id*="FFbe"]').each(function() {
                    if ($(this).css('display') === 'block') {
                        window.scrollTo(0, $(this)[0].offsetTop - 5);
                        returnFlag = true;
                        return;
                    }
                });
                if(returnFlag) {
                    e.preventDefault();
                    e.stopPropagation();
                    return true;
                }
                
                if ($('#FFgb').length && $('div#FFgb').children().children().text() === '') {
                    return;
                }
                // FCCL-2371: add condition when using search at ftra screen
                if(this.id == 'dwfrm_login' || this.id == 'dwfrm_requestpassword' || this.name == 'simpleSearch') {
                    return;
                }
                if($('div#FFgb').children().children().text() > 0) {
                    var $scope = $(this).find('input, select').filter(function() {
                        return true;//this.style['background-color'] === 'rgb(255, 255, 255)';
                    });
                    if($scope.length == 0) {
                        return;
                    }
                    e.preventDefault()
                    e.stopPropagation();
                    // Show all errors at registration form FCCL-1576
                    var focusInterval = setInterval(function() {
                        $scope.each(function(){
                            $(this).focus();
                        });
                        $($scope.filter(function(){
                            return $(this).is(':text')
                        })[0]).focus();
                        clearInterval(focusInterval);
                    }, 1);
                    // End
                    return true;
                }
            });
            return false;
        }
    },
    //FCCL-2070: Add code for inserting csrf input in forms
    /**
     * Call ajax and add csrf input into form
     * @param {String} formid Specific form id to add csrf input, if formid is null, the function will add to all post and get method forms
     */
    getAndInsertCSRFToken: function(formid){
        var formIds = []; // create new array contains id of form with post method
        if(!formid) {
            if($('form[method="post"]').length > 0) {
                $('form[method="post"]').each(function(index, value){
                    formIds.push(value.id); // push form id into array
                });
            }
        } else {
            formIds = [formid];
        }
        var page = this;
        // Set length formIds for counter
        var counter = formIds.length;
        if(counter > 0) {
            loading.show();
            //Loop through all forms
            for (var i = 0; i < formIds.length; i++) {
                const id = formIds[i];
                // Call ajax
                $.ajax({
                    url: Urls.getCSRFToken,
                    context: document.body,
                    dataType: 'json',
                    success: function(data, status){
                        // If success, decrease counter
                        counter--;
                        page.insertCSRFForm(data, id);
                        // Check if counter is 0 (no ajax waiting remains) then hide loading
                        if(counter == 0) {
                            loading.hide();
                        }
                    },
                    error: function(xhr, status, error){
                        //TODO: CSRF generate error alert('error' + error);
                        loading.hide();
                        alert('Get CSRF token error: ' + error);
                    }
                })
            }
        }
    },
    /**
     * Add csrf input hidden to form
     * @param {Object} csrfjson Object contains csrf key and value to add
     * @param {String} formid Form id to add input csrf
     */
    insertCSRFForm: function(csrfjson, formid){
        var $form = $('form[id="' + formid + '"]');
        // Check if current form already has csrf input
        if($form.find('input[name="' + csrfjson.csrf_token_name + '"]').length > 0) {
            return;
        }
        var inputElement = '<input type="hidden" name="' + csrfjson.csrf_token_name + '" value="' + csrfjson.csrf_token_value + '" />';
        $form.append(inputElement);
    }
};

module.exports = page;
