'use strict';

var ajax = require('../../ajax'),
    formPrepare = require('./formPrepare'),
    progress = require('../../progress'),
    tooltip = require('../../tooltip'),
    util = require('../../util'),
    loading = require('../../loading'),
    page = require('../../page'),
    modal = require('../../modal');

var shippingMethods;
/**
 * @function
 * @description Initializes gift message box, if shipment is gift
 */
function giftMessageBox() {
    // show gift message box, if shipment is gift
    $('.gift-message-text').toggleClass('hidden', $('input[name$="_shippingAddress_isGift"]:checked').val() !== 'true');
}

/**
 * @function
 * @description updates the order summary based on a possibly recalculated basket after a shipping promotion has been applied
 */
function updateSummary() {
    var $summary = $('#secondary.summary');
    // indicate progress
    progress.show($summary);

    // load the updated summary area
    $summary.load(Urls.summaryRefreshURL, function () {
        // hide edit shipping method link
        $summary.fadeIn('fast');
        $summary.find('.checkout-mini-cart .minishipment .header a').hide();
        $summary.find('.order-totals-table .order-shipping .label a').hide();
    });
}

/**
 * @function
 * @description Helper method which constructs a URL for an AJAX request using the
 * entered address information as URL request parameters.
 */
function getShippingMethodURL(url, extraParams) {
    var $form = $('.address');
    var params = {
        address1: $form.find('input[name$="_address1"]').val(),
        address2: $form.find('input[name$="_address2"]').val(),
        countryCode: $form.find('select[id$="_country"]').val(),
        stateCode: $form.find('select[id$="_state"]').val(),
        postalCode: $form.find('input[name$="_postal"]').val(),
        city: $form.find('input[name$="_city"]').val()
    };
    return util.appendParamsToUrl(url, $.extend(params, extraParams));
}

/**
 * @function
 * @description selects a shipping method for the default shipment and updates the summary section on the right hand side
 * @param
 */
function selectShippingMethod(shippingMethodID) {
    // nothing entered
    if (!shippingMethodID) {
        return;
    }
    // attempt to set shipping method
    var url = getShippingMethodURL(Urls.selectShippingMethodsList, {shippingMethodID: shippingMethodID});
    ajax.getJson({
        url: url,
        callback: function (data) {
            updateSummary();
            if (!data || !data.shippingMethodID) {
                window.alert('Couldn\'t select shipping method.');
                return false;
            }
            // display promotion in UI and update the summary section,
            // if some promotions were applied
            $('.shippingpromotions').empty();


            // if (data.shippingPriceAdjustments && data.shippingPriceAdjustments.length > 0) {
            //     var len = data.shippingPriceAdjustments.length;
            //     for (var i=0; i < len; i++) {
            //         var spa = data.shippingPriceAdjustments[i];
            //     }
            // }
        }
    });
}

/**
 * @function
 * @description Make an AJAX request to the server to retrieve the list of applicable shipping methods
 * based on the merchandise in the cart and the currently entered shipping address
 * (the address may be only partially entered).  If the list of applicable shipping methods
 * has changed because new address information has been entered, then issue another AJAX
 * request which updates the currently selected shipping method (if needed) and also updates
 * the UI.
 */
function updateShippingMethodList() {
    var $shippingMethodList = $('#shipping-method-list');
    if (!$shippingMethodList || $shippingMethodList.length === 0) { return; }
    var url = getShippingMethodURL(Urls.shippingMethodsJSON);

    ajax.getJson({
        url: url,
        callback: function (data) {
            if (!data) {
                window.alert('Couldn\'t get list of applicable shipping methods.');
                return false;
            }
            if (shippingMethods && shippingMethods.toString() === data.toString()) {
                // No need to update the UI.  The list has not changed.
                return true;
            }

            // We need to update the UI.  The list has changed.
            // Cache the array of returned shipping methods.
            shippingMethods = data;
            // indicate progress
            progress.show($shippingMethodList);

            // load the shipping method form
            var smlUrl = getShippingMethodURL(Urls.shippingMethodsList);
            $shippingMethodList.load(smlUrl, function () {
                $shippingMethodList.fadeIn('fast');
                // rebind the radio buttons onclick function to a handler.
                $shippingMethodList.find('[name$="_shippingMethodID"]').click(function () {
                    selectShippingMethod($(this).val());
                });

                // update the summary
                updateSummary();
                progress.hide();
                tooltip.init();
                //if nothing is selected in the shipping methods select the first one
                if ($shippingMethodList.find('.input-radio:checked').length === 0) {
                    $shippingMethodList.find('.input-radio:eq(0)').prop('checked', 'checked');
                }
            });
        }
    });
}

exports.init = function () {
    // Start 201210 Add: FCCL-2331
    var postalErr = $('#div_err_check_postal');
    var stateErr = $('#div_err_change_state');
    var maxPostalLength = 7;
    var maxTimeCount = 30;  // 210120 Mod: FCCL-2485
    var intervalTime = 100;
    var topPadding = 5;
    var leftPadding = 15;
    var styleDisplayShow = 'block';
    var $oldPostalFtra = $('#old_postal');
    var $oldStateFtra = $('#old_state');
    var $oldCityFtra = $('#old_city');
    var $oldAddress1Ftra = $('#old_address1');
    var $curState = $('select[name$="_state"]');
    var $curPostal = $('input[name$="_postal"]');
    var $curCity = $('input[name$="_city"]');
    var $curAddress1 = $('input[name$="_address1"]');
    // Start 201218 Add: FCCL-2393
    var $defaultStateOption = $('select[name$="_state"] option[value=""]');
    var defaultStateText = $defaultStateOption.text();
    // End 201218 Add: FCCL-2393
    // End 201210 Add: FCCL-2331
    // FCCL-2445: Get all other contact input fields
    var contactInputs = $('input[name*=shippingaddress_phone2]');
    const OFFSET_TOP_UPPER = 34;
    var otherContactErr = $('[id*=div_err_other_contacts]');
    var functOtherContactErr = function () {
        return otherContactErr.filter(function () {
            return $(this).css('display') === styleDisplayShow;
        });
    }
    

    formPrepare.init({
        continueSelector: '[name$="shippingAddress_save"]',
        formSelector:'[id$="singleshipping_shippingAddress"]'
    });
    $('input[name$="_shippingAddress_isGift"]').on('click', giftMessageBox);
    $('.form_attention').hide();
    $('.form_attention').removeAttr("submit-event");

    $('.address').on('change',
        'input[name$="_addressFields_address1"], input[name$="_addressFields_address2"], select[name$="_addressFields_states_state"], input[name$="_addressFields_city"], input[name$="_addressFields_zip"]',
        updateShippingMethodList
    );

    giftMessageBox();
    updateShippingMethodList();
    page.showFormSubmissionErrors();

    var txtAttentionDefault = $('#attention_default').val();
    function checkDeliveryStatus() {
        enableSubmitButton(true);
    }
    function enableSubmitButton(enable) {
        if (enable) {
            $('#btn_next').removeAttr("disabled");
            $('#btn_next').css('background-color', '#C00');
        } else {
            $('#btn_next').attr("disabled", true);
            $('#btn_next').css('background-color', '#606A76');
        }
    }

    // FCCL-2212 add errMsg init on weekday change
    $('input.weekday').on('change',function(){
        $('.form_attention').text('');
        $('.form_attention').hide();
        $('.form_attention').removeAttr("submit-event");
        checkDeliveryStatus();
    });

    // FCCL-2212 add errMsg init on holiday change
    $('input.holiday').on('change',function(){
        $('.form_attention').text('');
        $('.form_attention').hide();
        $('.form_attention').removeAttr("submit-event");
        checkDeliveryStatus();
    });

    $('input[name$="_shippingTime"]').on('change',function(){
        $('.form_attention').text('');
        $('.form_attention').hide();
        $('.form_attention').removeAttr("submit-event");
        checkDeliveryStatus();
    });

    $('input[name$="_postal"]').on('keyup',function(){
        $('.form_attention').text('');
        $('.form_attention').hide();
        $('.form_attention').removeAttr("submit-event");
        checkDeliveryStatus();
    });

    $('input[id$="shippingaddress_isSetMethod"]').on('change', function(){
        if($('input[id$="shippingaddress_isSetMethod"]:checked').val() == 1) {
            // Set click event for default shippingTime 'cause FTRA doesn't regconize prop event
            $($('input[id$="shippingaddress_shippingTime"]')[0]).click();
        }
    });

    $('.js-form-specify input').on('change',function(){
        if($(this).val() == '1'){
            $('.form_attention').text('');
            $('.form_attention').hide();
            $('.form_attention').removeAttr("submit-event");
            $('.js-form-specifyBox').show();
        } else {
            $('.js-form-specifyBox').hide();
            $('.js-form-specifyBox input[type="checkbox"], .js-form-specifyBox input[type="radio"]').prop('checked', '');
        }
        checkDeliveryStatus();
    });

    $('.js-form-specify input:checked').each(function(e){
        if($(this).val() == '1'){
            $('.js-form-specifyBox').show();
        } else {
            $('.js-form-specifyBox').hide();
            $('.js-form-specifyBox input[type="checkbox"], .js-form-specifyBox input[type="radio"]').prop('checked', '');
        }
        checkDeliveryStatus();
    });

    $('button.form_button-check').on('click', function (e) {
        e.preventDefault();
        loading.show();
        var postal = $('input[name$="_postal"]').val() || null;
        var time = $('input[name$="_shippingTime"]:checked').val() || null;
        var weekday = false;
        // 曜日指定がある場合、weekdayをtrueに設定する。
        if ($('input[name$="_isMonday"]:checked').val()
            || $('input[name$="_isTuesday"]:checked').val()
            || $('input[name$="_isWednesday"]:checked').val()
            || $('input[name$="_isThursday"]:checked').val()
            || $('input[name$="_isFriday"]:checked').val()
            || $('input[name$="_isSaturday"]:checked').val()
            || $('input[name$="_isSunday"]:checked').val()) {
            weekday = true;
        }
        var url = util.appendParamsToUrl($(this).attr('url'), {
            'postal' : postal,
            'time' : time,
            'weekday' : weekday
        });
        $.getJSON(url, function (data) {
            var idError = '#err' + (Number($('input[name$="_isSetMethod"]:checked').val()) + 1);
            var idErrorWeekday = '#err' + (Number($('input[name$="_isSetMethod"]:checked').val()) + 2);
            if (data.error) {
                if (data.weekdayErr == true) {
                    $(idErrorWeekday).text(data.error);
                    $(idErrorWeekday).css('display', 'block');
                    $(idErrorWeekday).attr("deliverytime-error", true);
                    $(idErrorWeekday).removeAttr("submit-event");
                } else {
                    $(idError).text(data.error);
                    $(idError).css('display', 'block');
                    $(idError).attr("deliverytime-error", true);
                    $(idError).removeAttr("submit-event");
                }
            } else {
                $(idError).removeAttr("deliverytime-error");
                $(idErrorWeekday).removeAttr("deliverytime-error");
                if ($(idError).attr("submit-event") == "true") {
                    // Call click event for form submit
                    $('#btn_next').click();
                } else {
                    $(idError).text(data.success);
                    $(idError).css('display', 'block');
                }
            }

            checkDeliveryStatus();
            loading.hide();
        });
    });

    // FCCL-3039
    var addressValidation = false;
    var addressValidationValue = $('input[name="addressValidation"]').val();
    if (addressValidationValue != undefined) {
        addressValidation = addressValidationValue.toLowerCase() === 'true' ? true : false;
    }

    // FCCL-3177 配送情報ページの、「その連絡先」項目で、キーボードのエンターキーを押下すると項目チェックがスルーしてしまう
    $(function(){
        $("input").keypress(function(e){
            if(e.which == 13){
                $('#btn_next').click();
                return false;
            }
        });

        // FCCL-3190 【F-141】配送先、注文者の住所情報で番地の入力がないときにアラーム表示したい
        $('input[name$="shippingaddress_city"], input[name$="shippingaddress_address1"]').focusout(function (e) {
            var val = $(this).val();
            val = val.replace(/(ー|➖|〰|-|−|〜|~|―|‐|ー|-|−|ｰ)/g, "ー");
            val = val.replace(/[A-Za-z0-9!#\$%&~\|\^\@\`;:=\-\+\*\?\/_\,\.\(\)<>\[\]\{\}\\]/g,function(s){
                return String.fromCharCode(s.charCodeAt(0)+0xFEE0);
            });
            val = val.replace(/"/g,"”");
            val = val.replace(/'/g,"’");
            val = val.replace(/\s/g,"　");
            $(this).val(val);
        })
        
        $('input[name$="shippingaddress_city"], input[name$="shippingaddress_address1"]').focusout(function (e) {
            var city = $('input[name$="shippingaddress_city"]').val();
            var address1 = $('input[name$="shippingaddress_address1"]').val();
            var value = city + address1;
            if (city.length == 0 ||  city.length > 26) {
                $('#div_err_address1_post').hide();
                return;
            }
            var ret = false;
            var preAry = ["丁目","番地"];//○丁目、○番地
            var rearAry = ["－","‐"];//○－、○-、－○、-○
            var numeric = "０１２３４５６７８９〇一二三四五六七八九十";
            var notAddress = ["番外地","未番地","無番地","無地番地"];
            var arabicNumeric = ["０","１","２","３","４","５","６","７","８","９"]     
            //アラビア数字が含まれていればOK
            $.each(arabicNumeric, function(i,val){
                if(value.indexOf(val) != -1){
                    ret = true;
                    return false;//break;
                }
            });
            if(!ret){
                //「丁目」、「番地」の前に漢数字orアラビア数字がない入力はNG
                $.each(preAry, function(i,val){
                    var idx = value.indexOf(val);
                    if(idx > 0){//idx==0となる「丁目」、「番地」という入力は認めない。
                        var num = value.substring(idx-1,idx);
                        if(numeric.indexOf(num) != -1){
                            ret = true;
                            return false;//break;
                        }
                    }
                });
            }
            if(!ret){
                //○－、○-、－○、-○の場合OK（○・・・漢数字、アラビア数字）
                $.each(rearAry, function(i,val){//○－、○-、－○、-○
                    var idx = value.indexOf(val);
                    if(idx != -1){
                        var rearNum = value.substring(idx+1,idx+2);
                        var preNum = value.substring(idx-1,idx);
                        if((rearNum != "" && numeric.indexOf(rearNum) != -1) || 
                            (preNum != "" && numeric.indexOf(preNum) != -1)){
                            ret = true;
                            return false;//break;
                        }
                    }
                });
            }
            if(!ret){
                //番外地、未番地、無番地、無地番地が含まれていればOK
                $.each(notAddress, function(i,val){
                    if(value.indexOf(val) != -1){
                        ret = true;
                        return false;//break;
                    }
                });
            }

            var input = $('input[name$="shippingaddress_city"]')[0];
            var err = $('#div_err_address1_post');
            if (!ret) {
                err.css('display', styleDisplayShow);
                err.css('left', input.offsetLeft);
                err.css('top', input.offsetTop - input.offsetHeight);
            } else {
                err.hide();
            }
        })
    });

    $('#btn_next').on('click', function (e) {
        // FCCL-2485 add
        loading.show();

        // FCCL-3115 【F-73】入力フォームのバリデーションの実装追加
        $('input').each(function(){
            $(this).focus().blur();
        });
        
        var ev = page.showFormSubmissionErrors(e);
        if(ev && ev.isDefaultPrevented()) {
            e.preventDefault();
            var top = 0;
            $('div[id*="FFbe"],div[id*="div_err_check_postal"],div[id*="div_err_change_state"],div[id*="div_err_city"],div[id*="div_err_address1"]').each(function() {
                var display = $(this).css('display');
                if (display != 'none' && (top == 0 || top > $(this).position().top)) {
                    top = $(this).position().top;
                }
            });
            $(':focus').blur();
            window.scrollTo(0, top - topPadding);
            loading.hide();
            return;
        }
        var returnFlag = false; //FCCL-2445: Add return flag for checking and showing all errors
        // Start 201210 Add: FCCL-2331
        if(postalErr.css('display') === styleDisplayShow || stateErr.css('display') === styleDisplayShow) {
            window.scrollTo(0, $curPostal[0].offsetTop - topPadding);
            returnFlag = true;
        }
        // FCCL-2445: check if error popups are displayed and focus in first contact input
        // Start 211101 Add: FCCL-2982
        if($('#div_err_city').css('display') === styleDisplayShow) {
            window.scrollTo(0, $('#div_err_city')[0].offsetTop - topPadding);
            returnFlag = true;
        // FCCL-3190 【F-141】配送先、注文者の住所情報で番地の入力がないときにアラーム表示したい
        } else if ($('#div_err_address1_post').css('display') === styleDisplayShow) {
            window.scrollTo(0, $('#div_err_address1_post')[0].offsetTop - topPadding);
            returnFlag = true;
        }
        if($('#div_err_address1').css('display') === styleDisplayShow) {
            window.scrollTo(0, $('#div_err_address1')[0].offsetTop - topPadding);
            returnFlag = true;
        }
        // FCCL-3115 【F-73】入力フォームのバリデーションの実装追加
        if($('#div_err_companyName').css('display') === styleDisplayShow) {
            window.scrollTo(0, $('#div_err_companyName')[0].offsetTop - topPadding);
            returnFlag = true;
        }
        if($('#div_err_departmentName').css('display') === styleDisplayShow) {
            window.scrollTo(0, $('#div_err_departmentName')[0].offsetTop - topPadding);
            returnFlag = true;
        }
        if($('#div_err_lastName').css('display') === styleDisplayShow) {
            window.scrollTo(0, $('#div_err_lastName')[0].offsetTop - topPadding);
            returnFlag = true;
        }
        if($('#div_err_firstName').css('display') === styleDisplayShow) {
            window.scrollTo(0, $('#div_err_firstName')[0].offsetTop - topPadding);
            returnFlag = true;
        }
        // End 211101 Add: FCCL-2982
        if(functOtherContactErr().length > 0) {
            contactInputs[0].focus();
            returnFlag = true;
        }
        // FCCL-3131 bug fix
        $('div[id*="FFbe"]').each(function() {
            if ($(this).css('display') === styleDisplayShow) {
                window.scrollTo(0, $(this)[0].offsetTop - topPadding);
                returnFlag = true;
                return;
            }
        });
        if(returnFlag) {
            e.preventDefault();
            loading.hide();
            return;
        }
        // End 201210 Add: FCCL-2331

        // Start 210106 Add: FCCL-2442
        var curStateVal = $curState.val();
        var curCityVal = $curCity.val();
        var curAddress1Val = $curAddress1.val();
        // FCCL-2485 add
        var postal = $('input[name$="_postal"]').val() || null;
        // FCCL-3039
        if (addressValidation) {
            $.ajax({
                url: 'https://console.f-tra.com/zip/' + postal + '.js?encode=utf-8',
                dataType: "text"
            })
            .done(function(data, textStatus, jqXHR) {
                try {
                    if (jqXHR.status == 200) {
                        var stateComparison = data.indexOf('"0":"' + curStateVal + '"') >= 0 ? false : true;
                        $curState.val(curStateVal);
                        $curCity.val(curCityVal);
                        $curAddress1.val(curAddress1Val);
                        checkOtherContact(contactInputs); // FCCL-2445: check other contact when in interval
                        if(postalErr.css('display') === styleDisplayShow || stateErr.css('display') === styleDisplayShow) {
                            window.scrollTo(0, $curPostal[0].offsetTop - topPadding);
                            returnFlag = true
                        } else if (stateComparison) {
        
                            postalErr.find('.err_tooltip_msg').text($('#postal_error_2').val());
                            showPostalErr();
                            showStateErr();
        
                            window.scrollTo(0, $curPostal[0].offsetTop - topPadding);
                            returnFlag = true
                        }
                        // End 210106 Add: FCCL-2442
                        if(functOtherContactErr().length > 0) {
                            returnFlag = true;
                        }
                        if(returnFlag) {
                            e.preventDefault();
                            loading.hide();
                            return;
                        }
                        // FCCL-2873 エラーメッセージ表示時に払い方法の選択画面へ進めないように修正
                        var errMsg = $('p[deliverytime-error="true"]');
                        var errMsgSize = 0;
                        errMsg.each(function() {
                            if ($(this).css('display') != 'none') {
                                errMsgSize++;
                            }
                        })
                        if (errMsgSize > 0) {
                            e.preventDefault();
                            loading.hide();
                        } else {
                            errMsg.each(function() {
                                $(this).removeAttr('submit-event');
                            })
                            $('button[name$="_shippingAddress_save"]').click();
                        }
                    } else {
                        $('button.form_addsearch').trigger('click');
                        loading.hide();
                    }
                } catch (error) {
                    $('button.form_addsearch').trigger('click');
                    loading.hide();
                }
            })
            .fail(function() {
                $('button.form_addsearch').trigger('click');
                loading.hide();
            })
        } else {
            var errMsg = $('p[deliverytime-error="true"]');
            var errMsgSize = 0;
            errMsg.each(function() {
                if ($(this).css('display') != 'none') {
                    errMsgSize++;
                }
            })
            if (errMsgSize > 0) {
                e.preventDefault();
                loading.hide();
            } else {
                errMsg.each(function() {
                    $(this).removeAttr('submit-event');
                })
                $('button[name$="_shippingAddress_save"]').click();
            }
        }
    });

    if ($('#error_msg').val() != undefined && $('#error_msg').val() != 'null' && $('#error_msg').val() != '') {
        modal.dialog($('#error_msg').val(), function() {
            //@TODO
        });
    }

    // Start 201210 Add: FCCL-2331
    var timeCount = maxTimeCount;
    $('button.form_addsearch').on('click', function(e) {
        if (timeCount < maxTimeCount) {
            return;
        }
        timeCount = 0;
        var cPostal = $curPostal.val();
        if (cPostal.length != maxPostalLength) {
            postalErr.find('.err_tooltip_msg').text($('#postal_error_1').val());
            showPostalErr();
            e.preventDefault();
            timeCount = maxTimeCount;
            return;
        }

        if (cPostal == $oldPostalFtra.val()) {
            $curState.val($oldStateFtra.val());
            $curCity.val($oldCityFtra.val());
            $curAddress1.val($oldAddress1Ftra.val());

            $curState.trigger('change');
            $curCity.trigger('change');
            $curAddress1.trigger('change');

            postalErr.hide();
            stateErr.hide();

            e.preventDefault();
            timeCount = maxTimeCount;
            return;
        }

        var curPostal = $curPostal.val();
        var curState = $curState.val();
        var curCity = $curCity.val();
        var curAddress1 = $curAddress1.val();

        postalErr.hide();
        stateErr.hide();

        // Start 201218 Add: FCCL-2393
        $defaultStateOption.text($('select[name$="_state"] option:selected').text());
        $defaultStateOption.prop('label', $('select[name$="_state"] option:selected').text());
        // End 201218 Add: FCCL-2393
        $curState.val('');
        loading.show();  // 210120 Add: FCCL-2485
        var myInv = setInterval(function() {
            var val = $curState.val();
            if (val && val != '') {
                // Start 201218 Add: FCCL-2393
                $defaultStateOption.text(defaultStateText);
                $defaultStateOption.prop('label', defaultStateText);
                // End 201218 Add: FCCL-2393
                $oldPostalFtra.val($curPostal.val());
                $oldStateFtra.val($curState.val());
                $oldCityFtra.val($curCity.val());
                $oldAddress1Ftra.val($curAddress1.val());
                clearInterval(myInv);
                loading.hide();  // 210120 Add: FCCL-2485
                timeCount = maxTimeCount;
                return;
            }
            timeCount = timeCount + 1;
            if (timeCount >= maxTimeCount) {
                // Start 201218 Add: FCCL-2393
                $defaultStateOption.text(defaultStateText);
                $defaultStateOption.prop('label', defaultStateText);
                // End 201218 Add: FCCL-2393
                postalErr.find('.err_tooltip_msg').text($('#postal_error_1').val());
                showPostalErr();
                $curPostal.val(curPostal);
                $curState.val(curState);
                $curCity.val(curCity);
                $curAddress1.val(curAddress1);
                clearInterval(myInv);
                loading.hide();  // 210120 Add: FCCL-2485
            }
        }, intervalTime);
    });

    $curPostal.on('focusin', function() {
        checkPostal();
    });

    $curPostal.on('focusout', function() {
        checkPostal();
    });

    $curState.on('focusin', function() {
        checkState();
    });

    $curState.on('focusout', function() {
        checkState();
    });

    $curState.on('change', function() {
        checkState();
    });

    // FCCL-2445: Set events of each fields
    contactInputs.each(function() {
        $(this).off().on('focusout', function() {
            checkOtherContact(contactInputs);
        });
    });

    function checkOtherContact(contactInputs) {
        contactInputs.each(function(index, element) {
            if($(this).val() !== '') {
                showContactErr(contactInputs, index);
                return false;
            }
            else {
                hideContactErr(index);
            }
        });
    }
    
    
    function showContactErr(contactInputs, errIndex) {
        contactInputs.each(function(index, item) {
            if(index === errIndex || $(this).val() != '') {
                hideContactErr(index);
                return;
            }
            var contactErr = $(otherContactErr[index]);
            contactErr.css('display', styleDisplayShow);
            contactErr.css('left', $(item).offset().left);
            contactErr.css('top', $(item).offset().top - OFFSET_TOP_UPPER);
        });
        
    }
    function hideContactErr(errIndex) {
        $(otherContactErr[errIndex]).hide();
    }

    function checkPostal() {
        postalErr.hide();
        var old = $oldPostalFtra.val();
        if (old) {
            if (old != $curPostal.val() || $oldStateFtra.val() != $curState.val()) {
                postalErr.find('.err_tooltip_msg').text($('#postal_error_2').val());
                showPostalErr();
            }
        }
    }

    function checkState() {
        stateErr.hide();
        if ($oldPostalFtra.val()) {
            if ($oldStateFtra.val() != $curState.val()) {
                showStateErr();
            }
        }
    }

    function showPostalErr() {
        // FCCL-3039
        if (addressValidation) {
            var searchBtn = $('input[name$="_postal"]')[0];
            postalErr.css('display', styleDisplayShow);
            // FCCL-3131 【F-88】スマホのときの、フォームバリデーションのメッセージ表示位置が見切れる
            postalErr.css('left', searchBtn.offsetLeft);
            postalErr.css('top', searchBtn.offsetTop - searchBtn.offsetHeight);
        }
    }

    function showStateErr() {
        // FCCL-3039
        if (addressValidation) {
            var stateDiv = $('.form_select-prefectures')[0];
            stateErr.css('display', styleDisplayShow);
            stateErr.css('left', stateDiv.offsetLeft + stateDiv.offsetWidth + leftPadding);
            stateErr.css('top', stateDiv.offsetTop + topPadding);
        }        
    }

    // Start 211101 Add: FCCL-2982
    function checkInputError(str) {
        if (str.length == 0) return false;
        var whiteList = Resources.CHAR_WHITE_LIST;
        for (var i=0; i < str.length; i++) {
            var c = str.charAt(i);
            if (whiteList.indexOf(c) < 0) {
                return true;
            }
        }
        return false;
    }
    function showCityErr() {
        var input = $('input[name$="_city"]')[0];
        var err = $('#div_err_city');
        if (checkInputError(input.value)) {
            err.css('display', styleDisplayShow);
            // FCCL-3131 【F-88】スマホのときの、フォームバリデーションのメッセージ表示位置が見切れる
            err.css('left', input.offsetLeft);
            err.css('top', input.offsetTop - input.offsetHeight);
        } else {
            err.hide();
        }
    }
    function showAddress1Err() {
        var input = $('input[name$="_address1"]')[0];
        var err = $('#div_err_address1');
        if (checkInputError(input.value)) {
            err.css('display', styleDisplayShow);
            // FCCL-3131 【F-88】スマホのときの、フォームバリデーションのメッセージ表示位置が見切れる
            err.css('left', input.offsetLeft);
            err.css('top', input.offsetTop - input.offsetHeight);
        } else {
            err.hide();
        }
    }
    $('input[name$="_city"]').on('input change focusout', function() {
        showCityErr();
    });
    $('input[name$="_address1"]').on('input change focusout', function() {
        showAddress1Err();
    });
    // End 211101 Add: FCCL-2982
    // End 201210 Add: FCCL-2331

    // FCCL-3069 shipping
    $('input[name$="_lastName"]').on('input change focusout', function() {
        showLastnameErr();
    });
    function showLastnameErr() {
        var input = $('input[name$="_lastName"]')[0];
        var err = $('#div_err_lastName');
        if (checkInputError(input.value)) {
            err.css('display', styleDisplayShow);
            // FCCL-3131 【F-88】スマホのときの、フォームバリデーションのメッセージ表示位置が見切れる
            err.css('left', input.offsetLeft);
            err.css('top', input.offsetTop - input.offsetHeight);
        } else {
            err.hide();
        }
    }
    $('input[name$="_firstName"]').on('input change focusout', function() {
        showFirstnameErr();
    });
    function showFirstnameErr() {
        var input = $('input[name$="_firstName"]')[0];
        var err = $('#div_err_firstName');
        if (checkInputError(input.value)) {
            err.css('display', styleDisplayShow);
            // FCCL-3131 【F-88】スマホのときの、フォームバリデーションのメッセージ表示位置が見切れる
            err.css('left', input.offsetLeft);
            err.css('top', input.offsetTop - input.offsetHeight);
        } else {
            err.hide();
        }
    }
    $('input[name$="_companyName"]').on('input change focusout', function() {
        showCompanyNameErr();
    });
    function showCompanyNameErr() {
        var input = $('input[name$="_companyName"]')[0];
        var err = $('#div_err_companyName');
        if (checkInputError(input.value)) {
            err.css('display', styleDisplayShow);
            // FCCL-3131 【F-88】スマホのときの、フォームバリデーションのメッセージ表示位置が見切れる
            err.css('left', input.offsetLeft);
            err.css('top', input.offsetTop - input.offsetHeight);
        } else {
            err.hide();
        }
    }
    $('input[name$="_departmentName"]').on('input change focusout', function() {
        showDepartmentNameErr();
    });
    function showDepartmentNameErr() {
        var input = $('input[name$="_departmentName"]')[0];
        var err = $('#div_err_departmentName');
        if (checkInputError(input.value)) {
            err.css('display', styleDisplayShow);
            // FCCL-3131 【F-88】スマホのときの、フォームバリデーションのメッセージ表示位置が見切れる
            err.css('left', input.offsetLeft);
            err.css('top', input.offsetTop - input.offsetHeight);
        } else {
            err.hide();
        }
    }

    // Start 210114 Add: FCCL-2439
    var KEY_ENTER = 13;
    var KEY_SPACE = 32;
    $('span.form_checkbox').on('keypress', function(e) {
        if (e.keyCode == KEY_SPACE || e.keyCode == KEY_ENTER) {
            e.preventDefault();
            $(this).find('input')[0].checked = $(this).find('input')[0].checked != true;
        }
    });

    $('span.form_radio').on('keypress', function(e) {
        if (e.keyCode == KEY_SPACE || e.keyCode == KEY_ENTER) {
            e.preventDefault();
            $(this).find('input')[0].checked = true;
            $(this).find('input').trigger('change');
        }
    });
    // End 210114 Add: FCCL-2439
};

exports.updateShippingMethodList = updateShippingMethodList;