'use strict';

var progress = require('./progress'),
    page = require('./page'),
    loading = require('./loading'),
    util = require('./util');

var currentRequests = [];
var xhrStatusCode = {
    400: function() {
        console.log('400 status code! user error');
        page.redirect(Urls.errorGeneral);
    },
    401: function() {
        console.log('401 status code!');
        page.redirect(Urls.errorForbidden);
    },
    404: function() {
        console.log('404 status code!');
        page.redirect(Urls.errorNotFound);
    },
    500: function() {
        console.log('500 status code! server error');
        page.redirect(util.appendParamToURL(Urls.errorGeneral, 'code', 500));  //210311 Mod: FCCL-2642
    }
};
/**
 * @function
 * @description Ajax request to get json response
 * @param {Boolean} async  Asynchronous or not
 * @param {String} url URI for the request
 * @param {Object} data Name/Value pair data request
 * @param {Function} callback  Callback function to be called
 */
var getJson = function (options) {
    options.url = util.toAbsoluteUrl(options.url);
    // return if no url exists or url matches a current request
    if (!options.url || currentRequests[options.url]) {
        return;
    }

    currentRequests[options.url] = true;

    // make the server call
    $.ajax({
        dataType: 'json',
        url: options.url,
        type: options.type || "GET",
        async: (typeof options.async === 'undefined' || options.async === null) ? true : options.async,
        data: options.data || {},
        statusCode: xhrStatusCode
    })
    // success
    .done(function (response) {
        if (options.callback) {
            options.callback(response);
        }
    })
    // failed
    .fail(function (xhr, textStatus) {
        if (textStatus === 'parsererror') {
            window.alert(Resources.BAD_RESPONSE);
        }
        if (options.callback) {
            options.callback(null);
        }
    })
    // executed on success or fail
    .always(function () {
        // remove current request from hash
        if (currentRequests[options.url]) {
            delete currentRequests[options.url];
        }
    });
};
/**
 * @function
 * @description ajax request to load html response in a given container
 * @param {String} url URI for the request
 * @param {Object} data Name/Value pair data request
 * @param {Function} callback  Callback function to be called
 * @param {Object} target Selector or element that will receive content
 */
var load = function (options) {
    options.url = util.toAbsoluteUrl(options.url);
    // return if no url exists or url matches a current request
    if (!options.url || currentRequests[options.url]) {
        return;
    }

    currentRequests[options.url] = true;

    // make the server call
    $.ajax({
        dataType: 'html',
        type: options.type || "GET",
        url: util.appendParamToURL(options.url, 'format', 'ajax'),
        data: options.data,
        xhrFields: {
            withCredentials: true
        },
        statusCode: xhrStatusCode
    })
    .done(function (response) {
        // success
        if (options.target) {
            $(options.target).empty().html(response);
        }
        if (options.callback) {
            if (typeof (response) == 'string' && response.substr(0, 1) === '{') {
                try {
                    response = JSON.parse(response);
                }
                catch(e) {
                }
            }
            options.callback(response);
        }
    })
    .fail(function (xhr, textStatus) {
        // failed
        if (textStatus === 'parsererror') {
            window.alert(Resources.BAD_RESPONSE);
            loading.hide();
        }
        if (options.callback) {
            options.callback(null, textStatus);
        }
    })
    .always(function () {
        progress.hide();
        // remove current request from hash
        if (currentRequests[options.url]) {
            delete currentRequests[options.url];
        }
    });
};

exports.getJson = getJson;
exports.load = load;
