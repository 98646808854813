'use strict';
/*
var util = require('./util'),
    _ = require('lodash');
*/
var loading = {
    /** target loading variable */
    $loading: null,
    $container: null,

    /**
     * @function
     * @description Appends a loading to a given container (target)
     * @param {Object} params  params.target can be an id selector or an jquery object
     */
    show: function() {
        if (!this.$loading) {
            this.$loading = $("#loading_container");
        }
        
        // Only display loading if it's display value is none
        if(this.$loading.css('display') === 'none') {
            this.$loading.show();
        }
    },
    /**
     * @function
     * @description Closes the loading
     */
    hide: function() {
        this.$loading.hide('slow');
    },
    /**
     * @function
     * @description Appends a loading to a given container (target)
     * @param {Object} params  params.target can be an id selector or an jquery object
     */
    create: function(container) {
        if (container && $(container).length > 0) {
            var target = $(container);
            if (!$(container + ' .loader').length) {
                $('<div/>').addClass('loader')
                    .append($('<div/>').
                    addClass('loader-indicator'), $('<div/>')
                    .addClass('loader-bg'))
                    .appendTo(target).show();
            }
            this.$container = container;
        }
        return this;
    },
    /**
     * @function
     * @description Closes the loading
     */
    remove: function() {
        if (this.$container) {
            $(this.$container + ' .loader')
                .hide('slow')
                .remove();
            this.$container = null;
        }
    }
};

module.exports = loading;
