'use strict';
var config = require('../scripts/config.json');
var creditcardJS = {
    keyCodeFromEvent: function(e) {
        return e.which || e.keyCode;
    },
    keyIsKeypadNumber: function(e) {
        var keyCode = this.keyCodeFromEvent(e);
        return keyCode >= config.KEYS_BOARD.NUMPAD_0 && keyCode <= config.KEYS_BOARD.NUMPAD_9;
    },
    keyIsTopNumber: function(e) {
        var keyCode = this.keyCodeFromEvent(e);
        return keyCode >= config.KEYS_BOARD.key0 && keyCode <= config.KEYS_BOARD.key9;
    },
    keyIsNumber: function(e) {
        return this.keyIsTopNumber(e) || this.keyIsKeypadNumber(e);
    },
    //---------------------------- keyIsNumber -------------------------------------

    //---------------------------- keyIsDeletion -------------------------------------
    keyIsDelete: function(e) {
        return this.keyCodeFromEvent(e) == config.KEYS_BOARD.DELETE;
    },
    keyIsBackspace: function(e) {
        return this.keyCodeFromEvent(e) == config.KEYS_BOARD.BACKSPACE;
    },
    keyIsDeletion: function(e) {
        return this.keyIsDelete(e) || this.keyIsBackspace(e);
    },
    //---------------------------- keyIsDeletion -------------------------------------
    
    //---------------------------- keyIsArrow -------------------------------------
    keyIsArrow: function(e) {
        var keyCode = this.keyCodeFromEvent(e);
        return keyCode >= config.KEYS_BOARD.ARROW_LEFT && keyCode <= config.KEYS_BOARD.ARROW_DOWN;
    },
    //---------------------------- keyIsArrow -------------------------------------

    //---------------------------- keyIsNavigation -------------------------------------
    keyIsNavigation: function(e) {
        var keyCode = this.keyCodeFromEvent(e);
        return keyCode == config.KEYS_BOARD.HOME || keyCode == config.KEYS_BOARD.END;
    },
    //---------------------------- keyIsNavigation -------------------------------------

    //---------------------------- keyIsKeyboardCommand --------------------------------
    keyIsCommandFromEvent: function(e) {
        return e.ctrlKey || e.metaKey;
    },
    keyIsKeyboardCommand: function(e) {
        var keyCode = this.keyCodeFromEvent(e);
        return this.keyIsCommandFromEvent(e) &&
        (
            keyCode == config.KEYS_BOARD.A ||
            keyCode == config.KEYS_BOARD.X ||
            keyCode == config.KEYS_BOARD.C ||
            keyCode == config.KEYS_BOARD.V
        );
    },
    //---------------------------- keyIsKeyboardCommand --------------------------------
    keyIsTab: function(e) {
        return this.keyCodeFromEvent(e) == config.KEYS_BOARD.TAB;
    },
    //---------------------------- keyIsKeyboardCommand --------------------------------

    filterNumberOnlyKey: function(e) {
        var isNumber = this.keyIsNumber(e),
        isDeletion = this.keyIsDeletion(e),
        isArrow = this.keyIsArrow(e),
        isNavigation = this.keyIsNavigation(e),
        isKeyboardCommand = this.keyIsKeyboardCommand(e),
        isTab = this.keyIsTab(e);
        isNumber || isDeletion || isArrow || isNavigation || isKeyboardCommand || isTab || e.preventDefault();
    },

    caretStartPosition: function(element) {
        return "number" == typeof element.selectionStart ? element.selectionStart : false;
    },
    caretEndPosition: function(element) {
        return "number" == typeof element.selectionEnd ? element.selectionEnd : false;
    },

    normaliseCaretPosition: function(mask, caretPosition) {
        var numberPos = 0;
        if (0 > caretPosition || caretPosition > mask.length) return 0;
            for (var i = 0; i < mask.length; i++) {
                if (i == caretPosition) return numberPos;
                if ("X" == mask[i]) numberPos++;
            }
        return numberPos;
    },

    numbersOnlyString: function(string) {
        for (var numbersOnlyString = "", i = 0; i < string.length; i++) {
            var currentChar = string.charAt(i),
                isValid = !isNaN(parseInt(currentChar));
            isValid && (numbersOnlyString += currentChar);
        }
        return numbersOnlyString;
    },
    digitFromKeyCode: function(keyCode) {
        return keyCode >= config.KEYS_BOARD.key0 && keyCode <= config.KEYS_BOARD.key9 ? keyCode - config.KEYS_BOARD.key0 : keyCode >= config.KEYS_BOARD.NUMPAD_0 && keyCode <= config.KEYS_BOARD.NUMPAD_9 ? keyCode - config.KEYS_BOARD.NUMPAD_0 : null;
    },
    denormaliseCaretPosition: function(mask, caretPosition) {
        var numberPos = 0;
        if(caretPosition < 0 || caretPosition > mask.length) { 
            return 0; 
        }
        for(var i = 0; i < mask.length; i++) {
            if(numberPos == caretPosition) { 
                return i; 
            }
            if(mask[i] == "X") { 
                numberPos++; 
            }
        }
        return mask.length;
    },
    setCaretPosition: function(element, caretPos) {
        if(element != null) {
            if(element.createTextRange) {
                var range = element.createTextRange();
                range.move('character', caretPos);
                range.select();
            } else {
                if(element.selectionStart) {
                element.focus();
                element.setSelectionRange(caretPos, caretPos);
                } else {
                element.focus();
                }
            }
        }
    },
    handleMaskedNumberInputKey: function(e, mask) {
        this.filterNumberOnlyKey(e);
      
        var keyCode = e.which || e.keyCode;
      
        var element = e.target;
      
        var caretStart = this.caretStartPosition(element);
        var caretEnd = this.caretEndPosition(element);
      
      
        // Calculate normalised caret position
        var normalisedStartCaretPosition = this.normaliseCaretPosition(mask, caretStart);
        var normalisedEndCaretPosition = this.normaliseCaretPosition(mask, caretEnd);
      
      
        var newCaretPosition = caretStart;
      
        var isNumber = this.keyIsNumber(e);
        var isDelete = this.keyIsDelete(e);
        var isBackspace = this.keyIsBackspace(e);
      
        if (isNumber || isDelete || isBackspace) {
            e.preventDefault();
            var rawText = $(element).val();
            var numbersOnly = this.numbersOnlyString(rawText);     
            var digit = this.digitFromKeyCode(keyCode);
            var rangeHighlighted = normalisedEndCaretPosition > normalisedStartCaretPosition;       
            // Remove values highlighted (if highlighted)
            if (rangeHighlighted) {
                numbersOnly = (numbersOnly.slice(0, normalisedStartCaretPosition) + numbersOnly.slice(normalisedEndCaretPosition));
            }

            // Forward Action
            if (caretStart != mask.length) {
                // Insert number digit
                if (isNumber && rawText.length <= mask.length) {
                    numbersOnly = (numbersOnly.slice(0, normalisedStartCaretPosition) + digit + numbersOnly.slice(normalisedStartCaretPosition));
                    newCaretPosition = Math.max(
                        this.denormaliseCaretPosition(mask, normalisedStartCaretPosition + 1),
                        this.denormaliseCaretPosition(mask, normalisedStartCaretPosition + 2) - 1
                    );
                }
                // Delete
                if (isDelete) {
                    numbersOnly = (numbersOnly.slice(0, normalisedStartCaretPosition) + numbersOnly.slice(normalisedStartCaretPosition + 1));
                }
            }
            // Backward Action
            if (caretStart != 0) {
                // Backspace
                if(isBackspace && !rangeHighlighted) {
                    numbersOnly = (numbersOnly.slice(0, normalisedStartCaretPosition - 1) + numbersOnly.slice(normalisedStartCaretPosition));
                    newCaretPosition = this.denormaliseCaretPosition(mask, normalisedStartCaretPosition - 1);
                }
            }
        
        
            $(element).val(this.applyFormatMask(numbersOnly, mask));
        
            this.setCaretPosition(element, newCaretPosition);
        }
    },

    handleCreditCardNumberKey: function(e, cardMask) {
        this.handleMaskedNumberInputKey(e, cardMask);
    },

//------------------------------------------------------------------------------------------
    applyFormatMask: function(string, mask) {
        for (var formattedString = "", numberPos = 0, j = 0; j < mask.length; j++) {
            var currentMaskChar = mask[j];
            if ("X" == currentMaskChar) {
                var digit = string.charAt(numberPos);
                if (!digit) break;
                (formattedString += string.charAt(numberPos)), numberPos++;
            } else formattedString += currentMaskChar;
        }
        return formattedString;
    },
    refreshCreditCardNumberFormat: function(cardNumberInput, creditCardNumberMask) {
        var numbersOnly = this.numbersOnlyString(cardNumberInput.val()),
            formattedNumber = this.applyFormatMask(numbersOnly, creditCardNumberMask);
        cardNumberInput.val(formattedNumber);
    }
}
var creditcard = {
    init: function () {
        var CREDIT_CARD_NUMBER_DEFAULT_MASK = "XXXX XXXX XXXX XXXX";
        var cardNumberInput = $('input[name$="_creditCard_number"]');
        cardNumberInput.on('keydown',function(e){
            creditcardJS.handleCreditCardNumberKey(e, CREDIT_CARD_NUMBER_DEFAULT_MASK);
        });
        cardNumberInput.on('paste',function(){
            setTimeout(function () {
                creditcardJS.refreshCreditCardNumberFormat(cardNumberInput,CREDIT_CARD_NUMBER_DEFAULT_MASK), 1
            });
        });
        cardNumberInput.on('change',function(){
            setTimeout(function () {
                creditcardJS.refreshCreditCardNumberFormat(cardNumberInput,CREDIT_CARD_NUMBER_DEFAULT_MASK), 1
            });
        });
    }
}
module.exports = creditcard;