'use strict';

var modal = require('../modal');

var print = {
    init: function () {

        if ($('#call-center-error-text').length > 0) {
            if ($('#call-center-error-text').val() != undefined && $('#call-center-error-text').val() != 'null' && $('#call-center-error-text').val() != '') {
                modal.dialog($('#call-center-error-text').val(), function() {
                    //@TODO
                });
            }
            $('.categoryCustom_confirm').parent().parent().css('background','transparent');
            $('.mfp-wrap').css('overflow','visible');  // 210127 Add: FCCL-2559
        }

        // Start 210301 Add: FCCL-2651
        if ($('#no_payment_error').length > 0) {
            modal.dialog($('#no_payment_error').val(), function() {
                //@TODO
            });
            $('.categoryCustom_confirm').parent().parent().css('background','transparent');
            $('.mfp-wrap').css('overflow','visible');
        }
        // End 210301 Add: FCCL-2651
    }
};

module.exports = print;
