'use strict';

var util = require('./util');
// FCCL-2656 : bangly 3/9/2021 start
var creditcard = require('./credit_card');
// FCCL-2656 : bangly 3/9/2021 end
var _open = 'isOpen';
var _close = 'isClose';
var _fixed = 'isFixed';

var jqueryCustom = {
    init: function () {
        //click accordion
        this.accordion();
        //click Anchor
        this.cateAnc();
        // FCCL-2656 : bangly 3/9/2021 start
        //creditcard
        creditcard.init();
        // FCCL-2656 : bangly 3/9/2021 end
    },
    accordion: function () {
        var _acdBtn = null;
        if ($('div.jsAccordionBtn').length > 0) {
            _acdBtn = $('div.jsAccordionBtn');
        } else if ($('a.jsAccordionBtn').length > 0) {
            _acdBtn = $('a.jsAccordionBtn');
        } else if ($('button.jsAccordionBtn').length > 0) {
            _acdBtn = $('button.jsAccordionBtn');
        }
        if (_acdBtn) {
            _acdBtn.off('click').on({
                'click': function() {
                    // FCCL-2328: Revert original code
                    $(this).closest('.jsAccordion').toggleClass(_open).children('.jsAccordionBox').stop().slideToggle(200);
                    return false;
                }
            });
        }
    },
    cateAnc: function () {
        var _cateAnchor = $('.categoryAnchor');
        if(_cateAnchor.length) {
            $(window).on({
                'scroll': function() {
                    var _cateAnchorPos = _cateAnchor.offset().top;
                    if( $(this).scrollTop() > _cateAnchorPos ) {
                        _cateAnchor.addClass(_fixed);
                    } else {
                        _cateAnchor.removeClass(_fixed);
                    }
                }
            });
        }
    }
};

module.exports = jqueryCustom;
