'use strict';

var _ = require('lodash'),
ajax = require('../../ajax'),
page = require('../../page'),
loading = require('../../loading'),
Promise = require('promise'),
config = require('../../../scripts/config'),
util = require('../../util'),
modal = require('../../modal'),
PdpServer = require('./pdp_server');

function initializeEvents() {
	var btnAddtoCart = $('button.productSpec_button');
	var deliveryTimeMapAndCode = $.parseJSON($('input[name="DeliveryTimeAndCode"]').val());
	//init pdpserver
	PdpServer.init($('#productID').val());
	// change quantity
	$( ".pdp-main .productSpec_select #quantity" ).change(function(e) {
		e.preventDefault();
		var qty = parseInt($(this).val(), 10);
		PdpServer.changeQuantity(qty);
		PdpServer.displayPrice();
	});

	$('.productSpec_inputNumber').on('change', function (e) {
		e.preventDefault();
		var qty = 0;
		if($(this).val() == null || $(this).val() == undefined || $(this).val() == "") {
			qty = 0;
		} else {
			qty = parseInt($(this).val(), 10);
		}
		PdpServer.changeQuantity(qty);
		PdpServer.displayPrice();
		if(qty > 999 || qty < 1) {
			btnAddtoCart.attr("disabled", true);
			btnAddtoCart.css('background-color', '#606A76');
			btnAddtoCart.text(btnDisable);
			window.alert(inputNunmberValidate);
		} else {
			var isEnable = btnAddtoCart.prop('disabled');
			if(isEnable) {
				btnAddtoCart.removeAttr("disabled");
				btnAddtoCart.css('background-color', '#C00');
				btnAddtoCart.text(btnEnable);
			}
		}
	});
	
	
	// click addtocart button
	$('.pt_product-details .page-t42').on('click touchend', '#add-to-cart', function(e) {
		e.preventDefault();
		loading.show();
		var $form = $(this).closest('form');
		PdpServer.addToCart($form);
	});

	$('select.product-set-item-select').on('change', function(e) {
		e.preventDefault();
		
		var control = $('option:selected',this);
		var selectControl = $(this);
    	var cloneMap = {};
    	if(control.attr('deliveryTime')) {
    		$('span.'+ $(this).attr('psid')).text(control.attr('deliveryTime'));
    	} else {
    		$('span.'+ $(this).attr('psid')).text('未定');
    	}

		//change display price
		PdpServer.displayPrice();

    	$.extend(cloneMap, specMap);
    	// Add property for cloneMap
    	cloneMap[control.attr('name')] = control.attr('value');
    	specMap[control.attr('name')] = control.attr('value');
    	$('select.product-set-item-select').each(function (e) {
    		$('p.' + $('option:selected',this).attr('name') + '-err').empty();
    		$(this).removeClass('categoryMainUnit-selectErr');
    	})
    	
    	checkSelectBTO(specMap, webTypeControlStr,control, selectControl);
    	deliveryTimeChange(specMap,deliveryTimeMapAndCode);
	});
	
	$('select.product-set-item-select').each(function (e) {
		if($('option:first',this).attr('deliveryTime')) {
			$('span.' + $(this).attr('psid')).text($('option:first',this).attr('deliveryTime'));
		} else {
			$('span.' + $(this).attr('psid')).text('未定');
		}
	});
	
	var specMap = {};
	initSpecMap(specMap);
	deliveryTimeChange(specMap,deliveryTimeMapAndCode);
	
	//init load Memory
	loadMemory();
	
	//recaculate memory when change option select
	changeDetailMemory();
	
	//recaculate price when change option select
	PdpServer.displayPrice();

	//checkSelectBTO(specMap, webTypeControlStr);
}

/**
 * function use to change delivery Time 
 * @param specMap
 * @param deliveryTimeMapAndCode
 * @returns
 */
function deliveryTimeChange(specMap, deliveryTimeMapAndCode) {
	var deliveryCodeRanking = config.DELIVERY_CODE_RANKING;
	var idxMax = -1;
	var deiveryTimeMax = "";
	$.each(specMap, function(key, value) {
		var deliveryTimeMapAndCodeValue = deliveryTimeMapAndCode[value];
		if (deliveryTimeMapAndCodeValue) {
			var idx = deliveryCodeRanking.indexOf(deliveryTimeMapAndCodeValue.code);
			if (deliveryTimeMapAndCodeValue.code) {
				if (idxMax < idx) {
					idxMax = idx;
					deiveryTimeMax = deliveryTimeMapAndCodeValue.time;
				}
			}
		}
	});
	$('.productSpec_text span').text(deiveryTimeMax);
	// FCCL-3152 【F-105（1）】スマートフォンの場合のPDPの価格表示のデザイン変更
	try {
		$('.productSpec-mobile-deliverytime span').text(deiveryTimeMax);
	} catch (error) {
	
	}
}


/**
 * init specMap use to check Webtype
 * @param specMap
 * @returns
 */
function initSpecMap(specMap) {
	$('select.product-set-item-select').each(function(e){
		if($('option',this).is(':selected') === true) {
			var control = $('option:selected',this);
			specMap[control.attr('name')] = control.attr('value');
		}
	})
}

/**
 * init Memory for productSet
 * @returns
 */
function loadMemory() {
	var logicMem = 0;
	var physicMem = 0;
	$('select.mem').each(function(e){
		let controlLogic = $('option:selected',this).attr('logicalmem');
		let controlPhysic = $('option:selected',this).attr('physicalmem');
		if(controlLogic && controlLogic != undefined && controlLogic != "null") {
  			let memSize = controlLogic;
    		if(memSize) {
    			logicMem += parseInt(memSize);
    		}
		}

		if(controlPhysic && controlPhysic != undefined && controlPhysic != "null") {
  			let memSize = controlPhysic;  
    		if(memSize) {
    			physicMem += parseInt(memSize);
    		}
		}    		  
	});
	$('p.categoryMainUnit_memoryTxt').html('メモリ容量の合計　<br class="forSP">物理容量：' + logicMem.toFixed(1) + 'GB 　論理容量： '+ physicMem.toFixed(1) + 'GB');	
}

/**
 * Load total memory when change option
 * @returns
 */
function changeDetailMemory() {
	$('select.mem').on('change', function(){
		loadMemory();
	})
}

function checkSelectBTO(specMap, webTypeControlStr, control, selectControl) {
	var result;
	if(webTypeControlStr) {
		result = checkWebTypeControlRequire(specMap, webTypeControlStr.require);
		if(result) {
			checkWebTypeControlExclude(specMap, webTypeControlStr.exclude);
		}
	} else {
		return specMap;
	}
}

/**
 * Check webtype require
 * @param specMap
 * @param webTypeControlStr
 * @param control
 * @param selectControl
 * @returns
 */
function checkWebTypeControlRequire(specMap, webTypeControlStr) {
	if(webTypeControlStr != null) {
		for(let i in webTypeControlStr) {
			var condition = webTypeControlStr[i].condition;
			if(!isEmpty(condition)) {
				var result = conditionJudgment(specMap, condition);
				if(result) {
					var combinaCondi = webTypeControlStr[i].combinationCondition;
					if(!isEmpty(combinaCondi)){
						var resultCondi = conditionCombineRequire(specMap, combinaCondi);
						if(!resultCondi) {
							var message = webTypeControlStr[i].message;
							return showErrPosition(message);
						}
					}
				}
			}
		}
	}
	return activeButton();
}

/**
 * Check webtype require
 * @param specMap
 * @param webTypeControlStr
 * @param control
 * @param selectControl
 * @returns
 */
function checkWebTypeControlExclude(specMap, webTypeControlStr) {
	if(webTypeControlStr != null) {
		for(let i in webTypeControlStr) {
			var condition = webTypeControlStr[i].condition;
			if(!isEmpty(condition)) {
				var result = conditionJudgment(specMap, condition);
				if(result) {
					var combinaCondi = webTypeControlStr[i].combinationCondition;
					if(!isEmpty(combinaCondi)){
						var resultCondi = conditionCombineExclude(specMap, combinaCondi);
						if(!resultCondi) {
							var message = webTypeControlStr[i].message;
							return showErrPosition(message);
						}
					}
				}
			}
		}
	}
	return activeButton();
}

/**
 * Condition Judgment
 * @param specMap
 * @param condition
 * @returns
 */
function conditionJudgment(specMap, condition) {
	var judCondition = false;
	var conditionLength = getSize(condition);
	var count = 0;
	for(let i in condition) {
		if(condition[i] == specMap[i]) {
			count++;
		}
	}
	if(count == conditionLength) {
		judCondition = true;
	}
	return judCondition;
}

function conditionCombineRequire(specMap, condition) {
	var count = 0;
	var conditionLength = getSize(condition);
	for(let j in condition) {
		var matchKey = matchingKey(condition,j);
		if(matchKey.length > 0) {
			for(let z in matchKey) {
				if(specMap[j] == matchKey[z]) {
					count++;
				}
			}
		}
	}
	if(count != conditionLength) {
		return false;
	}
	return true;
}

function conditionCombineExclude(specMap, condition) {
	for(let j in condition) {
		var matchKey = matchingKey(condition,j);
		if(matchKey.length > 0) {
			for(let z in matchKey) {
				if(specMap[j] === matchKey[z]) {
					return false;
				}
			}
		}
	}
	return true;
}
/**
 * function 7, check Matching Key
 * @param Map
 * @param key
 * @return result
 */
function matchingKey(Map, keySpec) {
	var result;
	for (var i in Map) {
		if(i === keySpec) {
			result = Map[i];
			return result;
		}
	}
	return result;
}

/**
 * show err Msg in position
 * @param message
 * @returns
 */
function showErrPosition(message) {
	var btnAddtoCart = $('button.productSpec_button');
	var errMsg = setErrMsg(message.format, message.placeholder);
	for(let i in message.position) {
		var position = message.position[i];
		$('p.' + position + '-err').html(errMsg);
		$('select#' +position).addClass('categoryMainUnit-selectErr');
	}
	btnAddtoCart.attr("disabled", true);
	btnAddtoCart.css('background-color', '#606A76');
	btnAddtoCart.text(btnDisable);
	return false;
}

/**
 * active Button addtocart of it disable and no error
 * @returns
 */
function activeButton(){
	var btnAddtoCart = $('button.productSpec_button');
	var isEnable = btnAddtoCart.prop('disabled');
	if(isEnable) {
		btnAddtoCart.removeAttr("disabled");
		btnAddtoCart.css('background-color', '#C00');
		btnAddtoCart.text(btnEnable);
	}
	return true;
}
/**
 * set message
 * @param format
 * @param placeHolder
 * @returns
 */
function setErrMsg(format, placeHolder) {
	var result = format;
	for(let i in placeHolder) {
		var con = '<ul>';
		for(let j in placeHolder[i]) {
			var re = placeHolder[i][j];
			var type = j;
			for(let z in re) {
				var id = re[z];
				con += '<li>' +  '・[' + id + ']' + getName(type, id) + '</li>';
			}
		}
		let st = '{' + i + '}';
		result = result.replace(st,con + '</ul>');
	}
	return result;
}

function getName(type, id) {
	if(type === 'productId') {
		return $('select.product-set-item-select option.' + id).attr('popName');
	} else if(type === 'productSetId') {
		return  $('select#' + id).attr('name');
	}
	return false;
}

/*
 * check size of object
 */
function getSize(obj) {
	var count = 0;
	for(let i in obj) {
		if(obj.hasOwnProperty(i)) {
			count ++;
		}
	}
	return count;
}

/*
 * check object empty or not
 */
function isEmpty(obj) {
	  for(var prop in obj) {
		    if(obj.hasOwnProperty(prop)) {
		      return false;
		    }
		  }
	  return true;
}
exports.init = function () {
	initializeEvents();
};