'use strict';
var ajax = require('./ajax'),
    util = require('./util'),
    loading = require('./loading'),
    _ = require('lodash');
var findProduct = {
    init: function () {
        $('button[id="validate-search-order"]').on('click',function(){
            loading.show();
            var data = {
                orderNumber : $('form[id="dwfrm_orderconf"] input#dwfrm_orderconf_orderNo').val(),
                phone : $('form[id="dwfrm_orderconf"] input#dwfrm_orderconf_phone').val()
            };
            ajax.load({
                type: 'POST',
                url: Urls.validationOrder,
                data: data,
                callback: function (response) {
                    if(response.error == 'false'){
                        $('form[id="dwfrm_orderconf"]').removeAttr('target');
                        $('#search-order').click();
                    }else{
                    	var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
                    	if (isSafari) {
                            $('form[id="dwfrm_orderconf"]').removeAttr('target');
                    	} else {
                            $('form[id="dwfrm_orderconf"]').attr('target', '_blank');
                    	}
                        $('.form_attention').text('');
                        $('#search-order').click();
                    }
                    loading.hide();
                }
            });
            //$(this).removeAttr('target');
            //$(this).attr('target', '_self');
        });
    }
}
module.exports = findProduct;