'use strict';
var loading = require('../../loading'),
	page = require('../../page');

function initializeEvents() {
	/**
	 * return to EmpIntro when click button and save data to local storage
	 */
	$('button.form_button-back').on('click', function(e) {
		var control = $(this);
		var url = control.attr('url');
		$('div.membersInfo_itemText').each(function(e) {
			if($(this).attr('name') != 'nameBottom' && $(this).attr('name') != 'name') {
				localStorage.setItem($(this).attr('name'), $(this).text());
			}
		});
		$('input.input_hidden').each(function(e){
			localStorage.setItem($(this).attr('name'), $(this).attr('value'));
		})
		page.redirect(url);
	});

}

exports.init = function() {
	initializeEvents();
};