module.exports={
    "settings" : {
        "cartridgeCoreName" : "common_core",
        "contact_phone_number" : "0120-719-242",
        "contact_phone_number_b2b" : "0120-564-380"
    },
    "account" : {
        "loginCloudLink" : "https://azby.fmworld.net/app/customer/registpc/entrance.act",
        "cloudLink" : "https://azby.fmworld.net/app/customer/registpc/common/regist_pc_auto.vhtml",
        "contractedserice" : {
            "1": "利用中",
            "2": "解約申込中",
            "3": "解約完了",
            "4": "サービス停止中",
            "5": "解約完了（削除未処理）"
        },
        "source_code_group": {
            "FMV_USER": "126",
            "GUEST_USER": "169",
            "PREMIMUM": "257"
        },
        "member_lever": {
            "FMV": 5,
            "GUEST": 2
        },
        "authenticationProviderId": "FujitsuMyCloud",
        "defaultPassword": "ABcd@1234"
    },
    "CurrencySeparate": ",",
    "CurrencySymbol": "円",
    "siteCatalog"   : "Consumer",
    "masterCatalog" : "M_PC",
    "rootCategory"  : "root",
    "product" : {
        "priceBookList"    : "list",
        "priceBookSale"    : "SalePrice"
    },
    "masterCategory": {
        "CPU"                : ["RS_CPU"],
        "Color"              : ["RS_Color"],
        "Display"            : ["RS_Display"],
        "Memory"             : ["RS_Memory"],
        "OS"                 : ["RS_OS"],
        "Other"              : ["RS_OTHER"],
        "Storage"            : ["RS_Storage"],
        "Service"            : [
            "SRV_PCTutor",
            "SRV_MyCloudPremium",
            "SRV_HardwareWarranty",
            "SRV_CustomMadePlus",
            "SRV_PhoneSupport",
            "SRV_NiftyConnectionServiceApplication",
            "SRV_RecoveryMediaCreation"
        ],
        "PeripheralEquipment": ["RS_PeripheralEquipment"]
    },
    "productSetCategory" : {
        "BTO"    : "BTO",
        "Service": "SRV",
        "PeripheralEquipment_Software" : "PRP",
        "Campaign" : "CPN"
    },
    "pcCategory" : [
        "Desktop",
        "Laptop",
        "Tablet"
    ],
    "topCategoryId":{
        "PC": "PC",
    	"BTO": "BTO",
        "Service": "Service",
        "Campaign": "Campaign",
        "SERVER": "Server",
    	"PS": "PeripheralEquipment_Software",
    	"PRESENT": "Present"
    },
    "topCategoryIdB2B":{
        "PC": "PC",
    	"BTO": "S_BTO",
        "Service": "Service",
        "Campaign": "Campaign",
    	"PS": "PeripheralEquipment_Software",
    	"PRESENT": "Present"
    },
    "categoryInTop":{
    	"DESKTOP": "Desktop",
    	"LAPTOP": "Laptop",
        "TABLET": "Tablet",
        "PS": "PeripheralEquipment_Software",
        "SERVICE": "Service",
        "CAMPAIGN1":"Campaign1"
    },
    "categoryInTopB2B":{
    	"DESKTOP": "Desktop",
        "TABLET": "Tablet",
        "MOBILE": "Mobile",
        "CAMPAIGN":"Campaign",
        "WORKSTATION": "Workstation"
    },
	"B2Bcatalog": "Business",
	"B2Ccatalog": "Consumer",
    "productGroup" : {
        "PC" : "PC",
        "BTO": "BTO",
        "SRV": "SRV",
        "PRP": "PRP",
        "CPN": "CPN",
        "SB": "SB",
        "BKU": "BKU",
        "MAI": "MAI",
        "UPS": "UPS",
        "SVD": "SVD"
    },
    "J_Section" : {
        "BTO": 1,
        "CAMPAIGN": 2,
        "SERVICE": 3,
        "PERIPHERAL": 4
    },
    "separatePurchasable" : [
        "PC",
        "PRP"
    ],
    "pcProductType" : {
        "MJ" : "MJ",
        "CM" : "CM",
        "AC" : "AC",
        "RS_CPU" : "RS_CPU",
        "RS_OS" : "RS_OS",
        "RS_Memory" : "RS_Memory",
        "RS_Storage" : "RS_Storage",
        "RS_OpticalDrive" : "RS_OpticalDrive",
        "RS_Display" : "RS_Display",
        "RS_Color" : "RS_Color",
        "RS_DrivingTime" : "RS_DrivingTime",
        "RS_TVFunction" : "RS_TVFunction",
        "SRV_PCTutor" : "SRV_PCTutor",
        "SRV_MyCloudPremium" : "SRV_MyCloudPremium",
        "SRV_HardwareWarranty" : "SRV_HardwareWarranty",
        "PC_CM":"PC_CM",
        "PC_AC": "PC_AC",
        "PC_CT": "PC_CT",
        "ARRANGE":"ARRANGE"
    },
    "btoMainAttributes" : ["OS", "CPU", "メモリ", "Memory", "ストレージ", "Storage"],
    "productTypeAttrs":[
        "RS_OS",
        "RS_CPU",
        "RS_Memory",
        "RS_Storage",
        "RS_OpticalDrive",
        "RS_Display",
        "RS_Color",
        "RS_OTHER",
        "SRV_PCTutor",
        "SRV_MyCloudPremium",
        "SRV_HardwareWarranty",
        "SRV_CustomMadePlus",
        "SRV_PhoneSupport",
        "SRV_NiftyConnectionServiceApplication",
        "SRV_RecoveryMediaCreation",
        "RS_PeripheralEquipment",
        "RS_MicrosoftOffice"
    ],
    "notProductTypeAttrs": [
        "RS_ModelType",
        "RS_WarrantyPeriod",
        "RS_DrivingTime",
        "RS_Weight",
        "RS_TVFunction"
    ],
    "productTypeAttrsLess":[
        "RS_OS",
        "RS_CPU",
        "RS_Memory",
        "RS_Storage"
    ],
    "modelType" : {
        "CM" : "1",
        "AC" : "3",
        "CP" : "2",
        "OL"  : "4",
        "CL"  : "5",
        "ACCM" : "6"
    },
    "boProductType" : {
        "PD" : "Product",
        "PS" : "ProductSet",
        "MJ" : "MajorProduct",
        "CM" : "CustomMadeProduct",
        "AC" : "ACustomProduct"
    },
    "displayAttributes": [
        "RS_OS",
        "RS_CPU",
        "RS_Memory",
        "RS_Storage",
        "RS_OpticalDrive",
        "RS_Display",
        "RS_MicrosoftOffice"
    ],
    "displayAttributesWidth": {
        "RS_OS"             : "140px",
        "RS_CPU"            : "152px",
        "RS_Memory"         : "77px",
        "RS_Storage"        : "226px",
        "RS_Display"        : "110px",
        "RS_MicrosoftOffice": "115px"
    },
    "BTO":[
        "RS_CPU",
        "RS_OS",
        "RS_Memory",
        "RS_Storage",
        "RS_OpticalDrive",
        "RS_Display",
        "RS_Color",
        "RS_OTHER"
    ],
    "SRV":[
        "SRV_PCTutor",
        "SRV_MyCloudPremium",
        "SRV_HardwareWarranty",
        "SRV_CustomMadePlus",
        "SRV_PhoneSupport",
        "SRV_NiftyConnectionServiceApplication",
        "SRV_RecoveryMediaCreation"
    ],
    "PRP":[
        "RS_PeripheralEquipment",
        "RS_MicrosoftOffice"
    ],
    "searchAttrs":[
        "RS_OS",
        "RS_CPU",
        "RS_Memory",
        "RS_Storage",
        "RS_OpticalDrive",
        "RS_Display",
        "RS_TVFunction",
        "RS_Color",
        "RS_MicrosoftOffice",
        "RS_Weight",
        "RS_DrivingTime",
        "RS_ModelType",
        "J_QuickDelivery"
    ],
    "searchSpecialAttrs":[
        "RS_ModelType",
        "J_QuickDelivery"
    ],
    "niftyProduct" : "SRV_NiftyConnectionServiceApplication",
    "QuickDeliveryAttr" : "J_QuickDelivery",
    "ModelTypeAttr" : "RS_ModelType",
    "PeripheralSoftware" : "PeripheralEquipment_Software",
    "address" : {
        "fields" : [
            "firstName",
            "lastName",
            "address1",
            "address2",
            "city",
            "postalCode",
            "stateCode",
            "countryCode",
            "phone"]
    },
    "checkout" : {
        "steps": [{
            "id": "cart"
        },{
            "id": "login",
            "modules": ["login"]
        },{
            "id": "shipping",
            "sections": [{"id": "shippingaddress","modules": ["shippingaddress"]},
                {"id": "shippingmethod","modules": ["shippingmethod"]}]
        },{
            "id": "billing",
            "sections": [{"id": "billingaddress","modules": ["billingaddress"]},
                {"id": "payment","modules": ["couponcode","payment"]}]
        },{
            "id": "confirmation",
            "modules": ["confirmation"],
            "showInProgressIndicator" : false
        }]
    },
    "countries" : {
        "US" : {
            "form" : {
                "layout" : [
                    [{"id" : "firstName", "width": "50%" },
                        {"id" : "lastName", "width": "50%" }],
                    ["address1"],
                    ["address2"],
                    [{"id" : "postalCode", "width": "30%" },
                        {"id" : "city", "width": "70%" }
                    ]
                ]
            },
            "currencyCode" : "USD"
        }
    },
    "searchCustomObjects": ["news"],
    "searchCustomAttribute": ["ApplicableModel"],
    "paging":{
        "productSize": 12,
        "contentSize": 16,
        "maxSize": 60,
        "searchProductSize": 4,
        "maxPage": 4,
        "listSize": 10
    },
    "recommendation": {
        "series": 4
    },
    "shippingDate": {
        "1": "月",
        "2": "火",
        "3": "水",
        "4": "木",
        "5": "金",
        "6": "土",
        "7": "日"
    },
    "shippingTime": {
        "1": "時間帯指定をしない",
        "2": "午前 ( 09 : 00 ~ 12 : 00 )",
        "3": "午後 ( 12 : 00 ~ 18 : 00 )",
        "4": "夜間 ( 18 : 00 ~ 21 : 00 )"
    },
    "lastvisitedcount": 12,
    "customViewType" : {
        "RS_Color"  : ["ブラック系", "ホワイト系", "シルバー系", "レッド系", "ゴールド系", "ブルー系"],
        "RS_Display": ["ディスプレイなし", "ディスプレイあり"],
        "showAll"   : ["RS_Color"]
    },
    "colorMap" : {
        "ブラック系" : "black",
        "ホワイト系" : "white",
        "シルバー系" : "silver",
        "レッド系"  : "red",
        "ゴールド系" : "yellow",
        "ブルー系"  : "blue"
    },
    "plsSideBarRefinements": {
        "parentcats": [
            "MainPC",
            "PeripheralEquipment",
            "Software",
            "Service"
        ],
        "searchPeripheralEquipment": [
            "G_Acadapter",
            "G_Battery",
            "OptionsForG_Tablets",
            "G_Mouse",
            "G_E_LaptopMemory",
            "G_Keyboard",
            "G_LCD_Display",
            "Other"
        ]
    },
    "cartQuantity" : 9,
    "pdpDefaultNotCheckedAttr" : "isDefaultNotChecked",
    "pdpSelectableQuantityAttr" : "isSelectableQuantity",
    "pdpMultiSelectableAttr" : "isMultiSelectable",
    "sourceCodeGroupStorePayment": [17],
    "sourceCodeGroupCallCenterPayment": [31],
    "productLinks": {
        "Link1": "特長",
        "Link2": "仕様",
        "Link3": "ソフトウェア",
        "Link4": "デザイン"
    },
    "productLinksB2B": {
        "Link1": "送料について",
        "Link2": "キャンセル・返品・交換について"
    },    
    "quantity": [1,2,3,4,5,6,7,8,9,10],
    "wishlist": {
        "max_items": 100,
        "pctype"   : "fvpc",
        "prptype"  : "fvprp",
        "display": 4,
        "btos": [
            "RS_ModelType",
            "RS_OS",
            "RS_CPU",
            "RS_Memory",
            "RS_Storage",
            "RS_OpticalDrive",
            "RS_Display",
            "RS_Color",
            "RS_MicrosoftOffice",
            "RS_DrivingTime",
            "RS_Weight",
            "RS_Other"
        ]
    },
    "useDummyAPI" : true,
    "paymentMethod": {
        "rakuten"     : "SBPS_RakutenPay",
        "payeasy"     : "SBPS_PayEasy",
        "credit"      : "CREDIT_CARD",
        "banktranfer" : "BANK_TRANSFER",
        "webCVS"      : "SBPS_WebCVS",
        "cashDelivery": "CASH_ON_DELIVERY",
        "invoice"     : "BML",
        "shop"        : "COUNTER_PAYMENT",
        "callcenter"  : "CALL_CENTER",
        "designatedlease" : "DESIGNATED_LEASE",
        "mallPayment" : "MALL_PAYMENT"
    },
    "bankTransferInfo" : {
        "bank"         : "みずほ銀行内幸町営業部",
        "storenumber"  : "111",
        "accountnumber": "2217883 (普通口座)",
        "accountholder": "富士通株式会社",
        "accountname"  : "フジツウ(カ"
    },
    "payeasyInfo" : {
        "storageagencynumber"  : "みずほ銀行内幸町営業部",
        "customernumber"       : "111",
        "authorization"        : "2217883 (普通口座)",
        "paymentclassification": "富士通株式会社"
    },
    "rakutenInfo" : {
        "storageagencynumber"  : "みずほ銀行内幸町営業部",
        "customernumber"       : "111",
        "authorization"        : "2217883 (普通口座)",
        "paymentclassification": "富士通株式会社"
    },
    "webCVSInfo" : {
        "storageagencynumber"  : "みずほ銀行内幸町営業部",
        "customernumber"       : "111",
        "authorization"        : "2217883 (普通口座)",
        "paymentclassification": "富士通株式会社"
    },
    "cashDeliveryInfo" : {
        "storageagencynumber"  : "みずほ銀行内幸町営業部",
        "customernumber"       : "111",
        "authorization"        : "2217883 (普通口座)",
        "paymentclassification": "富士通株式会社"
    },
    "creditInfo" : {
        "storageagencynumber"  : "みずほ銀行内幸町営業部",
        "customernumber"       : "111",
        "authorization"        : "2217883 (普通口座)",
        "paymentclassification": "富士通株式会社"
    },
    "shippingStatus" : {
        "NOTSHIPPED"  : 0,
        "PARTSHIPPED" : 1,
        "SHIPPED"     : 2
    },
    "dateFormatter": "yyyy/MM/dd HH時",
    "moneyformatter": "#,###",
    "dateFormatter1": "yyyy年MM月dd日",
    "dateFormatterYearMonthJP": "yyyy年MM月",
    "dateFormatterYearMonthDay": "yyyy/MM/dd",
    "searchRefinement":{
    	"categoryNotShow": "BTO",
    	"categorylv1": "MainPC",
    	"subCategoryPC1": "Desktop",
        "subCategoryPC2": "Mobile",
        "whiteListRefinementID": [
			"RS_CPU",
			"RS_OS",
			"RS_Display",
			"RS_Storage",
			"RS_Memory",
			"RS_OpticalDrive",
			"RS_TVFunction",
			"RS_Color",
			"RS_MicrosoftOffice",
			"J_QuickDelivery"
		]
    },
    "categories":{
    	"CGID_1":"DeskTop",
     	"CGID_2":"Laptop", 
    	"CGID_3":"QH",
    	"CGID_4":"Campaign",
    	"CGID_5":"PeripheralEquipment",
    	"CGID_6":"Software",
    	"CGID_7":"Service"
    },
    "searchResult":{
    	"numShow": 20,
    	"numProducts":25
    },
    "cgid": {
        "SERVICE": "Service",
        "GENERAL_SERVICE": "GeneralService",
        "GUARANTE": "WarrantyAndRecovery",
        "SECURITY": "SecurityService",
        "FWV": "FMVUtilization"
    },
    "CUSTOMER_PROMOTION_ID": "SPECIAL_OFFER",
    "CUSTOMER_SPECIAL_OFFER_TEXT": "SPECIAL_OFFER",
    "COUPON_PROMOTION_ID": "COUPON_OFFER",
    "COUPON_PROMOTION_OFFER_TEXT": "COUPON_OFFER",
    "web_number": {
        "MONTHLY_BUSTER_VIRUS": "FMWMBB0003-0",
        "MONTHLY_BUSTER_VIRUS_FREE": "FMWMBB0003-1",
        "NORTON_INTERNET_SECURITY": "FMWMBB0004-0",
        "NORTON_INTERNET_SECURITY_FREE": "FMWMBB0004-1",
        "MONTHLY_MCAFEE_MULTI_ACCESS": "SVUCXSSA01-0",
        "MONTHLY_MCAFEE_MULTI_ACCESS_FREE": "SVUCXSSA01-1",
        "DEKIRA_COURSE": "SVUCXMN010-FP",
        "DEKIRA_COURSE_FREE": "SVUCXMN010-01",
        "NO_LIMIT_SOFT_TOKU_PREMIUM": "SVUCXSSB07",
        "NO_LIMIT_SOFT_TOKU": "SVUCXSSB06-0",
        "NO_LIMIT_SOFT_TOKU_FREE": "SVUCXSSB06-1",
        "CLOUD_PREMIUM_BASIC": "FMWMVPR012-1",
        "CLOUD_PREMIUM_ANSHIN_PLUS": "FMWMVPR033-1",
        "CLOUD_PREMIUM_ANSHIN_STANDARD": "SVUCXPR040-2",
        "CLOUD_PREMIUM_ANSHIN_STANDARD_2": "SVUCXPR040-3",
        "CLOUD_PREMIUM_ANSHIN_WIDE": "SVUCXPR050-2"
    },
    "cartColor": ["red", "yellow", "green", "gray"],
    "breadcrumbs": {
        "tops": {
            "Desktop": "デスクトップパソコン一覧",
            "Laptop": "ノートパソコン一覧",
            "Tablet": "防水タブレット一覧",
            "Campaign": "キャンペーン一覧",
            "PeripheralEquipment_Software": "周辺機器",
            "Service": "サービス・サポート一覧",
            "Server": "サーバー一覧",
            "WorkStation": "ワークステーション"
        }
    },
	"category_name": {
        "Service": "すべて選択",
        "GeneralService": "総合保証サービス",
        "WarrantyAndRecovery": "保証サービス",
        "Security": "セキュリティサービス",
        "FMVUtilization": "FMV利活用サービス"
    },
    "product_name": {
        "RECOMMEND_WARRANTY_NAME": "5年延長保証(2年延長保証）"
    },
    "productType":{
        "os":"RS_OS",
        "cpu":"RS_CPU",
        "memory":"RS_Memory",
        "storage":"RS_Storage",
        "optical_drive":"RS_OpticalDrive",
        "display":"RS_Display",
        "color":"RS_Color",
        "office":"RS_MicrosoftOffice",
        "battery_run_time":"RS_DrivingTime",
        "mass":"RS_Weight",
        "hardware_warranty":"SRV_HardwareWarranty",
        "campaign":"RS_Present",
        "service_support":"SRV_MyCloudPremium",
        "peripheralEquipment":"RS_PeripheralEquipment",
        "PC":"PC_"
    },
    "OCCUPATION": {
        "LOGIN": 1,
        "NOT_LOGIN": 0
    },
    "CUSTOMER_GROUP": {
        "169GuestUserGroup": "169",
        "126FMVUserGroup": "126",
        "257MyCloudPremiumUserGroup": "257",
        "504YutaihojinUserGroup": "504"
    },
	"PAYMENT_METHOD": {
		"CARD" : 21,
		"BANKING" : 1,
		"PAY_EASY" : 30,
		"CONVIN" : 5,
		"RAKUTEN" : 25,
		"COD" : 12,
		"INVOCE" : 15,
		"SHOP" : 17,
		"CALL_CENTER" : 31,
		"DESIGNATED_LEASE" : 16,
        "MALL_PAYMENT" : 32
	},
	"PAYMENT_TYPE": {
		"ONE_TIME" : "都度",
		"CONTINUE" : "継続"
	},
	"INSTALLMENT_TYPE": {
		"Type_10" : "10",
		"Type_21" : "21",
		"Type_61" : "61",
		"Type_61_Child" : "61c",
		"Type_80" : "80"
	},
	"PromotionFee" : {
		"ID" : "FEE",
		"Text" : "FEE"
	},
	"INTEGRATION_STATUS" : {
		"NOT_EX" : 0,
		"EX_REG_OK" : 1,
		"EX_REG_NG" : 2,
		"EX_UPD_START" : 3,
		"EX_UPD_OK" : 4,
		"EX_UPD_NG" : 5
	},
	"EXPORT_STATUS" : {
		"NOTEXPORTED" : 0,
		"EXPORTED" : 1,
		"EXPORTREADY" : 2,
		"EXPORTFAILED" : 3
	},
	"DEFAULT_COUNTRY" : "JP",
    "ORDER_CONTROLLER": ["Cart", "COBilling", "COPlaceOrder", "COShipping", "COShippingMultiple", "COSummary"],
    "DEFAULT_SOURCECODE" : {
		"B2C" : 0,
		"B2B" : 500
	},
    "STR_SEARCH" : {
		"OK" : 0,
		"NG" : -1
	},
    "DELIVERY_CODE_RANKING" : [
		9,
		11,
		15,
		12,
		21,
		81,
		22,
		82,
		23,
		83,
		27,
		84,
		8,
		85,
		1,
		2,
		3,
		25,
		26,
		10,
		6,
		13,
		17,
		16,
		98,
		99,
		4,
		5,
		14,
		7
    ],
    "AMOUNTLIMIT" : {
        "CONBINI" : 300000,
        "PAY_EASY" : 1000000,
        "COD"	  : 298680,
        "ONLY_CREDIT": 0
    },
    "LOGIN_TEMPLATE_SCOPE" : {
        "ACCOUNT_LOGIN" : null,
        "ACCOUNT_LOGIN_FAILED" : "",
        "CHECKOUT_LOGIN" : "checkout",
        "QUOTE_LOGIN" : "quote",
        "WISHLIST_LOGIN" : "wishlist"
    },
    "JP_DATE_FORMAT_STRING" : {
        "YEAR_START" : 0,
        "YEAR_END" : 4,
        "MONTH_START" : 4,
        "MONTH_END" : 6,
        "DAY_START" : 6
    },
    "PATH_IMG_CATALOG" : "/images/common/",
    "TYPE_IMG" : {
        "JPG" : "jpg"
    },
    "BILLING_ADDRESS" : {
        "01" : "公務員",
        "02" : "経営者・役員",
        "03" : "会社員",
        "04" : "自営業・自由業",
        "05" : "専業主婦・主夫",
        "06" : "パート・アルバイト",
        "07" : "学生（小学校・中学校）",
        "08" : "学生（高校）",
        "09" : "学生（大学・大学院・専門学校）",
        "10" : "退職後無職",
        "11" : "その他"
    },
    "B2C_IN_B2B_SOURCECODE": ["409", "442", "448"],
    "KEYS_BOARD": {
        "key0" : 48,
        "key9" : 57,
        "NUMPAD_0" : 96,
        "NUMPAD_9" : 105,
        "DELETE" : 46,
        "BACKSPACE" : 8,
        "ARROW_LEFT" : 37,
        "ARROW_RIGHT" : 39,
        "ARROW_UP" : 38,
        "ARROW_DOWN" : 40,
        "HOME" : 36,
        "END" : 35,
        "TAB" : 9,
        "A" : 65,
        "X" : 88,
        "C" : 67,
        "V" : 86
    },
    "DUMMY_MAIL" : "no@fmworld.net",
    "LONG_DELIVERY_TIME_CODES": [2, 3, 25, 26, 10, 4, 5, 6, 7]
}
