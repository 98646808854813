'use strict';
var loading = require('../../loading'),
	page = require('../../page');

function initializeEvents() {
	/**
	 * check if local storage is set form T44 and them fill data into input
	 */
	if(localStorage.length > 0) {
		$('input.form_input').each(function(e) {
			var control = $(this);
			var name = control.attr('name');
			if(name == 'affiliationCode') {
				control.val(localStorage.getItem(name)); 
			} else if(name == 'reWithEmp') {
				control.val(localStorage.getItem(name)); 
			} else if(name == 'extensionOrDialIn') {
				control.val(localStorage.getItem(name)); 
			} else if(name == 'mailAddressBottom') {
				control.val(localStorage.getItem(name)); 
			} else if(name == 'employeeNumber') {
				control.val(localStorage.getItem(name)); 
			} else if(name == 'mailAddress') {
				control.val(localStorage.getItem(name)); 
			} else if(name == 'lastName') {
				control.val(localStorage.getItem(name)); 
			} else if(name == 'firstName') {
				control.val(localStorage.getItem(name)); 
			} else if(name == 'lastNameBottom') {
				control.val(localStorage.getItem(name)); 
			} else if(name == 'firstNameBottom') {
				control.val(localStorage.getItem(name)); 
			} else if(name == 'comment') {
				control.val(localStorage.getItem(name)); 
			}
		})
	}
}

exports.init = function () {
	initializeEvents();
};
