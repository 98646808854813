'use strict';

var addProductToCart = require('./product/addToCart'),
    ajax = require('../ajax'),
    page = require('../page'),
    login = require('../login'),
    util = require('../util'),
    modal = require('../modal'),
    loading = require('../loading');
function sendRequest (url, data) {
    return new Promise(function (resolve) {
        ajax.load({
            type: 'POST',
            url: url,
            data: data,
            callback: function (response, textStatus) {
                //console.log(response, textStatus);
                resolve(response);
            }
        });
    });
}
function openPopupModal(){
    var ErrorFlag = $('input#ErrorFlag').attr('value');
    if(ErrorFlag == 'true'){
        modal.confirm(Resources.FAVORITE_UPDATE_TITILE, '', function() {
            loading.show();
            sendRequest(Urls.updateFirstWishList, '')
            .then(function (response) {
                // handle error in the response
                if (response.error == 'login') {
                    page.redirect(util.appendParamsToUrl(Urls.login, {
                        scope: 'wishlist'
                    }));
                }
                else{
                    window.location.reload();
                    //$.magnificPopup.close();
                }
            });
        });
    }
}

exports.init = function () {
//    addProductToCart();
//    $('#editAddress').on('change', function () {
//        page.redirect(util.appendParamToURL(Urls.wishlistAddress, 'AddressID', $(this).val()));
//    });
//
//    //add js logic to remove the , from the qty feild to pass regex expression on client side
//    $('.option-quantity-desired input').on('focusout', function () {
//        $(this).val($(this).val().replace(',', ''));
//    });
    openPopupModal();
    //wishlist category search
    var _defaultHeight = 90;
    var _open = 'isOpen';
    
    /** START T21 at 2020/05/11 **/
    $('.productTableSection_filterList').on('click', 'input[type="radio"]', function(){
        var id=$(this).attr('id');
        var $tableContentContainer = $('div.productTable_contents');
        if(id=='product1'){
            $tableContentContainer.find('ul.cg_mainPC').show();
            $tableContentContainer.find('ul.cg_service').show();
            $tableContentContainer.find('ul.cg_peripheral').show();
        }else if(id=='product2'){
            $tableContentContainer.find('ul.cg_mainPC').show();
            $tableContentContainer.find('ul.cg_service').hide();
            $tableContentContainer.find('ul.cg_peripheral').hide();
        }else if(id=='product3'){
            $tableContentContainer.find('ul.cg_mainPC').hide();
            $tableContentContainer.find('ul.cg_service').show();
            $tableContentContainer.find('ul.cg_peripheral').hide();
        }else if(id=='product4'){
            $tableContentContainer.find('ul.cg_mainPC').hide();
            $tableContentContainer.find('ul.cg_service').hide();
            $tableContentContainer.find('ul.cg_peripheral').show();
        }
        $('.js-productTable').slick('slickGoTo', 0);
    });
    //click button confirm Delete all
    $(document.body).on('click', 'div#productTableSectionConfirm .productTableModal_confirmButton-reset', function(){
        $('div.productTableSection_count .js-inline-modal').magnificPopup('close');
        loading.show(); 
        var url=window.location.href;
           url = util.appendParamsToUrl(url, {
                  'isDeleteAll' : 'true',
               'format': 'ajax'
        });
        $.ajax({
            url: url,
            success: function (response) {
                $('div.productTableRenderArea').empty().html(response);
                loading.hide();
            },
             error:function(err){
             loading.hide();
             }
        });
    });
    //Click button [x]
    $('div.productTableRenderArea').on('click', 'a.productTable_listClose', function(){
        var date =$(this).attr('additionDate');
        $('input#deleteId').attr('value', date);
    });
    ////click button confirm Delete one 1 product
    $(document.body).on('click', 'div#productTableConfirm .productTableModal_confirmButton-reset', function(){
        $('div.js-productTable .js-inline-modal').magnificPopup('close');
        loading.show(); 
        var date=$('input#deleteId').attr('value');
        var url=window.location.href;
            url = util.appendParamsToUrl(url, {
                'additonDate' : date,
                'format': 'ajax',
                'trackingFilter': $('.productTableSection_filterList input[type="radio"]:checked').attr('id')
        });
        $.ajax({
            url: url,
            success: function (response) {
                $('div.productTableRenderArea').empty().html(response);

                $('.productTable .productTable_contentsWrap').css('visibility', 'visible');
                loading.create('.productTable').remove();
                    loading.hide();
            },
             error:function(err){
                 loading.hide();
             }
        });
    });
    $(document).ajaxComplete(function(){
        //FCCL-2819 : 13-5-2021 bangly start
        //Check flag
        if ($('#searchSuggestFlag').length > 0 && $('#searchSuggestFlag').val() == 1) {
            return;
        }
        //FCCL-2819 : 13-5-2021 bangly end
        $('.js-inline-modal-close').on('click', function() {
            $('.js-inline-modal').magnificPopup('close');
        });
        // お気に入り productTable
        var j;
//        $('.js-heightLine-pT-group1').each(function(){
        if($(".js-heightLine-pT-group1").length) {
            for(j = 1; j <= 20; j++){
              $('.js-heightLine-pT-group' + j).heightLine();
            }
        }
//            });
        // savedconf accordion
        var _maxHeight = 0;
        var _savedconfAcrdButton = $('.jsSavedconfAccordionBtn');
        var _savedconfAcrdTarget = $('.js-heightLine-pT-group17');
        _savedconfAcrdTarget.each(function(){
            if ($(this).outerHeight() > _maxHeight) {
                _maxHeight = $(this).outerHeight();
            }
        });
        // Start 201118 Add FCCL-2172: set button default view 「+」
        if (_savedconfAcrdButton.hasClass(_open)) {
            _savedconfAcrdButton.removeClass(_open);
        }
        // End 201118 Add FCCL-2172: set button default view 「+」
        _savedconfAcrdTarget.css({
            'height': _defaultHeight,
            'transition':'height 0.2s ease'
        });
        _savedconfAcrdButton.off().click(function(e){
            e.preventDefault();
            if($(this).hasClass(_open)){
                $(this).removeClass(_open);
                _savedconfAcrdTarget.css('height', _defaultHeight);
            } else{
                $(this).addClass(_open);
                _savedconfAcrdTarget.css('height', _maxHeight);
            }
        });
        // productTable
        if($('.js-productTable').length){
            $('.js-productTable').slick({
                slidesToScroll: 1,
                slidesToShow: 1,
                autoplay: false,
                arrows: true,
                infinite: false,
                variableWidth: true,
                responsive: [
                    {
                        breakpoint: 768,
                        settings: {
                          swipeToSlide: true
                        }
                    }
                ]
            });
        }
        // Modal popup
        $('.js-inline-modal')['magnificPopup']({
            type: 'inline',
            preloader: false,
            removalDelay: 900,
            fixedContentPos: true,
            fixedBgPos: true,
            callbacks: {
                beforeOpen: function(){
                },
                open: function(){
                },
                close: function(){
                }
            }
        });
        var trackingId = $('input#TrackingFilter').val();
        if(trackingId && trackingId != undefined && trackingId != ""){
            $('.productTableSection_filterList input#' + trackingId).trigger('click');
        }    
    });
    /** END T21 at 2020/05/26 **/

    //call login inti
    login.init();

    //display default less information
    setTimeout(function () {
        $('.productTable .productTable_contentsWrap').css('visibility', 'visible');
        loading.create('.productTable').remove();
    }, 1000);
};
