var util = require('../../util'),
    page = require('../../page'),
    ajax = require('../../ajax'),
    loading = require('../../loading');

var seriesSV = {
    /**
     * Initialize Series sv
     * 
     * @public
     */
    init: function () {
        this.onCheckSerialNumber();
    },
    onCheckSerialNumber: function() {
        var self = this;
        $('form[id$=checkserialnumber]').submit(function(e){
            e.preventDefault();
            loading.show();
            var serialnumber = $('input[name$=serialnumber]').val();
            var modelnumber = $('input[name$=modelnumber]').val();
            var url = util.appendParamsToUrl($(this).attr('action'), {
                modelnumber: modelnumber,
                serialnumber: serialnumber
            });
            //page.redirect(url);
            //return false;
            // FCCL-2070: Add csrf for ajax checking
            ajax.load({
                type: 'POST',
                url: url,
                data: {
                    serialnumber: serialnumber,
                    modelnumber: modelnumber,
                    csrf_token: $(this).children('input[name="csrf_token"]').val()
                },
                callback: function (response, textStatus) {
                    //console.log(response, textStatus);
                    loading.hide();
                    if (typeof(response) !== 'string') {
                        if(response && response.csrf_error) {
                            // Redirect to CSRF failed page whenever csrf validation failed
                            page.redirect(Urls.csrffailed);
                            return;
                        }
                        if (!response.success) {
                            alert(response.message);
                        }
                    } else {
                        //window.location.reload();
                        $('#result-service-series').html(response);
                        self.setSessionModelAndSerial();
                    }
                }
            });
        });
    },
    /**
     * 
     * @description Request Ajax set setSessionModelAndSerial
     * 
     * @returns {void}
     * 
     * @private
     */
    setSessionModelAndSerial: function() {
        $('button.b2b-serial-addtocart').on('click', function(e) {
            e.preventDefault();
            loading.show();
            var productID = $(this).attr('data-pid');
            // FCCL-2596 選択機種情報追加
            var ownedMachineWebtypeNumber = $('input[name$="checkserialnumber_modelnumber"]').val() || '';
            var ownedMachineSerialNumber = $('input[name$="checkserialnumber_serialnumber"]').val() || '';
            var urlRedirect = Urls.getProductUrl + '?pid=' + productID + '&ownedMachineWebtypeNumber=' + ownedMachineWebtypeNumber + '&ownedMachineSerialNumber=' + ownedMachineSerialNumber;
            ajax.load({
                type: 'POST',
                url: Urls.setModelserialSession + '?format=ajax',
                data: {
                    My_ProductName: $(this).attr('data-pname')
                },
                callback: function (response, textStatus) {
                    //console.log(response, textStatus);
                    //loading.hide();
                    page.redirect(urlRedirect);
                }
            });
        });
    }
}


module.exports = seriesSV;
