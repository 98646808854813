'use strict';

var util = require('./util'),
    _ = require('lodash');

var loading = require('./loading');

var modal = {
    /** object instance magnificPopup variable */
    $instance: null,

    /** target dialog variable */
    $dialog: null,

    /**
     * @function
     * @description Appends a dialog to a given container (target)
     * @param {Object} params  params.target can be an id selector or an jquery object
     */
    confirm: function (txtTitle, txtTitleSmall, callbackYes, callbackCancel) {
        if (!this.$instance) {
            this.$instance = $.magnificPopup.instance; // save instance in magnificPopup variable
        }

        if (!this.$dialog) {
            this.$dialog = $("#modal_dialog");
        }
        var self = this;

        this.$dialog.find(".categoryCustom_confirmText").html(txtTitle);
        this.$dialog.find(".categoryCustom_confirmText-small").html(txtTitleSmall).show();
        
        //show yes/no button, hide cancel button
        this.$dialog.find(".js-inline-modal-yes").show();
        this.$dialog.find(".js-inline-modal-no").show();
        this.$dialog.find(".js-inline-modal-cancel").hide();

        // trigger click Yes button
        this.$dialog.find(".js-inline-modal-yes").off().on('click',function() {
            self.close(); // Close popup that is currently opened (shorthand)
            if (typeof callbackYes == 'function') {
                callbackYes();
            }           
        });
        
        // trigger click Cancel button
        this.$dialog.find(".js-inline-modal-no").off().on('click',function() {
            self.close(); // Close popup that is currently opened (shorthand)
            // magnificPopup.updateItemHTML(); // updates the popup content. Useful after you change "items" array
            if (typeof callbackCancel == 'function') {
                callbackCancel();
            }
        });

        // Open directly via API
        this.$instance.open({
            items: {
                src: this.$dialog, // can be a HTML string, jQuery object, or CSS selector                
            },
            type: 'inline',
            preloader: false,
            removalDelay: 900,
            fixedContentPos: true,
            fixedBgPos: true,
            callbacks: {
                beforeOpen: function() {
                },
                open: function(){
                    // var _content = $('.mfp-content');
                },
                close: function() {
                },
                afterClose: function() {
                    // console.log('Popup is completely closed');
                }
            }
        });
    },
    // FCCL-3110 コールセンターで見積カートインするときにログアウト処理を追加する
    confirm_2: function (txtTitle, txtTitleSmall, callbackClearCartIn, callbackCartIn, callbackNo) {
        if (!this.$instance) {
            this.$instance = $.magnificPopup.instance;
        }

        if (!this.$dialog) {
            this.$dialog = $("#modal_dialog_2");
        }
        var self = this;

        this.$dialog.find(".categoryCustom_confirmText").html(txtTitle);
        this.$dialog.find(".categoryCustom_confirmText-small").html(txtTitleSmall).show();
        
        this.$dialog.find(".js-inline-modal-callbackClearCartIn").show();
        this.$dialog.find(".js-inline-modal-callbackCartIn").show();
        this.$dialog.find(".js-inline-modal-callbackNo").show();

        this.$dialog.find(".js-inline-modal-callbackClearCartIn").off().on('click',function() {
            self.close();
            if (typeof callbackClearCartIn == 'function') {
                callbackClearCartIn();
            }           
        });
        
        this.$dialog.find(".js-inline-modal-callbackCartIn").off().on('click',function() {
            self.close();
            if (typeof callbackCartIn == 'function') {
                callbackCartIn();
            }
        });

        this.$dialog.find(".js-inline-modal-callbackNo").off().on('click',function() {
            self.close();
            if (typeof callbackNo == 'function') {
                callbackNo();
            }
        });

        this.$instance.open({
            items: {
                src: this.$dialog,              
            },
            type: 'inline',
            preloader: false,
            removalDelay: 900,
            fixedContentPos: true,
            fixedBgPos: true,
            callbacks: {
                beforeOpen: function() {
                },
                open: function(){
                },
                close: function() {
                },
                afterClose: function() {
                }
            }
        });
    },
    /**
     * @function
     * @description Appends a dialog to a given container (target)
     * @param {Object} params  params.target can be an id selector or an jquery object
     */
    dialog: function (txtTitle, callback) {
        if (!this.$instance) {
            this.$instance = $.magnificPopup.instance; // save instance in magnificPopup variable
        }

        if (!this.$dialog) {
            this.$dialog = $("#modal_dialog");
        }
        var self = this;

        this.$dialog.find(".categoryCustom_confirmText").html(txtTitle);
        this.$dialog.find(".categoryCustom_confirmText-small").hide();
        
        //hide Yes/no button
        this.$dialog.find(".js-inline-modal-yes").hide();
        this.$dialog.find(".js-inline-modal-no").hide();
        this.$dialog.find(".js-inline-modal-cancel").show();

        // trigger click Cancel button
        this.$dialog.find(".js-inline-modal-cancel").off().on('click',function() {
            self.close(); // Close popup that is currently opened (shorthand)
            // magnificPopup.updateItemHTML(); // updates the popup content. Useful after you change "items" array
            if (typeof callback == 'function') {
                callback();
            }
        });

        // Open directly via API
        this.$instance.open({
            items: {
                src: this.$dialog, // can be a HTML string, jQuery object, or CSS selector                
            },
            type: 'inline',
            preloader: false,
            removalDelay: 900,
            fixedContentPos: true,
            fixedBgPos: true,
            closeOnContentClick:true,
            callbacks: {
                beforeOpen: function() {
                },
                open: function(){
                    // var _content = $('.mfp-content');
                },
                close: function() {
                },
                afterClose: function() {
                    // console.log('Popup is completely closed');
                }
            }
        });
    },
    // FCCL-3110 コールセンターで見積カートインするときにログアウト処理を追加する
    dialog_2: function (txtTitle, callback) {
        if (!this.$instance) {
            this.$instance = $.magnificPopup.instance;
        }

        if (!this.$dialog) {
            this.$dialog = $("#modal_dialog");
        }
        var self = this;

        this.$dialog.find(".categoryCustom_confirmText").html(txtTitle);
        this.$dialog.find(".categoryCustom_confirmText-small").hide();
        
        this.$dialog.find(".js-inline-modal-callbackClearCartIn").hide();
        this.$dialog.find(".js-inline-modal-callbackCartIn").hide();
        this.$dialog.find(".js-inline-modal-callbackNo").hide();
        this.$dialog.find(".js-inline-modal-cancel").show();

        this.$dialog.find(".js-inline-modal-cancel").off().on('click',function() {
            self.close();
            if (typeof callback == 'function') {
                callback();
            }
        });

        // Open directly via API
        this.$instance.open({
            items: {
                src: this.$dialog,             
            },
            type: 'inline',
            preloader: false,
            removalDelay: 900,
            fixedContentPos: true,
            fixedBgPos: true,
            closeOnContentClick:true,
            callbacks: {
                beforeOpen: function() {
                },
                open: function(){
                },
                close: function() {
                },
                afterClose: function() {
                }
            }
        });
    },
    /**
     * @function
     * @description Appends a dialog to a given container (target)
     * @param {Object} params  params.target can be an id selector or an jquery object
     */
    addWishlist: function (txtTitle, buttonUpdate, callbackYes, callbackCancel) {
        if (!this.$instance) {
            this.$instance = $.magnificPopup.instance; // save instance in magnificPopup variable
        }

        if (!this.$dialog) {
            this.$dialog = $("#modal_dialog");
        }
        var self = this;
        this.$dialog.find(".categoryCustom_confirmText").html(txtTitle);
        //show yes/no button, hide cancel button
        this.$dialog.find(".js-inline-modal-yes").html(buttonUpdate);
        this.$dialog.find(".js-inline-modal-yes").show();
        this.$dialog.find(".js-inline-modal-no").hide();
        this.$dialog.find(".js-inline-modal-cancel").show();

        // trigger click Yes button
        this.$dialog.find(".js-inline-modal-yes").off().on('click',function() {
            self.close(); // Close popup that is currently opened (shorthand)
            if (typeof callbackYes == 'function') {
                return callbackYes();
            }           
        });
        
        // trigger click Cancel button
        this.$dialog.find(".js-inline-modal-cancel").off().on('click',function() {
            self.close(); // Close popup that is currently opened (shorthand)
            // magnificPopup.updateItemHTML(); // updates the popup content. Useful after you change "items" array
            if (typeof callbackCancel == 'function') {
                return callbackCancel();
            }
        });

        // Open directly via API
        this.$instance.open({
            items: {
                src: this.$dialog, // can be a HTML string, jQuery object, or CSS selector                
            },
            type: 'inline',
            preloader: false,
            removalDelay: 900,
            fixedContentPos: true,
            fixedBgPos: true,
            closeOnContentClick:true,
            callbacks: {
                beforeOpen: function() {
                },
                open: function(){
                    // var _content = $('.mfp-content');
                },
                close: function() {
                    self.$dialog.find(".js-inline-modal-yes").html(Resources.BUTTON_YES);
                },
                afterClose: function() {
                    // console.log('Popup is completely closed');
                }
            }
        });
    },
    /**
     * @function
     * @description Closes the dialog
     */
    close: function () {
        this.$instance.close(); // Close popup that is currently opened (shorthand)
    },
    customPopup: function (txtTitle) {
        if (!this.$instance) {
            this.$instance = $.magnificPopup.instance; // save instance in magnificPopup variable
        }

        if (!this.$dialog) {
            this.$dialog = $("#modal_dialog");
        }

        var $dialog = this.$dialog;

        var self = this;

        $dialog.find(".categoryCustom_confirmText").html(txtTitle);
        
        var btnOccupation = document.createElement('button');
        // Change classList into className due to an error occurs when at strict mode page in IE11
        btnOccupation.className = 'categoryCustom_confirmButton categoryCustom_confirmButton-reset';
        btnOccupation.innerText = Resources.MODAL_BTN_OCCUPATION;

        var btnMypage = btnOccupation.cloneNode(true);
        btnMypage.innerText = Resources.MODAL_BTN_MYPAGE;

        var btnCancel = document.createElement('button');
        btnCancel.type = 'button';
        btnCancel.style.setProperty('display', 'block', 'important');
        btnCancel.style.setProperty('top', '-20px', 'important');
        btnCancel.style.setProperty('right', '-20px', 'important');
        // <button title="Close (Esc)" type="button" class="mfp-close">×</button>

        $dialog.find(".categoryCustom_confirmBox").append($(btnOccupation).addClass('btn-occupation')).show();
        $dialog.find(".categoryCustom_confirmBox").append($(btnMypage).addClass('btn-mypage')).show();
        $dialog.find(".categoryCustom_confirmBox").append($(btnCancel).addClass('mfp-close').addClass('btn-cancel')).show();
        
        

        $dialog.find(".btn-cancel").off().on('click',function() {
            self.close();
            $dialog.find(".btn-occupation").remove();
            $dialog.find(".btn-mypage").remove();
            $dialog.find(".btn-cancel").remove();
            $.ajax({
                method: 'POST',
                url: Urls.setOccupation + '?deleteSessionSrcCode=true',
                cache: false,
                contentType: 'application/json',
            }).done();
        });

        $dialog.find(".btn-mypage").off().on('click', function(){
            loading.show();
            $.ajax({
                method: 'POST',
                url: Urls.setOccupation + '?deleteSessionSrcCode=true',
                cache: false,
                contentType: 'application/json',
            }).done();
            window.location.href = Urls.login;
        });
        
        $dialog.find(".btn-occupation").off().on('click', function(){
            loading.show();
            $dialog.find(".btn-occupation").hide();
            $dialog.find(".btn-mypage").hide();
            $.ajax({
                method: 'POST',
                url: Urls.setOccupation,
                cache: false,
                contentType: 'application/json',
            }).done(function(res){
                loading.hide();
                if (res.success) {
                    self.close();
                }
                /**
                 * FCCL-2291: reload page when user click to reload all src code and CA accordingly
                 * FCCL-2741: Move window.location.reload(); to ajax.done for fixing not set source code group on firefox
                 *  */ 
                window.location.reload();
                // End of FCCL-2291
            }).fail(function(){
                loading.hide();
            });
        });

        // Open directly via API
        this.$instance.open({
            items: {
                src: this.$dialog, // can be a HTML string, jQuery object, or CSS selector                
            },
            type: 'inline',
            preloader: false,
            removalDelay: 900,
            fixedContentPos: true,
            fixedBgPos: true,
            callbacks: {
                beforeOpen: function() {
                },
                open: function(){
                    // var _content = $('.mfp-content');
                },
                close: function() {
                },
                afterClose: function() {
                    // console.log('Popup is completely closed');
                }
            }
        });
    },
    
};

module.exports = modal;