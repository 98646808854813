'use strict';

var dialog = require('../../dialog'),
    productCart = require('./productCart'),
    productStoreInventory = require('../../storeinventory/product'),
    tooltip = require('../../tooltip'),
    util = require('../../util'),
    //addToCart = require('./addToCart'),
    availability = require('./availability'),
    image = require('./image'),
    productNav = require('./productNav'),
    productSet = require('./productSet'),
    recommendations = require('./recommendations'),
    variant = require('./variant'),
    loading = require('../../loading'),
    productB2B = require('./productB2B'),
    config = require('../../../scripts/config'),
    page = require('../../page'),
    jqueryCustom = require('../../jquery-custom');
/**
 * @description Initialize product detail page with reviews, recommendation and product navigation.
 */
function initializeDom() {
    productNav();
    recommendations();
    tooltip.init();
}

/**
 * @description Initialize event handlers on product detail page
 */
function initializeEvents() {
    var $pdpMain = $('#pdpMain');
    var btnAddtoCart = $('button.productSpec_button');
    var idNameMap = initIdNameMap();
    
    
    //addToCart();
    availability();
    variant();
    image();
    productSet();
    if (SitePreferences.STORE_PICKUP) {
        productStoreInventory.init();
    }
    // update here
    productCart.init();
       
    // Add to Wishlist and Add to Gift Registry links behaviors
    $pdpMain.on('click', '[data-action="wishlist"], [data-action="gift-registry"]', function () {
        var data = util.getQueryStringParams($('.pdpForm').serialize());
        if (data.cartAction) {
            delete data.cartAction;
        }
        var url = util.appendParamsToUrl(this.href, data);
        this.setAttribute('href', url);
    });

    // product options
    $pdpMain.on('change', '.product-options select', function () {
        var salesPrice = $pdpMain.find('.product-add-to-cart .price-sales');
        var selectedItem = $(this).children().filter(':selected').first();
        salesPrice.text(selectedItem.data('combined'));
    });

    // prevent default behavior of thumbnail link and add this Button
    $pdpMain.on('click', '.thumbnail-link, .unselectable a', function (e) {
        e.preventDefault();
    });

    $('.size-chart-link a').on('click', function (e) {
        e.preventDefault();
        dialog.open({
            url: $(e.target).attr('href')
        });
    });
    
    /**
     * Page T42 only
     */
    if($('form.page-t42').length > 0) {
        $('form.page-t42').on('keyup keypress', function(e) {
            var keyCode = e.keyCode || e.which;
              if (keyCode === 13) { 
                e.preventDefault();
                return false;
              }
        });
        productB2B.init();
    }
    
    /**
     * Page T07 only
     */
    if($('form.page-t07').length > 0) {
        $('form.page-t07').on('keyup keypress', function(e) {
            var keyCode = e.keyCode || e.which;
              if (keyCode === 13) { 
                e.preventDefault();
                return false;
              }
        });
        
        $('select#quantity').on('change', function(e) {
            var qty = parseInt($("#quantity").val(), 10);
            var salePrice = $('strong#salePrice').attr('data-price');
            var webPrice = $('dd#webPrice').attr('data-price');
            salePrice = salePrice*qty;
            webPrice = webPrice*qty;
            if(webPrice){
                 var newWebPrice = webPrice.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1"+ config.CurrencySeparate);
                 var newSalePrice = salePrice.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1"+ config.CurrencySeparate);
                 $("#webPrice").html(newWebPrice + config.CurrencySymbol);
                 $("#salePrice").html(newSalePrice + "<span>" + config.CurrencySymbol + "</span>");
                 $("#salePrice-mobile").html(newSalePrice + config.CurrencySymbol);
            } else {
                var newSalePrice = salePrice.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1"+ config.CurrencySeparate);
                $("#salePrice").html(newSalePrice + "<span>" + config.CurrencySymbol + "</span>");
                $("#salePrice-mobile").html(newSalePrice + config.CurrencySymbol);
            }
        });
        
        $('.productSpec_inputNumber').on('change', function (e) {
            e.preventDefault();
            var qty = 0;
            var control = $(this);
            
            qty = changeInputNumber(qty, control, btnAddtoCart, btnEnable, btnDisable);
            
            var salePrice = $('strong#salePrice').attr('data-price');
            var webPrice = $('dd#webPrice').attr('data-price');
            salePrice = salePrice*qty;
            webPrice = webPrice*qty;
            if(webPrice || webPrice == 0){
                 var newWebPrice = webPrice.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1"+ config.CurrencySeparate);
                 var newSalePrice = salePrice.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1"+ config.CurrencySeparate);
                 $("#webPrice").html(newWebPrice + config.CurrencySymbol);
                 $("#salePrice").html(newSalePrice + "<span>" + config.CurrencySymbol + "</span>");
                 $("#salePrice-mobile").html(newSalePrice + config.CurrencySymbol);
            } else {
                var newSalePrice = salePrice.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1"+ config.CurrencySeparate);
                $("#salePrice").html(newSalePrice + "<span>" + config.CurrencySymbol + "</span>");
                $("#salePrice-mobile").html(newSalePrice + config.CurrencySymbol);
            }
            
        });
    }
    
    /**
     * Page T06 only
     */
    if($('form.page-t06').length > 0) {
        $('form.page-t06').on('keyup keypress', function(e) {
            var keyCode = e.keyCode || e.which;
              if (keyCode === 13) { 
                e.preventDefault();
                return false;
              }
        });
        var deliveryTimeMapAndCode = $.parseJSON($('input[name="DeliveryTimeAndCode"]').val());
        var siteCatalog = $('input#siteCatalog').val();
        var specMap = {};
        //init Specmap form data check BTO
        initSpecMap(specMap);
        $('dl#subBTOS').hide();
        var paramsBTO = $('input#paramsBTO').attr('value');
        if(paramsBTO !== '') {
            if(rsModelType) {
                rewriteDelivetyTime();
            } else if (siteCatalog == 'Consumer') {
                rewriteDelivetyTimeFalse();
            }
            recheckCustomize(paramsBTO);
            initDetailBTO();
            initSpecMap(specMap);
            initCheckCustomize(specMap, webTypeControlStr, idNameMap);
            if(rsModelType && deliveryTimeMapAndCode != false) {
                var initValueAfterWebtypess = initValueAfterWebtype();
                fillDataDeliveryTime(initValueAfterWebtypess, webTypeControlStr.arrange);
            }
            productCart.changePrice();
        } else {
            if(rsModelType) {
                rewriteDelivetyTime();
            } else if (siteCatalog == 'Consumer') {
                rewriteDelivetyTimeFalse();
            }
            initCheckCustomize(specMap, webTypeControlStr, idNameMap);
            if(rsModelType && deliveryTimeMapAndCode != false) {
                var initValueAfterWebtypess = initValueAfterWebtype();
                fillDataDeliveryTime(initValueAfterWebtypess, webTypeControlStr.arrange);
            }
            productCart.changePrice();
            //FCCL-2188 check bto in right when return from cart
            initDetailBTO();
        }
        
        //check user login and return a id of a product
        var customerId = $('ul.SRV_MyCloudPremium').attr('customerid');
        if(customerId == 'FMWMVPR012-1' ) {
                $('li.FMWMVPR012-1').hide();
                //$('li.AA').hide();
        } else if (customerId == 'FMWMVPR033-1') {
                $('li.FMWMVPR012-1').hide();
                $('li.FMWMVPR033-1').hide();
                //$('li.0AA').hide();
        } else if (customerId == 'SVUCXPR040-2' || customerId == 'SVUCXPR040-3') {
                $('li.FMWMVPR012-1').hide();
                $('li.FMWMVPR033-1').hide();
                $('li.SVUCXPR040-2').hide();
                $('li.SVUCXPR040-3').hide();
                //$('li.0AA').hide();
        } else if (customerId == 'SVUCXPR050-2') {
                //$('li.0AA').hide();
                $('div.SRV_MyCloudPremium').hide();
        } else {
            //$('li.AA').hide();
        }
        
//        //if ckeck any radio in mycloud premium, then PC tutor change following that value
//        var $myCloudContainer = $('div.SRV_MyCloudPremium');
//        $myCloudContainer.on('click','input.product-set-item', function (e) {
//              var control = $(this);
//              var controlValue = control.attr('value');
//              if(controlValue === 'FMWMVPR012-1' || controlValue.indexOf('nonentry') != -1) {
//                  if ($('li.AA').length > 0) {
//                      if(window.confirm(errMsg5)) {
//                          $('li.0AA').show();
//                          $('li.AA').hide();
//                      } else {
//                          return false;
//                      }
//                  }
//              } else {
//                  if ($('li.0AA').length > 0) {
//                      if(window.confirm(errMsg5)) {  
//                          $('li.AA').show();
//                          $('li.0AA').hide();
//                      } else {
//                          return false;
//                      }
//                  }
//                  
//              }
//        });
        
        /**
         * check when click in BTOs
         *
         */
        var $btoContainer = $('div.categoryCustom_inner div.BTO-item');
        $btoContainer.on('click', 'input.product-set-item', function (e) {
            var control = $(this);
            initSpecMap(specMap);
            if(rsModelType) {
                rewriteDelivetyTime();
            }
            //change detail BTO in right
            changeDeTailBTO(control);
            
            //reset arrange to empty array
            var arrage = $('div.ARRANGE-item input.product-set-item-checkbox');
            specMap[arrage.attr('name')] = [];
            uncheckInputCheckBoxArrange();
            
            var cloneMap = {};
            $.extend(cloneMap, specMap);
            // Add property for cloneMap
            cloneMap[control.attr('name')] = control.attr('value');
            specMap[control.attr('name')] = control.attr('value');
            
            //check spec map
            initCheckCustomize(specMap, webTypeControlStr, idNameMap);
            if(rsModelType && deliveryTimeMapAndCode != false) {
                var initValueAfterWebtypess = initValueAfterWebtype();
                fillDataDeliveryTime(initValueAfterWebtypess, webTypeControlStr.arrange);
            }
            setStockLimited();
        });
        
        setStockLimited();
    }

    /**
     * T06 + T07
     */
    
    /**
     * function 37.
     * if choose any model -> comfirm box ask -> go to that model
     */
    var $relatedProductContainer = $('div.model_inner');
    var checked = $('input.related-product[checked="checked"]');
    $relatedProductContainer.on('click', 'input.related-product', function(e) {
        e.preventDefault();
        var control = $(this);
        var url = control.attr('url');
        var msg = control.attr('msg');
        if(window.confirm(msg)) {
            page.redirect(url);
        } else {
            checked.prop("checked", true);
        }
    });
    
    /**
     * function 41. click button customize 
     */
    var $buttonContainer = $('div.productSpec_inner');
    $buttonContainer.on('click', '.productSpec_button-customize', function(e) {
        e.preventDefault();
        var control = $(this);
        var paramsURl = '';
        var leBTO = $('.categoryCustom_inner input.product-set-item').length;
        $('.categoryCustom_inner input.product-set-item').each(function(i) {
            if ($(this).prop("checked") == true) {
                if(i < leBTO -1) {
                    paramsURl += $(this).attr('valueWMN') + '|';
                } else {
                    paramsURl += $(this).attr('valueWMN');
                } 
                
            }
        })
        var url = control.attr('url')+'?bto=' + paramsURl;
        loading.show();
        page.redirect(url);
    });
    
    /**
     * reload page when click button yes
     */
    $('.pt_product-details  .categoryCustom_confirmButton-reset').on('click', function(e) {
        page.refresh();
    })
    
}

/**
 * function use to set stock limited for PDP
 * @returns
 */
function setStockLimited() {
    $('.BTO-item input.product-set-item').each(function(e) {
        if ($(this).prop("checked") == true && $(this).attr('psprdtype').indexOf('PC') >= 0) {
            var selector = 'input[name$="'+ $(this).val() + '"]';
            var $stockLimited = $(selector).val();
            $('p.categoryMv_attention').text($stockLimited);
        }
    })
}

function changeInputNumber(qty, control, btnAddtoCart,btnEnable ,btnDisable) {
    if(control.val() == null || control.val() == undefined || control.val() == "") {
        qty = 0;
    } else {
        qty = parseInt(control.val(), 10);
    }
    
    if(qty > 999 || qty < 1) {
        btnAddtoCart.attr("disabled", true);
        btnAddtoCart.css('background-color', '#606A76');
        btnAddtoCart.text(btnDisable);
        window.alert(inputNunmberValidate);
    } else {
        var isEnable = btnAddtoCart.prop('disabled');
        if(isEnable) {
            btnAddtoCart.removeAttr("disabled");
            btnAddtoCart.css('background-color', '#C00');
            btnAddtoCart.text(btnEnable);
        }
    }
    return qty;
}

/**
 * recheck input BTO when click button customize
 * @param paramsBTO
 * @returns
 */
function recheckCustomize(paramsBTO) {
    var arrayParamsBTO = paramsBTO.split('|');
    for(let i in arrayParamsBTO) {
        $('.BTO-item input.product-set-item').each(function(e) {
            if($(this).attr('valueWMN') === arrayParamsBTO[i]) {
                let psname = '#'+ $(this).attr('psname') + '-item input.product-set-item';
                $(psname).each(function(e) {
                    $(this).prop("checked", false);
                    $(this).removeAttr("checked");
                })
                $(this).prop("checked", true);
                $(this).attr("checked", 'checked');
            }
        })
    }
}

function initDetailBTO() {
    $('.BTO-item input.product-set-item').each(function(e) {
        if ($(this).prop("checked") == true) {
            let prdType =  $(this).attr('psname');
            let standard = $(this).attr('standard');
            let dl = $('dl#subBTOS');
            if($(this).attr('psprdtype') === config.pcProductType.RS_OS 
                    || $(this).attr('psprdtype') == config.pcProductType.RS_CPU
                    || $(this).attr('psprdtype') === config.pcProductType.RS_Memory
                    || $(this).attr('psprdtype') == config.pcProductType.RS_Storage) {
                $('dd.' + prdType).text($(this).attr('pSpecItem')); 
            } else if($(this).attr('psprdtype').indexOf('PC') === -1) {
                if(!standard) {
                    let lengthDetail = $('dt#subBTOPS_name').length;
                    if(lengthDetail == 0) {
                        dl.show();
                    }
                    dl.append('<dt class="' + prdType + '" id="subBTOPS_name">' + $(this).attr('psvalue') + '</dt>'); 
                    dl.append('<dd class="' + prdType + '" id="PSP_name">' + $(this).attr('pSpecItem') + '</dd>');
                }
            }
        }
    })
}

/**
 * change the name of detail BTo in the right
 * @param name
 * @returns 
 */
function changeDeTailBTO(control) {
    var prdType =  control.attr('psname');
    var standard = control.attr('standard');
    var dl = $('dl#subBTOS');
    
    if(control.attr('psprdtype') == 'RS_OS' 
            || control.attr('psprdtype') == 'RS_Memory'
            || control.attr('psprdtype') == 'RS_CPU' 
            || control.attr('psprdtype') == 'RS_Storage') {
        $('dd.' + prdType).text(control.attr('pSpecItem')); 
    } else {
        if(!standard) {
            let lengthDetail = $('dt#subBTOPS_name').length;
            if(lengthDetail == 0) {
                dl.show();
            } else {
                $('dt.' + prdType).remove();
                $('dd.' + prdType).remove();
            }
            
            dl.append('<dt class="' + prdType + '" id="subBTOPS_name">' + control.attr('psvalue') + '</dt>'); 
            dl.append('<dd class="' + prdType + '" id="PSP_name">' + control.attr('pSpecItem') + '</dd>');
        } else {
            let lengthExsit = $('dt.' + prdType).length;
            if(lengthExsit > 0) {
                $('dt.' + prdType).remove();
                $('dd.' + prdType).remove();
            }
            let lengthDetail = $('dt#subBTOPS_name').length;
            if(lengthDetail == 0) {
                $('dl#subBTOS').hide();
            }
        }
    }
} 

/**
 * init specmap when load page 
 * @param specMap
 * @returns
 */
function initSpecMap(specMap) {
    $('input.product-set-item').each(function (e) {
        if ($(this).prop("checked") == true) {
            if($(this).attr('category') === 'BTO') {
                specMap[$(this).attr('name')] = $(this).attr('value');
            }
        }
    });
    var arrage = $('div.ARRANGE-item input.product-set-item-checkbox');
    specMap[arrage.attr('name')] = [];
}

/**
 * main function to render delivery time case null
 * @param initValueAfterWebtypess
 * @param arrange
 * @returns
 */
function fillDataDeliveryTime(initValueAfterWebtypess , arrange) {
    var matchConditionArrangeMap = getValueMatchCondition(arrange);
    var arr = checkArrangeMatch(initValueAfterWebtypess, matchConditionArrangeMap);
    initNullDeliveryTime(initValueAfterWebtypess, arr);
}

/**
 * check maping arrange and Match condition
 * @param arrange
 * @param matchMap
 * @returns
 */
function checkArrangeMatch(arrange, matchMap) {
    var arrArrange = arrange.arrArrange;
    var arr = [];
    for(var i in arrArrange) { 
        for(var j in matchMap) {
            var lastElement = matchMap[j].length - 1;
            if(arrArrange[i] == matchMap[j][lastElement]) {
                arr.push(matchMap[j]);
            }
        }
    }
    return arr;
}

/**
 * main function to check and render delivery time for null delivery time product
 * @param condition
 * @param matchMap
 * @returns
 */
function initNullDeliveryTime(condition, matchMap) {
    var maxDeliveryTime = deliveryTimeArrangeMax(condition.arrArrange);
    var arrCondition = condition.arr;
    var object = {};
    var index = 0;
    for(var i in matchMap) {
        var lastElement = matchMap[i].length - 1;
        var count = 0;
        for(var j in matchMap[i]) {
            if(j == lastElement) {
                break;
            }
            if (arrCondition[matchMap[i][j]] || arrCondition[matchMap[i][j]] == '') {
                count++;
            }
        }
        if(count == lastElement) {
            index ++;
            for(var z in matchMap[i]) {
                if(arrCondition[matchMap[i][z]] == '') {
                    var $label = $('label.' + matchMap[i][z]);
                    var dd = $label.find('dd.categoryCustom_dataDate');
                    dd.text(maxDeliveryTime);
                }
            }
        }
    }
    return object;
}

/**
 * init value after use webtype function
 * @returns
 */
function initValueAfterWebtype(){
    var deliveryTimeMapAndCode = $.parseJSON($('input[name="DeliveryTimeAndCode"]').val());
    var arr = {};
    var arrArrange = [];
    $('input.product-set-item').each(function (e) {
        var value = $(this).attr('value');
        if($(this).attr('category') === 'BTO') {
            if ($(this).prop("checked") == true) {
                if(!deliveryTimeMapAndCode[value].time) {
                    arr[value] = '';
                } else {
                    arr[value] = deliveryTimeMapAndCode[value].time;
                }
            }
        }
    });
    $('input.product-set-item-checkbox').each(function (e) {
        var value = $(this).attr('value');
        if($(this).attr('category') === 'BTO') {
            if ($(this).prop("checked") == true) {
                arrArrange.push(value);
                if(!deliveryTimeMapAndCode[value].time) {
                    arr[value] = '';
                } else {
                    arr[value] = deliveryTimeMapAndCode[value].time;
                }
            }
        }
    })
    return {
        arrArrange: arrArrange,
        arr: arr
    };
}

/**
 * get value of product in matchconition in arrange condition
 * @param webtypecontrolStrArrange
 * @returns
 */
function getValueMatchCondition(webtypecontrolStrArrange) {
    var object = {};
    var arr = [];
    var index = 0;
    for(var i in webtypecontrolStrArrange) {
        var condition = webtypecontrolStrArrange[i];
        let arrTemp = [];
        if(condition && condition != null) {
            var matchCondition = condition.MATCH;
            if(matchCondition && matchCondition != null) {
                for(var j in matchCondition) {
                    arrTemp.push(matchCondition[j]);
                    if(j.indexOf('arrange') < 0) {
                        if(!object[j]) {
                            object[j] = [];
                        }
                        if(object[j].indexOf(matchCondition[j]) < 0 ) {
                            object[j].push(matchCondition[j]);
                        }
                    }
                }
            }
        }
        var arrange = getLastProperty(condition);
        arrTemp.push(condition[arrange]);
        arr.push(arrTemp);
        index++;
    }
    return arr;
}

/**
 * Checks if the given delivery code is considered a short delivery code.
 *
 * @param {number} deliveryCode - The delivery code to check.
 * @returns {boolean} - Returns `true` if the delivery code is a short delivery code, otherwise `false`.
 */
function isShortDeliveryCode(deliveryCode) {
    var longDeliveryCodes = config.LONG_DELIVERY_TIME_CODES || [2, 3, 25, 26, 10, 4, 5, 6, 7];
    return !longDeliveryCodes.includes(deliveryCode)
}

/**
 * rewrite delivety time
 * @returns
 */
function rewriteDelivetyTime() {
    var deliveryTimeMapAndCode = $.parseJSON($('input[name="DeliveryTimeAndCode"]').val());
    var siteCatalog = $('input#siteCatalog').val();
    if(deliveryTimeMapAndCode != false) {
        var objectAll = {};
        $('input.product-set-item').each(function (e) {
            var value = $(this).attr('value');
            if($(this).attr('category') === 'BTO') {
                //FCCL-2525 check delivery time for B2B
                if(siteCatalog == "Business") {
                    if(!deliveryTimeMapAndCode[value].time) {
                        if($(this).attr('pdummy') == 'true' && ($(this).val().indexOf('Standard_Warranty_Biz') >= 0 || $(this).attr('pname').indexOf('なし') >= 0)) {
                            objectAll[value] = '';
                        } else {
                            objectAll[value] = '他の構成による';
                        }
                    } else {
                        objectAll[value] = deliveryTimeMapAndCode[value].time;
                    }
                } else {
                    if(!deliveryTimeMapAndCode[value].time) {
                        objectAll[value] = '他の構成による';
                    } else if (isShortDeliveryCode(deliveryTimeMapAndCode[value].code)) {
                        objectAll[value] = '';
                    } else {
                        objectAll[value] = deliveryTimeMapAndCode[value].time;
                    }
                }
            }
        })
        $('input.product-set-item-checkbox').each(function (e) {
            var value = $(this).attr('value');
            if($(this).attr('category') === 'BTO') {
                //FCCL-2525 check delivery time for B2B
                if(siteCatalog == "Business") {
                    if(!deliveryTimeMapAndCode[value].time) {
                        if($(this).attr('pdummy') == 'true' && ($(this).val().indexOf('Standard_Warranty_Biz') >= 0 || $(this).attr('pname').indexOf('なし') >= 0)) {
                            objectAll[value] = '';
                        } else {
                            objectAll[value] = '他の構成による';
                        }
                    } else {
                        objectAll[value] = deliveryTimeMapAndCode[value].time;
                    }
                } else {
                    if(!deliveryTimeMapAndCode[value].time) {
                        objectAll[value] = '他の構成による';
                    } else if (isShortDeliveryCode(deliveryTimeMapAndCode[value].code)) {
                        objectAll[value] = '';
                    } else {
                        objectAll[value] = deliveryTimeMapAndCode[value].time;
                    }
                }
            }
        })
        
        for(var i in objectAll) {
            var selector = 'label[name$="'+ i + '"]';
            var $label = $(selector);
            var dd = $label.find('dd.categoryCustom_dataDate');
            dd.text(objectAll[i]);
        }
    }
} 

/**
 * rewrite delivety time if rsModelType is false
 * @returns
 */
function rewriteDelivetyTimeFalse() {
    var deliveryTimeMapAndCode = $.parseJSON($('input[name="DeliveryTimeAndCode"]').val());
    var isDeliveryTimeMapAndCode = deliveryTimeMapAndCode != false;

    $('dd.categoryCustom_dataDate').each(function (e) {
        var productId = $(this).closest('label').attr('name');
        var text = $(this).text().replace(/\n/g, '');

        if (isDeliveryTimeMapAndCode && deliveryTimeMapAndCode[productId] && isShortDeliveryCode(deliveryTimeMapAndCode[productId].code)) {
            $(this).text('');
        } else if (text == "") {
            $(this).text('他の構成による');
        }
    })
}

/**
 * check max delivery time
 * @param specMap
 * @returns
 */
function deliveryTimeArrangeMax(specMap) {
    var deliveryTimeMapAndCode = $.parseJSON($('input[name="DeliveryTimeAndCode"]').val());
    var deliveryCodeRanking = config.DELIVERY_CODE_RANKING;
    var idxMax = -1;
    var deiveryTimeMax = "";
    var isConsumerCatalog = $('input#siteCatalog').val() == 'Consumer';
 
    for(var i in specMap) {
        var deliveryTimeMapAndCodeValue = deliveryTimeMapAndCode[specMap[i]];
        if (deliveryTimeMapAndCodeValue) {
            if (isConsumerCatalog && isShortDeliveryCode(deliveryTimeMapAndCodeValue.code)) {
                continue;
            }

            var idx = deliveryCodeRanking.indexOf(deliveryTimeMapAndCodeValue.code);
            if (deliveryTimeMapAndCodeValue.code) {
                if (idxMax < idx) {
                    idxMax = idx;
                    deiveryTimeMax = deliveryTimeMapAndCodeValue.time;
                }
            }
        }
    }
    return deiveryTimeMax;
}

/**
 * init object contain Id and name of product BTO
 * @param idNameMap
 * @returns
 */
function initIdNameMap() {
    var idNameMap = {};
    $('input.product-set-item').each(function (e) {
        if($(this).attr('category') === 'BTO') {
            idNameMap[$(this).attr('value')] = $(this).attr('pname');
        }
    });
    $('input.product-set-item-checkbox').each(function (e) {
        if($(this).attr('category') === 'BTO') {
            idNameMap[$(this).attr('value')] = $(this).attr('pname');
        }
    });
    return idNameMap;
}

/**
 * check spec map when first load page
 * @param specMap
 * @param webTypeControlStr
 * @returns
 */
function initCheckCustomize(specMap, webTypeControlStr, idNameMap) {
    var deliveryTimeMapAndCode = $.parseJSON($('input[name="DeliveryTimeAndCode"]').val());
    
    var result = getPdpDetail(specMap, webTypeControlStr, idNameMap);
    deliveryTimeChange(specMap, deliveryTimeMapAndCode);
    var btnAddtoCart = $('button.productSpec_button');
    /**
     * check result of getpdpDetail. if error then Bto still check but button addtocart disable and change text.
     * if no error then enable button and then change text
     */
    if(result.errMsg) {
        btnAddtoCart.attr("disabled", true);
        btnAddtoCart.css('background-color', '#606A76');
        btnAddtoCart.text(btnDisable);
        alert(result.msg);
    } else {
        var isEnable = btnAddtoCart.prop('disabled');
        if(isEnable) {
            btnAddtoCart.removeAttr("disabled");
            btnAddtoCart.css('background-color', '#C00');
            btnAddtoCart.text(btnEnable);
        }
    }
}

function deliveryTimeChange(specMap, deliveryTimeMapAndCode) {
    var deliveryCodeRanking = config.DELIVERY_CODE_RANKING;
    var idxMax = -1;
    var deiveryTimeMax = "";
    $.each(specMap, function(key, value) {
        if(key.indexOf('arrange') == -1) {
            var deliveryTimeMapAndCodeValue = deliveryTimeMapAndCode[value];
            if (deliveryTimeMapAndCodeValue) {
                var idx = deliveryCodeRanking.indexOf(deliveryTimeMapAndCodeValue.code);
                if (deliveryTimeMapAndCodeValue.code) {
                    if (idxMax < idx) {
                        idxMax = idx;
                        deiveryTimeMax = deliveryTimeMapAndCodeValue.time;
                    }
                }
            }
        } else {
            for(let va in value) {
                var deliveryTimeMapAndCodeValue = deliveryTimeMapAndCode[value[va]];
                if (deliveryTimeMapAndCodeValue) {
                    var idx = deliveryCodeRanking.indexOf(deliveryTimeMapAndCodeValue.code);
                    if (deliveryTimeMapAndCodeValue.code) {
                        if (idxMax < idx) {
                            idxMax = idx;
                            deiveryTimeMax = deliveryTimeMapAndCodeValue.time;
                        }
                    }
                }
            }
        }
    });
    
    //FCCL-2631 check break line if > 16 chars
    if(deiveryTimeMax.length > 9) {
    	 $('.productSpec_text').addClass('reduce_size').html('お届け目安：<br/>' + deiveryTimeMax);
        //$('.productSpec_text').html('お届け目安：<br/>' + deiveryTimeMax);
    } else {
        if($('.productSpec_text span').length == 0) {
            $('.productSpec_text').html('お届け目安：<span>' + deiveryTimeMax + '</span>');
        } else {
            $('.productSpec_text span').text(deiveryTimeMax);
        }
    }
    // FCCL-3152 【F-105（1）】スマートフォンの場合のPDPの価格表示のデザイン変更
    $('.productSpec-mobile-deliverytime').html('お届け目安：<span>' + deiveryTimeMax + '</span>');
}

/**
 *  main function control display of BTO product
 * @param specMap
 * @param webTypeControl
 * @returns specmap Result
 */
function getPdpDetail(specMap, webTypeControl, idNameMap) {
    //check webtypeControl
    if(webTypeControl === null) {
        return specMap;
    } else {
        //check Arrange
        if(webTypeControl.arrange != null) {
            //get spec map from Arrange
            specMap = checkWebTypeControlArrange(specMap, webTypeControl.arrange);
        }
        specMap = setWebTypePC(webTypeControl.map, specMap);
        for(var i in specMap) {
            if (i.indexOf('pc') !== -1) {
                var webTypePC = specMap[i];
                break
            }
        }
        specMap = setWebTypeOS(webTypeControl.replace,specMap,webTypePC); //webTypePC
        
        let resultRequire = checkWebTypeControlRequire(specMap,webTypeControl.require);
        if(resultRequire.result == false) {
            let object = resultRequire.causeID;
            let errMsg = '';
            for(let i in object) {
                let array = object[i];
                if(array.length == 1) {
                    errMsg += convertIDToNameBTO(array[0], idNameMap) + errMsg6Last + "\r\n";
                } else {
                    for(let j in array) {
                        if(j == 0) {
                            errMsg += convertIDToNameBTO(array[j], idNameMap) + errMsg6And + errMsg6Comma + ' ';
                        } else if (j == (array.length -1)) {
                            errMsg += convertIDToNameBTO(array[j], idNameMap) + errMsg6Last + "\r\n";
                        } else {
                            errMsg += convertIDToNameBTO(array[j], idNameMap) + errMsg6Comma + ' ';
                        }
                    }
                }
            }
            return {
                errMsg: 'errMsg6',
                msg: errMsg
            };
        }
        
        let resultExclude = checkWebTypeControlExclude(specMap,webTypeControl.exclude);
        if(resultExclude.result == false) {
            let object = resultExclude.causeID;
            let errMsg = errMsg4 + convertIDToNameBTO(resultExclude.causeID, idNameMap) + errMsg4Last;
            return {
                errMsg: 'errMsg4',
                msg: errMsg
            };
        }
    }
    
    return specMap;
}

/**
 *  function 4, check WebTypeControl Arrange
 * @param specmap
 * @param webTypeArrange
 * @returns specmap Result
 */
function checkWebTypeControlArrange(specMap, webTypeArrangeLarge) {
    if(webTypeArrangeLarge != null) {
        for(var z in webTypeArrangeLarge) {
            var matchCondition = webTypeArrangeLarge[z];
            if (matchCondition != null) {
                //check MATCH condition, if null, return spec map
                if(matchCondition.MATCH != null) {
                    //if there is MATCH condition exist but no property inside, set webmodelNumber for it 
                    if(isEmpty(matchCondition.MATCH) === false) {
                        //check specmap Match with match_codition 
                        if(checkMatchCondition(specMap,matchCondition.MATCH)) {
                            for(var i in specMap) {
                                if(i.indexOf('arrange') !== -1) {
                                    var replace = getLastProperty(matchCondition);
                                    specMap[i].push(matchCondition[replace]);
                                    recheckInputCheckBoxArrange(matchCondition[replace],'ARRANGE');
                                }
                            }
                        }
                    } else {
                        for(var i in specMap) {
                            if(i.indexOf('arrange') !== -1) {
                                var replace = getLastProperty(matchCondition);
                                specMap[i].push(matchCondition[replace]);
                                recheckInputCheckBoxArrange(matchCondition[replace],'ARRANGE');
                            }
                        }
                    }
                }
            }
        }
    }
    
    return specMap;
}

/**
 * function 8, check match condition result
 * @param specmap
 * @param matchCondition
 * @returns 
 */
function checkMatchCondition(specMap, matchCondition) {
    var count = 0;
    var matchLength = getSize(matchCondition);
    for (var i in matchCondition) {
        if(i.indexOf('arrange') !== -1) {
            for(let j in specMap[i]) {
                if (specMap[i][j] === matchCondition[i]) {
                    count++;
                    break;
                }
            }
        } else {
            for (var j in specMap) {
                if(i === j) { //
                    if (specMap[j] === matchCondition[i]) {
                        count++;
                        break;
                    }
                } 
            }
        }
    }
    if(count === matchLength) {
        return true;
    }
    return false;
}

/**
 *  function 5, check WebTypeControl Require
 * @param specmap
 * @param webTypeRequire
 * @returns specmap Result
 */
function checkWebTypeControlRequire(specMap, webTypeRequire) {
    if (webTypeRequire != null) { 
        for(var j in specMap) {
            var checkStatusRequire = checkStatusRequireOrExclude(webTypeRequire, j, specMap[j], specMap, 'require');
            if(checkStatusRequire.result != undefined) {
                if(!checkStatusRequire.result) {
                    return {
                        result: false,
                        causeID: checkStatusRequire.causeID
                    }
                }
            }
        } 
    }
    return specMap;
}

/**
 *  function 6, check WebTypeControl Exclude
 * @param specmap
 * @param webTypeRequire
 * @returns specmap Result
 */
function checkWebTypeControlExclude(specMap, webTypeExclude) {
    if (webTypeExclude != null) {
        for(var j in specMap) {
            var checkStatusExclude3 = checkStatusExclude(webTypeExclude, j, specMap[j], specMap, 'exclude'); //remember to revert
            if(checkStatusExclude3.result != undefined) {
                if(!checkStatusExclude3.result) {
                    return {
                        result: false,
                        causeID: checkStatusExclude3.causeID
                    }
                }
            }
        } 
    }
    return specMap;
}

/**
 * function 9
 * @param productComponent
 * @param specMapPsid 
 * @param specMapWMN 
 * @param specMap 
 * @param mode 
 * @returns isMatch
 */
function checkStatusExclude(productComponent, specMapPsid, specMapWMN, specMap, mode) {
    var result = true;
    var mapKey = matchingKey(productComponent, specMapPsid); //'osSetWN1'
    if(mapKey != null) {
        if(specMapPsid.indexOf('arrange') !== -1 && mode === 'exclude') {
            for(let i in specMapWMN) {
                var mapKeyPC = matchingKey(mapKey, specMapWMN[i]); //'OSWIN10P64ADV'
                if(mapKeyPC != null) {
                    var matchResult = checkMatchResultExclude(mapKeyPC, specMap, mode);
                    if(matchResult.result) {
                        result = {
                                result: true,
                                causeID: {}
                        };
                    } else {
                        result = {
                                result: false,
                                causeID: matchResult.causeID
                        };
                        break;
                    }
                }
            }
        } else {
            var mapKeyPC = matchingKey(mapKey, specMapWMN); //'OSWIN10P64ADV'
            if(mapKeyPC != null) {
                var matchResult = checkMatchResultExclude(mapKeyPC, specMap, mode);
                if(matchResult.result) {
                    result = {
                            result: true,
                            causeID: {}
                    };
                } else {
                    result = {
                            result: false,
                            causeID: matchResult.causeID
                    };
                }
            }
        }
    }
    return result;
}

/**
 * function 10, result matching exclude or require
 * @param matchCondition
 * @param specMap
 * @param mode (require or exclude)
 * @return result
 */
function checkMatchResultExclude(matchCondition, specMap, mode) {
    var count = 0;
    var result;
    var matchLength = getSize(matchCondition);
    if(matchLength === 0) {
        return true;
    }
    if(isEmpty(matchCondition)) {
        return true;
    }

    for(var i in matchCondition) {
        for(var j in specMap) {
            if(i.indexOf('arrange') !== -1) {  //if(i.indexOf('arrange') !== -1) { 
                var checkCondition = matchCondition[i];
                for (let z in checkCondition) {
                    result = isEmpty(checkCondition[z]);
                    if(result) { //lv2
                        if(specMap[i].length > 0) {
                            for(let s in specMap[i]) {
                                if (z === specMap[i][s]) { 
                                    //alert('lv2arrage ' + z);
                                    return {
                                        causeID: z,
                                        result: false
                                    };
                                }
                            }
                        }
                    }  else {
                        for(let s in specMap[i]) {
                            if (z === specMap[i][s]) { 
                                for(let a in checkCondition[z]) {
                                    var mapKey = matchingKey(checkCondition[z], a); 
                                    if(mapKey) {
                                        for(let o in mapKey){
                                            result = isEmpty(mapKey[o]);
                                            if(result){ //lv3
                                                if(a.indexOf('arrange') !== -1) {
                                                    if(specMap[a].length > 0) {
                                                        for(let p in specMap[a]) {
                                                            if(o === specMap[a][p]){
                                                                //alert('lv3arrage ' + o);
                                                                return {
                                                                    causeID: o,
                                                                    result: false
                                                                };
                                                            }
                                                        }
                                                    }
                                                } else {
                                                    if(o === specMap[a]) { 
                                                        //alert('lv3arrage ' + o);
                                                        return {
                                                            causeID: o,
                                                            result: false
                                                        };
                                                    }
                                                }
                                            } else {
                                                if(o === specMap[a]) {
                                                    for(let n in mapKey[o]) {
                                                        var mapKey1 = matchingKey(mapKey[o], n);
                                                        if(mapKey1) {
                                                            for(let m in mapKey1) {
                                                                result = isEmpty(mapKey1[m]);
                                                                if(result) { //lv4
                                                                    if(n.indexOf('arrange') !== -1) {
                                                                        if(specMap[n].length > 0) {
                                                                            for(let l in specMap[n]) {
                                                                                if(m === specMap[n][l]){
                                                                                    //alert('lv4arrage ' + m);
                                                                                    return {
                                                                                        causeID: m,
                                                                                        result: false
                                                                                    };
                                                                                }
                                                                            }
                                                                        }
                                                                    } else {
                                                                        if(m === specMap[n]) {
                                                                            //alert('lv4arrage ' + m);
                                                                            return {
                                                                                causeID: m,
                                                                                result: false
                                                                            };
                                                                        }
                                                                    }
                                                                } else {
                                                                    if(m === specMap[n]) {
                                                                        for(let b in mapKey1[m]) {
                                                                            var mapKey2 = matchingKey(mapKey1[m], b);
                                                                            if(mapKey2) {
                                                                                for(let c in mapKey2){
                                                                                    result = isEmpty(mapKey2[c]);
                                                                                    if(result) {
                                                                                        if(b.indexOf('arrange') !== -1) {
                                                                                            if(specMap[b].length > 0) {
                                                                                                for(let k in specMap[b]) {
                                                                                                    if(c === specMap[b][k]){
                                                                                                        //alert('lv5arrage ' + c);
                                                                                                        return {
                                                                                                            causeID: c,
                                                                                                            result: false
                                                                                                        };
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        } else {
                                                                                            if(c === specMap[b]){
                                                                                                //alert('lv5arrage ' + c);
                                                                                                return {
                                                                                                    causeID: c,
                                                                                                    result: false
                                                                                                };
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            } else {
                if(i === j) { 
                    var checkCondition = matchCondition[i];
                    for (let z in checkCondition) {
                        result = isEmpty(checkCondition[z]);
                        if(result) { //lv2
                            if (z === specMap[j]) { 
                                //alert('lv2 ' + z);
                                return {
                                    causeID: z,
                                    result: false
                                };
                            }
                        }  else {
                            if (z === specMap[j]) { 
                                for(let a in checkCondition[z]) {
                                    var mapKey = matchingKey(checkCondition[z], a); 
                                    if(mapKey) {
                                        for(let o in mapKey){
                                            result = isEmpty(mapKey[o]);
                                            if(result){ //lv3
                                                if(a.indexOf('arrange') !== -1) {
                                                    if(specMap[a].length > 0) {
                                                        for(let p in specMap[a]) {
                                                            if(o === specMap[a][p]){
                                                                //alert('lv3 ' + o);
                                                                return {
                                                                    causeID: o,
                                                                    result: false
                                                                };
                                                            }
                                                        }
                                                    }
                                                } else {
                                                    if(o === specMap[a]) { 
                                                        //alert('lv3 ' + o);
                                                        return {
                                                            causeID: o,
                                                            result: false
                                                        };
                                                    }
                                                }
                                            } else {
                                                if(o === specMap[a]) {
                                                    for(let n in mapKey[o]) {
                                                        var mapKey1 = matchingKey(mapKey[o], n);
                                                        if(mapKey1) {
                                                            for(let m in mapKey1) {
                                                                result = isEmpty(mapKey1[m]);
                                                                if(result) { //lv4
                                                                    if(n.indexOf('arrange') !== -1) {
                                                                        if(specMap[n].length > 0) {
                                                                            for(let l in specMap[n]) {
                                                                                if(m === specMap[n][l]){
                                                                                    //alert('lv4 ' + m);
                                                                                    return {
                                                                                        causeID: m,
                                                                                        result: false
                                                                                    };
                                                                                }
                                                                            }
                                                                        }
                                                                    } else {
                                                                        if(m === specMap[n]) {
                                                                            //alert('lv4 ' + m);
                                                                            return {
                                                                                causeID: m,
                                                                                result: false
                                                                            };
                                                                        }
                                                                    }
                                                                } else {
                                                                    if(m === specMap[n]) {
                                                                        for(let b in mapKey1[m]) {
                                                                            var mapKey2 = matchingKey(mapKey1[m], b);
                                                                            if(mapKey2) {
                                                                                for(let c in mapKey2){
                                                                                    result = isEmpty(mapKey2[c]);
                                                                                    if(result) {
                                                                                        if(b.indexOf('arrange') !== -1) {
                                                                                            if(specMap[b].length > 0) {
                                                                                                for(let k in specMap[b]) {
                                                                                                    if(c === specMap[b][k]){
                                                                                                        //alert('lv5 ' + c);
                                                                                                        return {
                                                                                                            causeID: c,
                                                                                                            result: false
                                                                                                        };
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        } else {
                                                                                            if(c === specMap[b]){
                                                                                                //Dalert('lv5 '+ c);
                                                                                                return {
                                                                                                    causeID: c,
                                                                                                    result: false
                                                                                                };
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    return {
        causeID: {},
        result: true
    };
}
/**
 * function 9
 * @param productComponent
 * @param specMapPsid 
 * @param specMapWMN 
 * @param specMap 
 * @param mode 
 * @returns isMatch
 */
function checkStatusRequireOrExclude(productComponent, specMapPsid, specMapWMN, specMap, mode) {
    var result = true;
    var mapKey = matchingKey(productComponent, specMapPsid); //'osSetWN1'
    if(mapKey != null) {
        if(specMapPsid.indexOf('arrange') !== -1 && mode === 'exclude') {
            for(let i in specMapWMN) {
                var mapKeyPC = matchingKey(mapKey, specMapWMN[i]); //'OSWIN10P64ADV'
                if(mapKeyPC != null) {
                    var matchResult = checkMatchResult(mapKeyPC, specMap, mode);
                    if(matchResult.result) {
                        result = {
                                result: true,
                                causeID: {}
                        };
                    } else {
                        result = {
                                result: false,
                                causeID: matchResult.causeID
                        };
                        break;
                    }
                }
            }
        } else {
            var mapKeyPC = matchingKey(mapKey, specMapWMN); //'OSWIN10P64ADV'
            if(mapKeyPC != null) {
                var matchResult = checkMatchResult(mapKeyPC, specMap, mode);
                if(matchResult.result) {
                    result = {
                            result: true,
                            causeID: {}
                    };
                } else {
                    result = {
                            result: false,
                            causeID: matchResult.causeID
                    };
                }
            }
        }
    }
    return result;
}

/**
 * function 7, check Matching Key
 * @param Map
 * @param key
 * @return result
 */
function matchingKey(Map, keySpec) {
    var result;
    for (var i in Map) {
        if(i === keySpec) {
            result = Map[i];
            return result;
        }
    }
    return result;
}

/**
 * function 2 check webtype PC 
 * @param webTypeControl
 * @param webTypeControl.map
 * @param specMap
 */
function setWebTypePC(map, specMap) {
    for (let i in specMap) {
        var dataKey = matchingKey(map, i); //'2698os'
        if (dataKey != null) {
            var psCondition = dataKey;
            var dataKey1 = matchingKey(psCondition, specMap[i]); //'OSNAR1A18X'
            if(dataKey1 != null) {
                for(var z in dataKey1) {
                    var matchCondition = dataKey1[z];
                    if (matchCondition.MATCH != null) {
                        if(isEmpty(matchCondition.MATCH) === false) {
                            if (checkMatchCondition(specMap, matchCondition.MATCH)) {
                                for(let i in specMap) {
                                    if(i.indexOf('pc') !== -1) {
                                        var replace = getLastProperty(matchCondition);
                                        specMap[i] = matchCondition[replace];
                                        recheckInputRadioBTO(matchCondition[replace],'PC');
                                        return specMap;
                                    }
                                }
                            }
                        } else {
                            for(let i in specMap) {
                                if(i.indexOf('pc') !== -1) {
                                    var replace = getLastProperty(matchCondition);
                                    specMap[i] = matchCondition[replace];
                                    recheckInputRadioBTO(matchCondition[replace],'PC');
                                    return specMap;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
return specMap;
}

/**
 * function 3 check webtype OS
 * @param webTypeControl
 * @param webTypeControl.map
 * @param specMap
 */
function setWebTypeOS(replace, specMap, webTypePC) {
    if (replace != null) {
        if(isEmpty(replace) === false) {
            var dataKey = matchingKey(replace,webTypePC); 
            if (dataKey != null) {
                for (var j in specMap) {
                    var dataKey1 = matchingKey(dataKey, j);
                    if(dataKey1 != null) {
                        var dataKey2 = matchingKey(dataKey1, specMap[j]);
                        if(dataKey2 != null) {
                            for(var i in specMap) {
                                if(i.indexOf('os') !== -1) {
                                    specMap[i] = dataKey2;
                                    recheckInputRadioBTO(dataKey2, 'RS_OS');
                                    return specMap;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    return specMap;
    }     
/**
 * function 10, result matching exclude or require
 * @param matchCondition
 * @param specMap
 * @param mode (require or exclude)
 * @return result
 */
function checkMatchResult(matchCondition, specMap, mode) {
    var count = 0;
    var matchLength = getSize(matchCondition);
    if(matchLength === 0) {
        return true;
    }
    if(isEmpty(matchCondition)) {
        return true;
    }

    
    for(var i in matchCondition) {
        for(var j in specMap) {
            if(mode === 'require') {
                if(i === j) {
                    var checkCondition = matchCondition[i];
                    for (let z in checkCondition) {
                        if (checkCondition[z] === specMap[j]) {
                            count++;
                        }
                    }
                }
            } 
            else if (mode ==='exclude') {
                if(i.indexOf('arrange') !== -1) {
                    var checkCondition = matchCondition[i];
                    for (let z in checkCondition) {
                        if(specMap[i].length > 0) {
                            for(let o in specMap[i]) {
                                if (checkCondition[z] === specMap[i][o]) {
                                    return {
                                        causeID: matchCondition,
                                        result: false
                                    };
                                }
                            }
                        }
                    }
                } else {
                    if(i === j) {
                        var checkCondition = matchCondition[i];
                        for (let z in checkCondition) {
                            if (checkCondition[z] === specMap[j]) {
                                return {
                                    causeID: matchCondition,
                                    result: false
                                };
                            }
                        }
                    }
                }
            }
        }
    }

    if(mode === 'exclude') {
        return {
            causeID: {},
            result: true
        };
    }
    else if (count === 0) {
        return {
            causeID: matchCondition,
            result: false
        };
    } else if (count != matchLength) {
        return {
            causeID: matchCondition,
            result: false
        };
    } else if (count == matchLength) {
        return {
            causeID: {},
            result: true
        };
    }
}

/*
 * check object empty or not
 */
function isEmpty(obj) {
      for(var prop in obj) {
            if(obj.hasOwnProperty(prop)) {
              return false;
            }
          }
      return true;
}

/*
 * check size of object
 */
function getSize(obj) {
    var count = 0;
    for(let i in obj) {
        if(obj.hasOwnProperty(i)) {
            count ++;
        }
    }
    return count;
}

/*
 * get the last property of object
 */
function getLastProperty(obj) {
    var result;
    for(let i in obj) {
        if(obj.hasOwnProperty(i)) {
            result = i;
        }
    }
    return result;
}
/**
 * recheck input radio button BTO when condition match
 * @param webModelNumber
 * @param productType
 * @returns
 */
function recheckInputRadioBTO(webModelNumber, productType) {
    $('div.'+productType+ '-item input.product-set-item').each(function(e) {
        if($(this).attr('value') === webModelNumber) {
            $(this).prop("checked", true);
            $(this).attr("checked", 'checked');
        } else {
            $(this).prop("checked", false);
            $(this).removeAttr("checked");
        }
    }) 
}

/**
 * check checkbox Arrange when meet the condition 
 */
function recheckInputCheckBoxArrange(webModelNumber, productType) {
    $('div.'+productType+ '-item input.product-set-item-checkbox').each(function(e) {
        if($(this).attr('value') === webModelNumber) {
            $(this).prop("checked", true);
            $(this).attr("checked", 'checked');
        }
    }) 
}

/**
 * uncheck checkbox Arrange when click any BTO option
 * @returns
 */
function uncheckInputCheckBoxArrange() {
    $('div.ARRANGE-item input.product-set-item-checkbox').each(function(e) {
        if($(this).prop("checked",true)) {
            $(this).prop("checked", false);
            $(this).removeAttr("checked");
        }
    })
}
/**
 * Convert ID of product to name of product 
 * @param id
 * @returns
 */
function convertIDToNameBTO(id, idNameMap) {
    return idNameMap[id];
}

// Start 210126 Add: FCCL-1744
var spMaxWidth = 767;
/**
 * resize category block to the same
 * @returns
 */
function resizeCategoryBlock() {
    var $block = $('.categoryService_block');
    if ($block.length > 0) {
        var delayTime = 500;
        setTimeout(function() {
            $block.each(function() {
                var $img = $(this).find('.categoryService_itemThum');
                var $txt = $(this).find('.categoryService_produtctsInfo');
                $img.height('auto');
                $txt.height('auto');
                if (document.defaultView.innerWidth > spMaxWidth) {
                    $img.height(Math.max.apply(null, $img.map(function() {return $(this).height();}).get()));
                    $txt.height(Math.max.apply(null, $txt.map(function() {return $(this).height();}).get()));
                }
            });
        }, delayTime);
    }
}
// End 210126 Add: FCCL-1744
// Start 210906 Add: FCCL-2951
function checkAddToCart(e) {
    $('#bto_check_error').val(0);
    var specMap = {};
    initSpecMap(specMap);
    var idNameMap = initIdNameMap();    
    var result = getPdpDetail(specMap, webTypeControlStr, idNameMap);
    if (result.errMsg) {
        $('button#add-to-cart').attr("disabled", true);
        $('button#add-to-cart').css('background-color', '#606A76');
        $('button#add-to-cart').text(btnDisable);
        $('#bto_check_error').val(1);
        alert(result.msg);
        // Start 210916 Add: FCCL-2960
        var errMsg = 'URL: ' + window.location.href + '\r\n';
        errMsg = errMsg + 'IP: ' + $('#client_ip_error').val() + '\r\n';
        errMsg = errMsg + 'User Agent: ' + $('#user_agent_error').val() + '\r\n';
        errMsg = errMsg + 'Time: ' + new Date();
        for (var i in specMap) {
            errMsg = errMsg + '\r\n' + i + ': ' + specMap[i];
        }

        var url = util.ajaxUrl(Urls.writeErrorLog);
        var data = {
            errMsg: errMsg
        }
        $.ajax({
            type: 'POST',
            url: url,
            data: data
        });
        // End 210916 Add: FCCL-2960
    } else {
        $('button#add-to-cart').removeAttr("disabled");
        $('button#add-to-cart').css('background-color', '#C00');
        $('button#add-to-cart').text(btnEnable);
    }
}
// End 210906 Add: FCCL-2951

var product = {
    initializeEvents: initializeEvents,
    init: function () {
        initializeDom();
        $('.pdp-main').on('click touchend', '#add-to-cart', checkAddToCart);  // 210906 Add: FCCL-2951
        initializeEvents();
        // Start 210126 Add: FCCL-1744
        $(window).on('resize', function() {
            resizeCategoryBlock();
        });
        $('.categoryService_block').find('.jsAccordionMoreBtn').on('click', function() {
            resizeCategoryBlock();
        });
        // End 210126 Add: FCCL-1744
        //getAndInsertCSRFToken('frm-pdp');

        if ($('form.page-t06').length > 0 && $('button#add-to-cart').length > 0) {
            $('button#add-to-cart').addClass('button-disable');
            setTimeout(function() {
                checkAddToCart();
                $('button#add-to-cart').removeClass('button-disable');
                $('select#quantity').trigger('change');
            }, 3000);
        }
        // End 210818 Add: FCCL-2940
    }

};

module.exports = product;