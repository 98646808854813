'use strict';
//FCCL-2666 : bangly 22-3-2021 start
var util = require('./util');
var modal = require('./modal');
var SessionAttributes = window.SessionAttributes;
//FCCL-2666 : bangly 22-3-2021 start
/**
 * @function captcha    Used to display/control the scrim containing the simulated captcha code
 **/
module.exports = function () {
    /**
     * if the session.privacy.ratelimited element is present then show the notification
     * NOTE: You will probably want to replace this with a call to an actual CAPTCHA system to replace the simple one here
     */
    //FCCL-2666 : bangly 22-3-2021 start
    if (SessionAttributes.SHOW_CAPTCHA) {
        //loading.hide();
        modal.dialog(Resources.ARE_YOU_HUMAN,function () {
            var url = util.appendParamsToUrl(Urls.rateLimiterReset, {format: 'ajax'});
            $.ajax({
                url: url
            });
            renameCancelButton();
        });
        $(".js-inline-modal-cancel").text('OK');
        function renameCancelButton (){
            $(".js-inline-modal-cancel").text(Resources.CANCEL)
        }
    }
    //FCCL-2666 : bangly 22-3-2021 end
};
