'use strict';
var util = require('../util');

exports.init = function () {
    $('#homepage-slider')
        // responsive slides
        .on('jcarousel:create jcarousel:reload', function () {
            var element = $(this),
                width = element.innerWidth();
            element.jcarousel('items').css('width', width + 'px');
        })
        .jcarousel({
            wrap: 'circular'
        })
        .jcarouselAutoscroll({
            interval: 5000
        });
    $('#homepage-slider .jcarousel-control')
        .on('jcarouselpagination:active', 'a', function () {
            $(this).addClass('active');
        })
        .on('jcarouselpagination:inactive', 'a', function () {
            $(this).removeClass('active');
        })
        .jcarouselPagination({
            item: function (page) {
                return '<a href="#' + page + '">' + page + '</a>';
            }
        });

    $('#vertical-carousel')
        .jcarousel({
            vertical: true
        })
        .jcarouselAutoscroll({
            interval: 5000
        });
    $('#vertical-carousel .jcarousel-prev')
        .on('jcarouselcontrol:active', function () {
            $(this).removeClass('inactive');
        })
        .on('jcarouselcontrol:inactive', function () {
            $(this).addClass('inactive');
        })
        .jcarouselControl({
            target: '-=1'
        });

    $('#vertical-carousel .jcarousel-next')
        .on('jcarouselcontrol:active', function () {
            $(this).removeClass('inactive');
        })
        .on('jcarouselcontrol:inactive', function () {
            $(this).addClass('inactive');
        })
        .jcarouselControl({
            target: '+=1'
        });
    /**
     * Add event convert characters to fullwidth
     */
    $('.form-shokuikilogin input[name*=upderOrDepartmentOrCourseName]')
        .add('.form-shokuikilogin input[name*=companyName]')
        .add('.form-shokuikilogin input[name*=magazineName]')
        .add('.form-shokuikilogin input[name*=staffName]')
        .add('.form-shokuikilogin input[name*=schoolname]')
        .on('change', function(){
            var shiftCharCode = function (Δ) { return function (c) { return String.fromCharCode(c.charCodeAt(0) + Δ); }; };
            var toFullWidth = function(str) {
                return str.replace(/[!-~]/g, shiftCharCode(0xFEE0));
            }
            $(this).val(toFullWidth($(this).val()));
        });
    $('.form-shokuikilogin button#shokuiki-submit').on('click', function(e){
        e.preventDefault();
        var $form = $(this).closest('form');
        var input_field = $form.find("input[id='input_field']").val();

        //FCCL-3021 validate fields
        var bool = true;
        var inputs = $form.find('.form_content input');
        var validFields = $form.find("input[id='input_fields_valid']").val().slice(1,-1).split(", ");

        inputs.each(function () {
            var name = $(this).attr('name');
            var errorMessage = $(this).parent().parent().find('#error_message');
            var oldErrorMessage = $(this).parent().parent().find("#error_message_controller");
            if (validFields.includes(name)) {
                if(/^.{0,25}$/.test($(this).val())) {
                    errorMessage.hide();
                } else {
                    errorMessage.show();
                    oldErrorMessage.remove();
                    bool = false;
                }
            }
        });

        if (bool) {
            $form.attr('action', util.appendParamToURL($form.attr('action'), 'input_field', input_field.slice(1,-1).split(", ")));
            $form.submit();
        }
    });
};
