'use strict';

var _ = require('lodash'),
    ajax = require('../../ajax'),
    page = require('../../page'),
    loading = require('../../loading'),
    Promise = require('promise'),
    config = require('../../../scripts/config'),
    util = require('../../util'),
    modal = require('../../modal');
var productCart = {
    /** current product id */
    pid: null,
    productSets: [],
    qty: 1,
    salePrice: 0,
    webPrice: 0,

    init: function (pid) {
        var self = this;
        this.pid = pid;
        this.productSets = [];
        this.qty = parseInt($("#quantity").val(), 10);
        //this.salePrice = parseInt($("#salePrice").attr('data-price').replace(/[^0-9]+/g, ""), 10);
        //this.webPrice = parseInt($("#webPrice").attr('data-price').replace(/[^0-9]+/g, ""), 10);
        this.salePrice = parseInt($("#salePrice").attr('data-price'), 10);
        this.webPrice = parseInt($("#webPrice").attr('data-price'), 10);
        
        this.checkedToCart();
    },
    checkedToCart: function () {
        var self = this;
        this.productSets = [];
        $("input:radio.product-set-item").each(function(i) {
            if ($(this).is(':checked') === true) {
                var group = $(this).attr('pgroup');
                var dummy = $(this).attr('pdummy');
                if(group == 'BTO' || (group != 'BTO' && dummy == 'false')) {
                    var pItem = {
                        psid: $(this).attr('name'),
                        pid: $(this).val(),
                        price: $(this).attr('data-price'),
                        saleprice: $(this).attr('data-saleprice')
                    }
                    self.addProduct(pItem, true);
                }
            }
        });
        $("input:checkbox.product-set-item-checkbox").each(function(i) {
            if ($(this).is(':checked') === true) {
                var group = $(this).attr('pgroup');
                var dummy = $(this).attr('pdummy');
                if(group == 'BTO' || (group != 'BTO' && dummy == 'false')) {
                    var pItem = {
                        psid: $(this).attr('name'),
                        pid: $(this).val(),
                        price: $(this).attr('data-price'),
                        saleprice: $(this).attr('data-saleprice')
                    }
                    self.addProduct(pItem, false);
                }
            }
        });
    },
    /**
     * Init check state of child product
     *
     */
    initChildProductCheckState: function () {
        this.productSets.forEach(function (item) {
            $('.pdp-main input.product-set-item[psname="' + item.psid + '"]').prop("checked", false);
            $('.pdp-main input.product-set-item[psname="' + item.psid + '"][value="' + item.pid + '"]').prop("checked", true);

            $('.pdp-main input.product-set-item-checkbox[psname="' + item.psid + '"]').prop("checked", false);
            $('.pdp-main input.product-set-item-checkbox[psname="' + item.psid + '"][value="' + item.pid + '"]').prop("checked", true);
        });
    },
    /** add the product to cart
     * @param pItem product info
     * @param: isRemove boolean
     */
    addProduct: function (pItem, isRemove) {
        var self = this;
        if (isRemove === true) {
            self.removeProduct(pItem);
            this.productSets = _.reject(this.productSets, function(item) {
                return item.psid == pItem.psid;
            });
        }

        this.productSets.push(pItem);
        //check show price after change
        //console.log(JSON.stringify(this.productSets));
    },
    /** update price the product on customization
     * @param pItem product info
     */
    setQuanity: function (qty) {
        qty = parseInt(qty, 10);
        if (!qty) {
            qty = 1;
        }
        this.qty = qty;
        //this.showPrice();
        return this;
    },
    /** remove the product on customization
     * @param pItem product info
     */
    removeProduct: function (pItem) {
        var self = this;
        var foundItem = _.find(this.productSets, function(item) {
            return item.psid == pItem.psid;
        });
        if (foundItem) {
            //self.removePrice(foundItem);
        }
    },
    /** remove the product on customization
     * @param pItem product info
     */
    removePrice: function (foundItem) {
        var self = this;
        var price = parseInt(foundItem.price, 10);
        var saleprice = parseInt(foundItem.saleprice, 10);

        if (price) {
            this.webPrice = this.webPrice - (price );
        }
        if (salePrice) {
            this.salePrice = this.salePrice - (saleprice);
        }
    },
    /** remove the product after unchecked on Peripherals / Software category
     * @param pItem product info
     */
    removeProductPeripSoftware: function (pItem) {
        var self = this;
        var foundItem = _.find(this.productSets, function(item) {
            return (item.psid == pItem.psid && item.pid == pItem.pid);
        });
        //console.log(foundItem);
        this.productSets = _.reject(this.productSets, function(item) {
            return (item.psid == pItem.psid && item.pid == pItem.pid);
        });
    },
    addItemToCart: function (form) {
        var self = this;
        var $form = $(form),
            $qty = $form.find('select[name="Quantity"] option:selected');
        if ($qty.length === 0 || isNaN($qty.val()) || parseInt($qty.val(), 10) === 0) {
            $qty.val('1');
        }
        this.checkedToCart();
        $('#bundledProductSets').val(JSON.stringify(self.productSets));
        return new Promise(function (resolve) {
            ajax.load({
                type: 'POST',
                url: Urls.addProduct,
                data: $form.serialize(),
                callback: function (response, textStatus) {
                    //console.log(response, textStatus);
                    resolve(response);
                }
            });
        });
    },
    sendRequest: function (url, data) {
        var self = this;
        
        return new Promise(function (resolve) {
            ajax.load({
                type: 'POST',
                url: url,
                data: data,
                callback: function (response, textStatus) {
                    //console.log(response, textStatus);
                    resolve(response);
                }
            });
        });
    },
    updateFirstWishList: function (data) {
        var self = this;
        var url = util.ajaxUrl(Urls.updateFirstWishList);
        return Promise.resolve($.ajax({
            type: 'POST',
            url: util.ajaxUrl(Urls.updateFirstWishList),
            data: data
        })).then(function (response) {
            // handle error in the response
            if (response.error == 'login') {
                page.redirect(Urls.login);
            }
            else{
                $.magnificPopup.close();
            }
        });
    },
    // FCCL-2070: Add csrf for calling ajax
    addItemToCartWithoutForm: function (productItem, csrfToken) {
        // Add form serialize for csrf validation
        productItem.csrf_token = csrfToken;
        return Promise.resolve($.ajax({
            type: 'POST',
            url: util.ajaxUrl(Urls.addProduct),
            data: productItem
        })).then(function (response) {
            // handle error in the response
            if(response && response.csrf_error) {
                // Redirect to CSRF failed page whenever csrf validation failed
                page.redirect(Urls.csrffailed);
            } else {
                return response;
            }
        });
    },
    addToCart: function ($form) {
        this.addItemToCart($form)
            .then(function (response) {
            //console.log(response);
                if(response.result) {
                    if(response.result.statusCode 
                        && !isNaN(response.result.statusCode)) {
                        page.redirectError(response.result.statusCode);
                    }else {
                        // FCCL-2116-2 add errorHandling
                        // FCCL-2538 Change from asynchronous to synchronous
                        $.when(
                            alert(response.result.message)
                        ).done(function () {
                            if (response.result.errorHandling == 'GO_TO_PLP') {
                                page.redirect(Urls.seriesService);
                            } else if (response.result.errorHandling == 'GO_TO_CART') {
                                $('input[name="ownedMachineWebtypeNumber"]').val('null');
                                $('input[name="ownedMachineSerialNumber"]').val('null');
                                $form = $(this).closest('form');
                                $('#add-to-cart').trigger("click");
                            } else {
                                // FCCL-2556 add loading hide
                                loading.hide();
                            }
                        })
                    }
                } else if(response.csrf_error) {
                    // Redirect to CSRF failed page whenever csrf validation failed
                    page.redirect(Urls.csrffailed);
                }
                else {
                    page.redirect(Urls.cartShow);
                }
            /*
            var $uuid = $form.find('input[name="uuid"]');
            if ($uuid.length > 0 && $uuid.val().length > 0) {
                // page.refresh();
            } else {
            }
            */
            });
    },
    displayPrice: function () {
        // var self = this;
        var webPrice = 0;
        var salePrice = 0;
        var qty = 0;
        var qtyValue = $("#quantity").val();
        if(qtyValue == null || qtyValue == undefined || qtyValue == "") {
            qty = 0;
        } else {
            qty = parseInt(qtyValue, 10);
        }
        //get fixed price, Product Invisible
        var fixedWebprice = parseInt($("#fixed-webprice").val(), 10);
        var fixedSaleprice = parseInt($("#fixed-saleprice").val(), 10);
        webPrice += fixedWebprice;
        salePrice += fixedSaleprice;

        $("input:radio.product-set-item").each(function(i) {
            if ($(this).is(':checked') === true) {
                webPrice += parseInt($(this).attr('data-price'), 10);
                salePrice += parseInt($(this).attr('data-saleprice'), 10);
            }
        });
        $("input:checkbox.product-set-item-checkbox").each(function(i) {
            if ($(this).is(':checked') === true) {
                webPrice += parseInt($(this).attr('data-price'), 10);
                salePrice += parseInt($(this).attr('data-saleprice'), 10);
            }
        });
        webPrice = (webPrice * qty);
        salePrice = (salePrice * qty);

        this.showPrice(webPrice, salePrice);
    },
    /** update price the product on customization
     * @param pItem product info
     */
    showPrice: function (webPrice, salePrice) {
        
        var newWebPrice = webPrice.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1"+ config.CurrencySeparate);
        $("#webPrice").html(newWebPrice + config.CurrencySymbol);

        var newSalePrice = salePrice.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1"+ config.CurrencySeparate);
        $("#salePrice").html(newSalePrice + "<span>" + config.CurrencySymbol + "</span>");
        $("#salePrice-mobile").html(newSalePrice + config.CurrencySymbol);
    },
    addToWishList: function () {
        var self = this;
        var qty = parseInt($("#quantity").val(), 10);
        
        this.checkedToCart();
        $('#bundledProductSets').val(JSON.stringify(self.productSets));
        var data = {
            quanlity: qty,
            productId: $('#productID').val(),
            bundledProductSets:   $('#bundledProductSets').val()
        }
        
        self.sendRequest(Urls.addWishlist, data).then(function (response) {
            // handle error in the response
            loading.hide();
            // var popupId = '#favourites_popup_success';
            if (response.error == 'login') {
                page.redirect(util.appendParamsToUrl(Urls.login, {
                    scope: 'wishlist'
                }));
            }
            else {
                if (response.error == config.wishlist.max_items) {
                    //                     popupId = '#favourites_popup_confirm';
                    modal.confirm(Resources.FAVORITE_UPDATE_TITILE, '', function() {
                        loading.show();
                        self.sendRequest(Urls.updateFirstWishList, data)
                            .then(function (response) {
                                loading.hide();
                                // handle error in the response
                                if (response.error == 'login') {
                                    page.redirect(util.appendParamsToUrl(Urls.login, {
                                        scope: 'wishlist'
                                    }));
                                }
                                else{
                                    $.magnificPopup.close();
                                }
                            });
                    });
                } else {
                    modal.addWishlist(Resources.FAVORITE_ADD_SUCCESS, Resources.BUTTON_FAVORITE, function() {
                        //todo
                        page.redirect(Urls.showWishList);
                    });
                }
            }
        });
    },
    // FCCL-2070: Add csrf for ajax calling
    addListToCart: function (listProduct, quoteInfo, csrfToken) {
        var self = this;
        var listProduct = this.parseListProduct(listProduct);
        var quoteId = null;
        if(quoteInfo) {
            quoteId = JSON.parse(quoteInfo).quoteID;
        }
        if (quoteInfo) {
            self.sendRequest(Urls.sessionQuote, {
                "quoteInfo": quoteInfo
            }).then(function (res) {
                addItemToCartRecursive(listProduct, csrfToken, 0, quoteId);
            });
        } else {
        	addItemToCartRecursive(listProduct, csrfToken, 0, quoteId);
        }
    },
    addCouponJson: function(couponCode) {
        var url = util.appendParamToURL(Urls.addCoupon, 'couponCode', couponCode);
        return Promise.resolve($.ajax({
            type: 'GET',
            url: util.ajaxUrl(url),
        })).then(function (response) {
            // handle error in the response
            if (response.error) {
                throw new Error(response.error);
            } else {
                return response;
            }
        });
    },
    setSessionQuote: function(quoteInfo) {
        var url = util.appendParamsToUrl(Urls.sessionQuote, {
            "quoteInfo": quoteInfo
        });
        return Promise.resolve($.ajax({
            type: 'POST',
            url: util.ajaxUrl(url),
        })).then(function (response) {
            // handle error in the response
            if (response.error) {
                throw new Error(response.error);
            } else {
                return response;
            }
        });
    },
    parseListProduct: function (quoteLineBody) {
        if (quoteLineBody.length > 0) {
            var quoteLineList = [];
            quoteLineBody.forEach(function(productQuote) {
                var productItems = {};
                
                productItems.Product = "on";
                productItems.Quantity = productQuote.quantity;

                //is product bundled
                if (productQuote.majorClsCd) {
                    productItems.pid = productQuote.majorClsCd;

                    var bundledProductSets = [];
                    var itemTypes = ['specList', 'serviceSupportList', 'peswList', 'presentList'];
                    // add PC product
                    bundledProductSets.push({
                        "psid": productQuote.productSetId,
                        "pid": productQuote.productId,
                        "price": "0.0",
                        "saleprice": productQuote.lineAmount.toString()
                    });

                    itemTypes.forEach( function(type) {
                        if (Array.isArray(productQuote[type])) {
                            productQuote[type].forEach( function(item) {
                                bundledProductSets.push({
                                    "psid": item.productSetId,
                                    "pid": item.productId,
                                    "price": "0.0",
                                    "saleprice": item.lineAmount.toString(),
                                });
                            });
                        }
                    });

                    productItems.bundledProductSets = JSON.stringify(bundledProductSets);
                    //set add product from quote
                    productItems.quotePid = productQuote.majorClsCd;

                    // FCCL-2116 add selected owned Machine
                    //FCCL-2748 : bangly 4-7-2021 start 
                    productItems.ownedMachineWebtypeNumber = productQuote.ownedMachineWebtypeNumber ? productQuote.ownedMachineWebtypeNumber : productQuote.ownedmachineWebtypeNumber;
                    productItems.ownedMachineSerialNumber = productQuote.ownedMachineSerialNumber ? productQuote.ownedMachineSerialNumber : productQuote.ownedmachineSerialNumber;
                    //FCCL-2748 : bangly 4-7-2021 end 
                    // FCCL-2454 add quoute parameter
                    productItems.isQuote = true;
                } else {
                    productItems.pid = productQuote.productId;
                    //set add product from quote
                    productItems.quotePid = productQuote.productId;

                    // FCCL-2116 add selected owned Machine
                    //FCCL-2748 : bangly 4-7-2021 start 
                    productItems.ownedMachineWebtypeNumber = productQuote.ownedMachineWebtypeNumber ? productQuote.ownedMachineWebtypeNumber : productQuote.ownedmachineWebtypeNumber;
                    productItems.ownedMachineSerialNumber = productQuote.ownedMachineSerialNumber ? productQuote.ownedMachineSerialNumber : productQuote.ownedmachineSerialNumber;
                    //FCCL-2748 : bangly 4-7-2021 end 
                    // FCCL-2454 add quoute parameter
                    productItems.isQuote = true;
                }
                
                var couponCode = '';
                if (productQuote.coupon && productQuote.coupon.couponId) {
                    couponCode = productQuote.coupon.couponId;
                }

                quoteLineList.push({
                    couponCode: couponCode,
                    data: productItems
                });
            });
            return quoteLineList;
        }

        return [];
    },
    deleteAllProductInCart: function () {
        return Promise.resolve(
            $.ajax({
                type: 'POST',
                url: util.ajaxUrl(Urls.deletePro)
            })
        );
    },
    // FCCL-3110 コールセンターで見積カートインするときにログアウト処理を追加する
    quoteLogout: function () {
        return Promise.resolve(
            $.ajax({
                type: 'POST',
                url: util.ajaxUrl(Urls.quoteLogout)
            })
        );
    },
};

var productDetail = {

    addToCart: function (e) {
        // Start 210906 Add: FCCL-2951
        if ($('#bto_check_error').val() == '1') {
            return;
        }
        // End 210906 Add: FCCL-2951
        var self = this;
        e.preventDefault();
        loading.show();

        // FCCL-3026 【サブスクPC】カートの商品名下に、月額金額を表示する
        $('input[name="daaSMonthly"]').val($('#salePrice').text());

        var $form = $(this).closest('form');
        productCart.addToCart($form);
    },
    addToWishList: function (e) {
        var self = this;
        e.preventDefault();
        loading.show();
        var $form = $(this).closest('form');
        productCart.addToWishList($form);
    },
    updateWishList: function (e) {
        var self = this;
        e.preventDefault();

        $('#bundledProductSets').val(JSON.stringify(productCart.productSets));
        var data ={
            productId: $('#productID').val(),
            bundledProductSets:   $('#bundledProductSets').val()
        }
        productCart.updateFirstWishList(data);
    },
    addListToCart: function (e) {
        e.preventDefault();
        var $form = $(this).closest('form');
        var productList = JSON.parse($form.find('.list-product').val());
        var quoteInfo = $form.find('.quote-data').val();
        // FCCL-2070: Get form csrf token
        var csrfToken = $form.children('input[name="csrf_token"]').val();

        //var quoteNumber = $form.find('.quote-number').val();
        //var quoteSubnumber = $form.find('.quote-subnumber').val();
        var check = $('.header_cartButton-count').text();
        // FCCL-3110 コールセンターで見積カートインするときにログアウト処理を追加する
        var customerNoMatch = $form.find('.customer-no-match').val() == 'true';
        var customerIsB2B = $form.find('.customer-isB2B').val() == 'true';
        var quoteCustomerId = $form.find('.customer-quoteCustomerId').val() == 'true';
        var customerLogin = $form.find('.customer-login').val() == 'true';
        if (customerIsB2B) {
            if (customerLogin && quoteCustomerId && customerNoMatch) {
                modal.confirm(Resources.ADD_QUOTE_TO_CART_2,'',
                    function(){
                        loading.show();
                        productCart.deleteAllProductInCart().then(function (response){               
                            productCart.quoteLogout().then(function (response) {
                                location.reload();
                            });
                        });
                    }
                );
            } else if (check) {
                modal.confirm_2(Resources.ADD_QUOTE_TO_CART,'',
                    function(){
                        loading.show();
                        productCart.deleteAllProductInCart().then(function (response){                        
                            productCart.addListToCart(productList, quoteInfo, csrfToken);
                        })
                    },
                    function(){
                        loading.show();
                        productCart.addListToCart(productList, quoteInfo, csrfToken);
                    }
                );
            } else {
                loading.show();
                productCart.addListToCart(productList, quoteInfo, csrfToken);
            }
        } else {
            if (customerNoMatch) {
                modal.confirm(Resources.ADD_QUOTE_TO_CART_2,'',
                    function(){
                        loading.show();
                        productCart.deleteAllProductInCart().then(function (response){               
                            productCart.quoteLogout().then(function (response) {
                                location.reload();
                            });
                        });
                    }
                );
            } else if (check) {
                modal.confirm_2(Resources.ADD_QUOTE_TO_CART,'',
                    function(){
                        loading.show();
                        productCart.deleteAllProductInCart().then(function (response){                        
                            productCart.addListToCart(productList, quoteInfo, csrfToken);
                        })
                    },
                    function(){
                        loading.show();
                        productCart.addListToCart(productList, quoteInfo, csrfToken);
                    }
                );
            } else {
                loading.show();
                productCart.addListToCart(productList, quoteInfo, csrfToken);
            }
        }
    },
    changeProductSet: function (e) {
        // var self = this;
        // e.preventDefault();
        // var productSet = $(this).closest('.product-set-list');
        var pItem = {
            psid: $(this).attr('psname'),
            pid: $(this).val(),
            price: $(this).attr('data-price'),
            saleprice: $(this).attr('data-saleprice')
            //psname: productSet.find('.categoryCustom_dataName').html()
        }
        productCart.addProduct(pItem, true, true);
        //$(this).attr('checked', true);
        //$(this).prop("checked", true);
        productCart.displayPrice();
    },
    checkProductSet: function (e) {
        // var self = this;
        var pItem = {
            psid: $(this).attr('psname'),
            pid: $(this).val(),
            price: $(this).attr('data-price'),
            saleprice: $(this).attr('data-saleprice')
        }
        if ($(this).is(':checked') === true) {
            productCart.addProduct(pItem, false, true);
        }else {
            productCart.removeProductPeripSoftware(pItem);
        }
        productCart.displayPrice();
    },
    changePrice: function (e) {
        productCart.displayPrice();
    },
    changeQuantity: function () {
        var qty = parseInt($("#quantity").val(), 10);
        productCart.setQuanity(qty);
        productCart.displayPrice();
    },
    changeQuantityB2B: function (control) {
        var qty = 0;
        var btnAddtoCart = $('button.productSpec_button');
        if(control.val() == null || control.val() == undefined || control.val() == "") {
            qty = 0;
        } else {
            qty = parseInt(control.val(), 10);
        }
        
        productCart.setQuanity(qty);
        productCart.displayPrice();
        
        if(qty > 999 || qty < 1) {
            btnAddtoCart.attr("disabled", true);
            btnAddtoCart.css('background-color', '#606A76');
            btnAddtoCart.text(btnDisable);
            window.alert(inputNunmberValidate);
        } else {
            var isEnable = btnAddtoCart.prop('disabled');
            if(isEnable) {
                btnAddtoCart.removeAttr("disabled");
                btnAddtoCart.css('background-color', '#C00');
                btnAddtoCart.text(btnEnable);
            }
        }
    },
    init: function () {
        var self = this;

        if ($('#quote-list').length > 0) {
            // click add-list-product-to-cart button
            $('.add-list-product-to-cart').on('click', this.addListToCart);
        } else {
            if ($('.pt_product-details .page-t06').length > 0 
                || $('.pt_product-details .page-t07').length > 0 ) {
                //var bundledProductSets = $('#bundledProductSets');
                productCart.init($('#productID').val());
                // change quantity
                $( ".pdp-main .productSpec_select #quantity" ).change(function(e) {
                    e.preventDefault();
                    self.changeQuantity();
                });
                
                $('.productSpec_inputNumber').on('change', function (e) {
                    e.preventDefault();
                    self.changeQuantityB2B($(this));
                });
                
                // click radio on list product
                $('.pdp-main').on('click touchend', '.product-set-item', self.changeProductSet);
                // click checkbox on list product
                $('.pdp-main').on('click touchend', '.product-set-item-checkbox', self.checkProductSet);
                // click addtocart button
                $('.pdp-main').on('click touchend', '#add-to-cart', this.addToCart);
            }
            // click wishlist button
            $('.pdp-main').on('click touchend', '#addWishList', this.addToWishList);
        }
    }
};

/**
 * addlist product to cart synchonous by ajax
 * @param productItem
 * @param csrfToken
 * @param counter
 * @returns
 */
function addItemToCartRecursive(productItem, csrfToken, counter, quoteId) {
    if(counter >= productItem.length)  {
        //after call add all product and coupon then call add discount and finally redirect to Cart when success
        if(quoteId) {
            var url = util.appendParamToURL(Urls.addDiscountFromQuote, 'qid', quoteId);
            return $.ajax({
                type: 'POST',
                url: util.ajaxUrl(url)
            }).done(function (response) {
                 page.redirect(Urls.cartShow);
            });
        } else {
            page.redirect(Urls.cartShow);
        }
    } else {
        //Recursive add cart
        if (productItem[counter].data) {
            productItem[counter].data.csrf_token = csrfToken;
            $.ajax({
                type: 'POST',
                url: util.ajaxUrl(Urls.addProduct),
                data: productItem[counter].data,
            }).done(function (response) {
                // handle error in the response
                if(response && response.csrf_error) {
                    // Redirect to CSRF failed page whenever csrf validation failed
                    page.redirect(Urls.csrffailed);
                } else {
                    //add coupon
                    if (productItem[counter].couponCode) {
                        var url = util.appendParamToURL(Urls.addCoupon, 'couponCode', couponCode);
                        $.ajax({
                            type: 'GET',
                            url: util.ajaxUrl(url),
                        }).done(function (response) {
                            if (response.error) {
                                throw new Error(response.error);
                            }
                        })
                    }
                    counter++;
                    addItemToCartRecursive(productItem, csrfToken, counter, quoteId);
                }
            });
        } else {
            if (productItem[counter].couponCode) {
                var url = util.appendParamToURL(Urls.addCoupon, 'couponCode', couponCode);
                $.ajax({
                    type: 'GET',
                    url: util.ajaxUrl(url),
                }).done(function (response) {
                    if (response.error) {
                        throw new Error(response.error);
                    }
                })
            }
            counter++;
            addItemToCartRecursive(productItem, csrfToken, counter, quoteId);
        }
    }
}
module.exports = productDetail;
